import { Injectable } from '@angular/core';
import { AlertComponent, AlertData } from '../components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public dialog: MatDialog) { }

  show(data: AlertData, panelClass = '') {
    const dialogRef = this.dialog.open(AlertComponent, {
      minWidth: '640px',
      panelClass: ['alert-dialog', panelClass],
      data
    });
    return dialogRef;
  }
}
