import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { QuestFuelService } from '../../../quest-fuel/quest-fuel.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MembersService } from '../../members.service';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../../services/notification.service';
import { DialogBoxComponent } from '../../../components/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-member-detail-fuel',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './member-detail-fuel.component.html',
  styleUrl: './member-detail-fuel.component.scss',
})
export class MemberDetailFuelComponent {
  brandTypes: any;
  pricingTier: any;
  locations: any;
  questFuelForm: FormGroup;
  @Input() memberId: any;
  isEditing: boolean = false;
  fuelProfileForm: boolean = false; 
  newForm: boolean = false;

  constructor(
    private questFueService: QuestFuelService,
    private fb: FormBuilder,
    private membersService: MembersService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    this.questFuelForm = this.fb.group({
      isQuestFuelCustomer: [null, Validators.required],
      questFuelAccountNumber: [null],
      brandTypeId: [null, Validators.required],
      pricingTierTypeId: [null, Validators.required],
      geographyTypeId: [null, Validators.required],
    });
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/add-blue.svg'
      )
    );
  }

  ngOnInit() {
    this.membersService.getFuelProfile(this.memberId).subscribe({
      next: (res) => {
        if (res) {
          this.fuelProfileForm = true;
          console.log(res);
          this.questFuelForm.patchValue({
            isQuestFuelCustomer: res.isQuestFuelCustomer ? '1' : '2',
            questFuelAccountNumber: res.questFuelAccountNumber,
            brandTypeId: res.brand.value,
            pricingTierTypeId: res.pricingTier.value,
            geographyTypeId: res.geographyType.value
          });
          this.questFuelForm.disable();
        }
      },
      error: (err) => {
       if (err.status = 404){
        this.fuelProfileForm = false;
       }
      },
    });

    this.questFueService.getBrands().subscribe((brands) => {
      this.brandTypes = brands;
    });
    this.questFueService.getPricingTiers().subscribe((tier) => {
      this.pricingTier = tier;
    });
    this.questFueService.getLocations().subscribe((loc) => {
      this.locations = loc;
    });

    if (this.questFuelForm) {
      this.questFuelForm
        .get('isQuestFuelCustomer')!
        .valueChanges.subscribe((value) => { 
          const questFuelAccountNumberControl = this.questFuelForm.get(
            'questFuelAccountNumber'
          );
          if (value === '1') {
            questFuelAccountNumberControl!.setValidators([Validators.required]);
            questFuelAccountNumberControl!.updateValueAndValidity();
          } else {
            questFuelAccountNumberControl!.clearValidators();
            questFuelAccountNumberControl!.updateValueAndValidity();
          }
        });
    }
  }

  onSubmit(): void {
    if (this.questFuelForm && this.questFuelForm.valid) {
      const formValues = this.questFuelForm.value;
      formValues.isQuestFuelCustomer == '1'
        ? (formValues.isQuestFuelCustomer = true)
        : (formValues.isQuestFuelCustomer = false);

      this.membersService
        .createFuelProfile(this.memberId, formValues)
        .subscribe(
          (response) => {
            this.notificationService.success('Fuel profile created successfully');
            this.newForm = false;
            this.questFuelForm.disable()
          },
          (error) => {
            this.notificationService.error('Error creating fuel profile');
          }
        );
    }
    else{
      this.showFormErrors(this.questFuelForm);
    }
  }

  showFormErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    }); 
  }

  deleteProfile() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this fuel profile?',
        description: 'Deleting this fuel profile will delete it permanently.',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.membersService
          .deleteFuelProfile(this.memberId)
          .subscribe((res) => {
            this.notificationService.success(
              'Fuel Profile deleted successfully'
            );
            this.fuelProfileForm = false;
            this.newForm = false;
            this.questFuelForm.reset();
            this.questFuelForm.enable()
          });
      }
    });
  }

  updateProfile() {
    if (this.questFuelForm && this.questFuelForm.valid) {
      const formValues = this.questFuelForm.value;
      formValues.isQuestFuelCustomer == '1'
        ? (formValues.isQuestFuelCustomer = true)
        : (formValues.isQuestFuelCustomer = false);

      this.membersService
        .updateFuelProfile(this.memberId, formValues)
        .subscribe(
          (response) => {
            this.notificationService.success('Fuel profile updated successfully');
            this.isEditing = false;
            this.questFuelForm.disable();
          },
          (error) => {
            this.notificationService.error('Error creating fuel profile'); 
          }
        );
    }
    else{
      this.showFormErrors(this.questFuelForm);
    }
  }
  cancelEditing(){
    this.isEditing = false;
    this.questFuelForm.disable();
  }

  createNewProfile() {
    this.fuelProfileForm = true;
    this.newForm = true;
  }

  questFuelFormEnable(){
    this.isEditing = true;
    this.questFuelForm.enable();
  }
  cancelCreate(){ 
    this.fuelProfileForm = false;
    this.newForm = false;
  }
}
