import { NgClass, Location, CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { filter, last, Observable } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [HttpClient],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  showBackIcon$!: Observable<boolean>;
  public userDetails: any;
  public userInitials: string = ''
  constructor(
    private headerService: HeaderService,
    private location: Location,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'notification',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/notification.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'down-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/down-arrow.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/arrow-left.svg'
      )
    );
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
          this.headerService.setShowBackIcon(false);
    });
  }

  ngOnInit() { 
    this.showBackIcon$ = this.headerService.getShowBackIcon();
    this.userDetails = JSON.parse(localStorage.getItem('account') || '');
    const names = this.userDetails.name.split(' ')
    const firstName = names[0].substring(0, 1);
    const lastName = names.length > 1 ? names[1].substring(0, 1): '';
    this.userInitials = firstName +' '+ lastName
  }

  goBack() { 
    this.headerService.setShowBackIcon(false);
    this.location.back();
  }
  logout() {
    this.router.navigate(['/login']);
    localStorage.clear();
  }
}
