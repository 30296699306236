import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UploadComponent } from '../../components/upload/upload.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { DataSharingService } from '../../services/data-sharing.service';

@Component({
  selector: 'app-announcement-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    UploadComponent,
  ],
  providers: [provideNativeDateAdapter(), DatePipe],
  templateUrl: './announcement-dialog.component.html',
  styleUrl: './announcement-dialog.component.scss',
})
export class AnnouncementDialogComponent {
  announcementForm = this.fb.group({
    title: ['', [Validators.required]],
    details: ['', [Validators.required]],
    startDate: [' ', [Validators.required]],
    endDate: [' ', [Validators.required]],
  });
  private readonly destroyRef = inject(DestroyRef);
  minEndDate: Date | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataService: DataSharingService
  ) {}

  ngOnInit() {
    if (this.data.data) {
      this.patchAnnouncementFormValues();
    }
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogService.onData(this.createData());
      });

    this.announcementForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.announcementForm.valid);
    });
  }

  onStartDateChange(event: any) {
    this.minEndDate = event.value;
  }

  createData() {
    const { title, details, startDate, endDate } = this.announcementForm.value;
    const locale = 'en-US';
    const body = {
      title,
      details,
      startDate: startDate
        ? `${this.datePipe.transform(startDate, 'yyyy')}-${formatDate(
            startDate,
            'MM',
            locale
          )}-${this.datePipe.transform(startDate, 'dd')}`
        : '',
      endDate: startDate
        ? `${this.datePipe.transform(
            endDate,
            'yyyy'
          )}-${this.datePipe.transform(
            endDate,
            'MM'
          )}-${this.datePipe.transform(endDate, 'dd')}`
        : '',
    };

    return body;
  }

  patchAnnouncementFormValues() {
    const { title, details, startDate, endDate } = this.data.data;
    const formValues = {
      title,
      details,
      startDate,
      endDate,
    };
    this.announcementForm.patchValue(formValues as any);
  }
}
