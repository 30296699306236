<form [formGroup]="incentiveForm">
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </div>
</form>
