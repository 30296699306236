<form [formGroup]="documentForm" class="">
  <mat-form-field
    appearance="outline"
    class="mb-6 w-full white-backgrounded-field"
  >
    <mat-label>Document Name</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="mb-6 w-full white-backgrounded-field"
  >
    <mat-label>Description</mat-label>
    <textarea
      formControlName="description"
      matInput
      placeholder="Type something"
    ></textarea>
  </mat-form-field>
  <app-theme-upload
    [form]="documentForm"
    formArrayName="attachments"
    [allowedExtensions]="['.pdf']"
    [showExtensions]="false"
    [showChange]="false"
  />
</form>
