import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-popup',
  standalone: true,
  imports: [MatIconModule, MatDialogClose],
  templateUrl: './image-popup.component.html',
  styleUrl: './image-popup.component.scss',
})
export class ImagePopupComponent {
  data = inject(MAT_DIALOG_DATA);
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cross.svg')
    );
  }
}
