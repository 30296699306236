import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../../services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { ThemeContainerComponent } from '../../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../../components/theme-table/theme-table.component';
import { TableColumns } from '../../components/table/table.component';
import { TransactionCreateComponent } from './transaction-create/transaction-create.component';
import { finalize, Subscription } from 'rxjs';
import { CustomColumnDirective } from '../../directives/custom-column.directive';
import { HeaderService } from '../../services/header.service';
import { MatButtonModule } from '@angular/material/button';
import { RebateCreateComponent } from '../rebate-create/rebate-create.component';
import { NotificationService } from '../../services/notification.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { GenericService } from '../../services/generic.service';
import { DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-finance-details',
  standalone: true,
  imports: [
    HeaderComponent,
    MatIconModule,
    MatMenuModule,
    ThemeContainerComponent,
    ThemeTableComponent,
    CustomColumnDirective,
    MatButtonModule,
    DatePipe,
    MatProgressSpinnerModule,
  ],
  templateUrl: './finance-details.component.html',
  styleUrl: './finance-details.component.scss',
})
export class FinanceDetailsComponent {
  dialogSubscription!: Subscription;
  totalRecords: any;
  incentiveProgramId: any;
  incentiveProgramByIdData: any;
  dataSource: any = [];
  displayedColumns: TableColumns[] = [
    {
      key: 'select',
      label: '',
    },
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'transactionType',
      label: 'Transaction Type',
      displayValue: (data) => data.transactionType.key,
    },
    {
      key: 'vendor',
      label: 'Vendor',
      displayValue: (data) => data.vendor.key,
    },
    {
      key: 'fiscalPeriod',
      label: 'Fiscal Period',
      displayValue: (data) => data.fiscalPeriod.key,
    },
    { key: 'fiscalYear', label: 'Fiscal Year' },
    { key: 'title', label: 'Rebate Type' },
    { key: 'totalRebateAmount', label: 'Total Rebate Amount' },
    {
      key: 'status',
      label: 'Status',
      displayValue: (data) => (data.isPublished ? 'Published' : 'Unpublished'),
    },
    { key: 'action', label: 'Actions' },
  ];
  isLoading: boolean = false;
  totalPages: any;
  pageNumber: any;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private dialogService: DialogService,
    private router: Router,
    private headerService: HeaderService,
    private dataService: DataSharingService,
    private notificationService: NotificationService,
    private readonly route: ActivatedRoute,
    private genericService: GenericService
  ) {
    this.matIconRegistry.addSvgIcon(
      'menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/menu-bar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/download-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/cross-yellow.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'tick',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/check-green.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'detail',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/details.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/delete.svg'
      )
    );
    this.headerService.setShowBackIcon(true);
    this.incentiveProgramId = +this.route.snapshot.paramMap.get('id')!;
  }

  ngAfterViewInit() {
    this.headerService.setShowBackIcon(true);
  }

  applyFilter(event: any) {}

  viewDetail(e: any) {
    this.router.navigate(['/finance/transaction', e]);
  }

  pageChange(e: any) {}

  ngOnInit() {
    this.getIncentiveProgramById();
    this.getAllTransactions();
  }

  getIncentiveProgramById() {
    this.isLoading = true;
    let url = this.genericService.endPoints.finance.incentiveProgramById;
    url = url.replace(':rebateProgramId', this.incentiveProgramId);
    this.genericService
      .getData(url)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.incentiveProgramByIdData = res;
      });
  }

  getAllTransactions() {
    let url = this.genericService.endPoints.finance.incentiveProgramsTransaction;
    url = url.replace(':rebateProgramId', this.incentiveProgramId).replace(':pageNumber', '0').replace(':pageSize', '10');
    this.genericService
      .getData(url)
      .subscribe((res) => {
        this.dataSource = res?.transactions;
      });
  }

  openDialog() {
    const dialogRef = this.dialogService.open({
      component: TransactionCreateComponent,
      title: 'Create Transaction',
      actionLabel: 'Create',
      themeModal: true,
      hideCloseIcon: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe({
      complete: () => {},
      error: (err: any) => {
        // this.notificationService.error(err.message);
      },
      next: (res: any) => {
        // dialogRef.close();
        // this.dataService.isFormValid.next(true);
        // this.pageChange();
        // this.notificationService.success('Announcement has been added');
      },
    });
  }

  createRebate() {
    const dialogRef = this.dialogService.open({
      component: RebateCreateComponent,
      title: 'Create Rebate Type',
      actionLabel: 'Create',
      themeModal: true,
      hideCloseIcon: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe({
      complete: () => {},
      error: (err: any) => {
        this.notificationService.error(err.message);
      },
      next: (res: any) => {
        dialogRef.close();
        this.dataService.isFormValid.next(true);
        // this.financeService.createRebateProgram(res).subscribe((res) => {
        //   this.notificationService.success('Rebate program has been created');
        // });
      },
    });
  }
}
