import { Component } from '@angular/core';
import { ThemeContainerComponent } from '../../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../../components/theme-table/theme-table.component';
import { HeaderComponent } from '../../components/header/header.component';
import { TableColumns } from '../../components/table/table.component';
import { CustomColumnDirective } from '../../directives/custom-column.directive';
import { HeaderService } from '../../services/header.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-transaction-details',
  standalone: true,
  imports: [
    ThemeContainerComponent,
    ThemeTableComponent,
    HeaderComponent,
    CustomColumnDirective,
    MatMenuModule,
    MatIconModule
  ],
  templateUrl: './transaction-details.component.html',
  styleUrl: './transaction-details.component.scss',
})
export class TransactionDetailsComponent {
  applyFilter(e: any) {}
  data: any = {
    pageNumber: 0,
    totalPages: 1,
    totalRecords: 4,
    transactions: [
      {
        id: 4,
        memberId: 1,
        memberName: 'Tech Corp',
        rebateVolume: '10 Units',
        rebateRate: 0.24,
        rebateAmount: 6,
      },
      {
        id: 3,
        memberId: 1,
        memberName: 'Tech Corp',
        rebateVolume: '10 Units',
        rebateRate: 0.24,
        rebateAmount: 10,
      },
      {
        id: 2,
        memberId: 1,
        memberName: 'Tech Corp',
        rebateVolume: '10 Units',
        rebateRate: 0.24,
        rebateAmount: 6,
      },
      {
        id: 1,
        memberId: 1,
        memberName: 'Tech Corp',
        rebateVolume: '10 Units',
        rebateRate: 0.24,
        rebateAmount: 10,
      },
    ],
  };
  dataSource: any;
  displayedColumns: TableColumns[] = [
    { key: 'id', label: 'ID' },
    {
      key: 'memberId',
      label: 'Member ID',
    },
    {
      key: 'memberName',
      label: 'Member Name',
    },
    {
      key: 'rebateVolume',
      label: 'Purchased Volume',
    },
    {
      key: 'rebateRate',
      label: 'Rebate Rate',
    },
    {
      key: 'rebateAmount',
      label: 'Rebate Amount',
    },
  ];
  totalPages: any;
  totalRecords: any;
  pageNumber: any;
  pageSize = 10;
  isLoading: boolean = false;
  pageChange(e: any) {}
  constructor(
    private headerService: HeaderService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) { 
    this.matIconRegistry.addSvgIcon(
      'dropdown',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/dropdown.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/download-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/cross-yellow.svg'
      )
    );
  }
  ngOnInit() {
    this.dataSource = this.data.transactions;
    this.totalRecords = this.data.totalRecords;
  }
  ngAfterViewInit(){
    this.headerService.setShowBackIcon(true);
  }
}
