import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Publication, PublicationsService } from '../publications.service';
import { HeaderService } from '../../services/header.service';
import { Observable } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'app-publication-detail',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
  templateUrl: './publication-detail.component.html',
  styleUrl: './publication-detail.component.scss'
})
export class PublicationDetailComponent {
  publication$!: Observable<Publication | null>; 
  constructor(
    private publicationsService: PublicationsService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
  ) { 
  }

  ngAfterViewInit(){
    this.headerService.setShowBackIcon(true);
  }
  
  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id')!;
    this.publication$ = this.publicationsService.getPublicationById(id);
  }
}
