import { Component, DestroyRef, Input, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core'; 
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataSharingService } from '../../services/data-sharing.service';
import { ThemeUploadComponent } from '../../components/theme-upload/theme-upload.component';

@Component({
  selector: 'app-publication-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ThemeUploadComponent,
    MatProgressSpinnerModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './publication-dialog.component.html',
  styleUrl: './publication-dialog.component.scss',
})
export class PublicationDialogComponent {
  @Input() data: any;
  publicationForm = this.fb.group({
    name: ['', [Validators.required]],
    createdDate: [' ', [Validators.required]], 
    attachments: this.fb.array([]),
  });
  private readonly destroyRef = inject(DestroyRef);
  public isLoading: boolean = false;
  constructor(
    private dialogService: DialogService,
    private fb: FormBuilder,
    private dataService: DataSharingService
  ) {}

  ngOnInit() {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isLoading = true;
        this.dialogService.onData(this.createData());
      });

    this.publicationForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.publicationForm.valid);
    });
  }
  createData() {
    const { name, createdDate } = this.publicationForm.value;
    const body = {
      name, 
      year: createdDate ? new Date(createdDate).getFullYear() : '',
      month: createdDate ? new Date(createdDate).getMonth() + 1 : '',
    };
    const formData = new FormData();
    formData.append(
      'details',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );
    const attachments = this.publicationForm.get('attachments') as FormArray;
    attachments.controls.forEach((control) => {
      const file: File = control?.get('file')?.value;
      formData.append('attachment', file, file.name);
    });
    return formData;
  }
}
