<form [formGroup]="reminderForm">
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Date</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Select date"
      formControlName="date"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    @if(reminderForm.controls['date'].hasError('required')){
    <mat-error *ngIf="">Date is required</mat-error>
    }
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Time</mat-label>
    <input
      matInput
      [ngxMatTimepicker]="timePicker"
      placeholder="Select time"
      formControlName="time"
    />
    <ngx-mat-timepicker-toggle
      matSuffix
      [for]="timePicker"
    ></ngx-mat-timepicker-toggle>
    <ngx-mat-timepicker #timePicker ></ngx-mat-timepicker>
    @if(reminderForm.controls['time'].hasError('required')){
    <mat-error>Time is required</mat-error>
    }
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Notes</mat-label>
    <textarea
      formControlName="notes"
      matInput
      placeholder="Enter reminder notes"
    ></textarea>
    @if(reminderForm.controls['notes'].hasError('required')){
      <mat-error *ngIf="">Notes are required</mat-error>
      }
  </mat-form-field> 
</form>
