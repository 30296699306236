<app-header>
  <p title>Seven Eleven</p>
</app-header>
<div class="main-content p-6">
  <div class="remarks">
    <h1 class="flex justify-between">
      Remarks
      <button mat-button class="btn-danger">Mark As Resolve</button>
    </h1>
    <ol>
      <li>
        met, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      </li>
      <li>
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation
      </li>
      <li>ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
    </ol>
  </div>
  <div class="bg-white rounded-lg mt-3">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Company Information">
        <app-company-information (remarks)="openRemarksDialog()"></app-company-information>
      </mat-tab>
      <mat-tab label="Owner Information">
        <app-owner-information (remarks)="openRemarksDialog()"></app-owner-information>
      </mat-tab>
      <mat-tab label="Store Supervisor">
        <app-store-supervisor (remarks)="openRemarksDialog()"></app-store-supervisor>
      </mat-tab>
      <mat-tab label="Store Manager">
        <app-store-manager (remarks)="openRemarksDialog()"></app-store-manager>
      </mat-tab>
      <mat-tab label="Reference">
        <app-reference (remarks)="openRemarksDialog()"></app-reference>
      </mat-tab>
      <mat-tab label="Required Documents">
        <app-required-docs (remarks)="openRemarksDialog()"></app-required-docs>
      </mat-tab>
      <mat-tab label="Accounting Review">
        <app-accounting-review (remarks)="openRemarksDialog()"></app-accounting-review>
      </mat-tab>
      <mat-tab label="Board of Director Review">
        <app-director-review (remarks)="openRemarksDialog()"></app-director-review>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
