<form [formGroup]="supportForm">
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Support Type</mat-label>
    <mat-select
      formControlName="supportTypeId"
      name="supportTypeId"
      placeholder="Select"
    >
      @for (support of supportTypes$ | async; track support) {
      <mat-option [value]="support.value">{{ support.key }}</mat-option>
      }
    </mat-select>
    @if(supportForm.controls.supportTypeId.hasError('required')){
    <mat-error> Support Type is required. </mat-error>
    }
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Vendor</mat-label>
    <mat-select formControlName="vendor" name="vendor" placeholder="Select">
      @for (vendor of vendorList; track vendor) {
      <mat-option [value]="vendor.id">{{ vendor.name }}</mat-option>
      }
    </mat-select>
    @if(supportForm.controls.vendor.hasError('required')){
    <mat-error> Vendor is required. </mat-error>
    }
  </mat-form-field>
  @if(getSelectedVendorName() == 'South Texas Merchants Association (STMA)'){
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Department</mat-label>
    <mat-select
      formControlName="departmentId"
      name="departmentId"
      placeholder="Select"
    >
      @for (department of departmentTypes$ | async; track department) {
      <mat-option [value]="department.value">{{ department.key }}</mat-option>
      }
    </mat-select>
    @if(supportForm.controls.departmentId.hasError('required')){
    <mat-error> Department is required. </mat-error>
    }
  </mat-form-field>
  }
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Member</mat-label>
    <input #input
    type="text"
    placeholder="Member"
    matInput 
    formControlName="memberId"
    [matAutocomplete]="auto"
    (input)="filterMembers()"
    (focus)="filterMembers()">
    <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    @for (member of filteredMembers; track $index) {
    <mat-option  [value]="member.value">
      {{ member.key }}
    </mat-option>
    }</mat-autocomplete>
    @if(supportForm.controls.memberId.hasError('required')){
    <mat-error> Member is required. </mat-error>
    }
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mb-4"
  >
    <mat-label>Add Issue/Concern</mat-label>
    <textarea
      formControlName="issueOrConcern"
      matInput
      placeholder="Add issue or concern of the ticket"
    ></textarea>
    @if(supportForm.controls.issueOrConcern.hasError('required')){
    <mat-error> Issue/Concern is required. </mat-error>
    }
  </mat-form-field>
  <app-theme-upload
    [form]="supportForm"
    formArrayName="attachments"
  ></app-theme-upload>
</form>
