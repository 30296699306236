<app-header>
  <p title>Announcement</p>
</app-header>
<div class="main-content announcements p-6">
  <app-theme-container
    title="Announcement"
    [totalRecords]="totalRecords"
    searchPlaceholder="Search publications by name, date etc"
    [cta]="'Create New Announcement'"
    (ctaClick)="openDialog()"
    [minheight]="true"
    [hideSearch]="true"
  >
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Current">
        <div class="grid grid-cols-3 gap-5 p-6">
          @if(announcements.length) {@for(announcement of announcements; track
          announcement; let i = $index){
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>
                <div class="flex items-center">
                  <mat-icon class="mr-4" svgIcon="speaker"></mat-icon>
                  <span class="">{{ announcement.title }}</span>
                </div>
                <div class="flex items-center">
                  <mat-icon
                    class="cursor-pointer"
                    (click)="delete(announcement.id)"
                    onkeydown
                    svgIcon="trash"
                  ></mat-icon>
                </div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-footer class="example-card-footer">
              <mat-chip-set>
                <mat-chip> Starts - {{ announcement.startDate }} </mat-chip>
                <mat-chip> Ends - {{ announcement.endDate }} </mat-chip>
              </mat-chip-set>
            </mat-card-footer>
            <mat-card-content>
              <p class="text-sm font-light">
                {{ announcement.details }}
              </p>
            </mat-card-content>
          </mat-card>
          }}@else {
          <div class="no-records">
            <h6 class="text-gray-500">No current announcements available</h6>
          </div>
          }
        </div>
      </mat-tab>
      <mat-tab label="Past">
        <div class="grid grid-cols-3 gap-5 p-6">
          @if(pastAnnouncements.length){ @for(pastAnnouncement of
          pastAnnouncements; track pastAnnouncement; let i = $index){
          <mat-card class="past-announcement" appearance="outlined">
            <mat-card-header>
              <mat-card-title>
                <div class="flex items-center">
                  <mat-icon class="mr-5" svgIcon="speaker"></mat-icon>
                  <span class="">{{ pastAnnouncement.title }}</span>
                </div>
                <div class="flex items-center">
                  <mat-icon
                    class="cursor-pointer"
                    (click)="delete(pastAnnouncement.id)"
                    onkeydown
                    svgIcon="trash"
                  ></mat-icon>
                </div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-footer class="example-card-footer">
              <mat-chip-set>
                <mat-chip> Starts - {{ pastAnnouncement.startDate }} </mat-chip>
                <mat-chip> Ends - {{ pastAnnouncement.endDate }} </mat-chip>
              </mat-chip-set>
            </mat-card-footer>
            <mat-card-content>
              <p class="text-light-grey">
                {{ pastAnnouncement.details }}
              </p>
            </mat-card-content>
          </mat-card>
          }} @else {
          <div class="no-records">
            <h6 class="text-gray-500">No past promotions available</h6>
          </div>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
  </app-theme-container>
</div>
