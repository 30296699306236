<form [formGroup]="publicationForm" class="">
  <app-theme-upload
  [form]="publicationForm"
  formArrayName="attachments"
  [allowedExtensions]="['.pdf']"
  [showExtensions]="false"
  [showChange]="false"
  [slimUpload]="true" 
/>
  <mat-form-field appearance="outline" class="mt-6 w-full white-backgrounded-field">
    <mat-label>Publication Name</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full white-backgrounded-field">
    <mat-label>Select Date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="createdDate" />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</form>
