import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-container',
  standalone: true,
  imports: [MatInputModule, MatIconModule, HttpClientModule],
  providers: [HttpClient],
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss'
})
export class ContainerComponent {
  @Input() title!: string;
  @Input() searchPlaceholder!: string;
  @Output() filter = new EventEmitter();
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
      );
    }

  applyFilter(event: Event) {
    fromEvent(this.searchInput.nativeElement, 'input').pipe(
      map((event: any) => (event.target as HTMLInputElement).value),
      debounceTime(300),  // Debounce time to delay the API call
      distinctUntilChanged()
    ).subscribe(query => {
      this.filter.emit(query);
    });
  }
}
