<app-header>
  <p title>Promotions</p>
</app-header>
<div class="main-content promotions p-6">
  <app-theme-container
    title="Promotions"
    searchPlaceholder="Search publications by name, date etc"
    [cta]="'Add New Promotion'"
    (ctaClick)="openDialog()"
    [minheight]="true"
    [hideSearch]="true"
  >
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="Current">
        <ng-template matTabContent>
          <div class="grid grid-cols-3 gap-5 p-6">
            @if (currentPromotions.length) { @for (currentPromotion of
            currentPromotions; track currentPromotion) {
            <div class="box">
              <app-promtion-banner
                [id]="currentPromotion.id"
              ></app-promtion-banner>
              <div class="details">
                <div class="flex justify-between">
                  <h2 color="primary" class="title font-medium">
                    {{ currentPromotion.name }}
                  </h2>
                  <button class="btn-menu" [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="more_vert"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openDialog(currentPromotion)"
                    >
                      <mat-icon svgIcon="pencil"></mat-icon>
                      <span>Edit</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="deletePromotion(currentPromotion.id)"
                    >
                      <mat-icon svgIcon="trash"></mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="box-footer flex">
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">Start Date:</span>
                      {{ currentPromotion.startDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">End Date:</span>
                      {{ currentPromotion.endDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            } } @else {
            <div class="no-records">
              <h6 class="text-gray-500">No current promotions available</h6>
            </div>
            }
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Upcoming">
        <ng-template matTabContent>
          <div class="grid grid-cols-3 gap-8 p-6">
            @if (upcomingPromotions.length) { @for (upcomingPromotion of
            upcomingPromotions; track upcomingPromotion) {
            <div class="box">
              <app-promtion-banner
                [id]="upcomingPromotion.id"
              ></app-promtion-banner>
              <div class="details">
                <div class="flex justify-between">
                  <h2 color="primary" class="title font-medium">
                    {{ upcomingPromotion.name }}
                  </h2>
                  <button class="btn-menu" [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="more_vert"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openDialog(upcomingPromotion)"
                    >
                      <mat-icon svgIcon="pencil"></mat-icon>
                      <span>Edit</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="deletePromotion(upcomingPromotion.id)"
                    >
                      <mat-icon svgIcon="trash"></mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="box-footer flex">
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">Start Date:</span>
                      {{ upcomingPromotion.startDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">End Date:</span>
                      {{ upcomingPromotion.endDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            } } @else {
            <div class="no-records">
              <h6 class="text-gray-500">No upcoming promotions available</h6>
            </div>
            }
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Expired">
        <ng-template matTabContent>
          <div class="grid grid-cols-3 gap-8 p-6">
            @if (expiredPromotions.length) { @for (expiredPromotion of
            expiredPromotions; track expiredPromotion) {
            <div class="box">
              <app-promtion-banner
                [id]="expiredPromotion.id"
              ></app-promtion-banner>
              <div class="details">
                <div class="flex justify-between">
                  <h2 color="primary" class="title font-medium">
                    {{ expiredPromotion.name }}
                  </h2>
                  <button class="btn-menu" [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="more_vert"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openDialog(expiredPromotion)"
                    >
                      <mat-icon svgIcon="pencil"></mat-icon>
                      <span>Edit</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="deletePromotion(expiredPromotion.id)"
                    >
                      <mat-icon svgIcon="trash"></mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="box-footer flex">
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">Start Date:</span>
                      {{ expiredPromotion.startDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                  <span class="date">
                    <mat-icon svgIcon="calendar"></mat-icon>
                    <span class="date-inner">
                      <span class="text-light-grey">End Date:</span>
                      {{ expiredPromotion.endDate | date : "dd/MM/yyyy" }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            } } @else {
            <div class="no-records">
              <h6 class="text-gray-500">No expired promotions available</h6>
            </div>
            }
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </app-theme-container>
</div>
