import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { Attachment } from '../components/upload/upload.component';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

export interface TicketElement {
  id: number;
  position: number;
  businessName: string;
  businessAddress: string;
  membershipNo: string;
  supportType: KeyValue;
  departmentId: KeyValue;
  statusType: KeyValue;
  member: KeyValue;
  vendor: KeyValue;
  department: KeyValue;
  issue: string;
  statusLabel: string;
  status: string;
  issueOrConcern: string;
  closingStatement: string;
  assignedTo: string;
  attachments: Attachment[];
}

export interface TicketResponse {
  pageNumber: number;
  totalPages: number;
  totalRecords: number;
  tickets: TicketElement[];
}

export interface KeyValue {
  key: string;
  value: number;
}

export interface Comment {
  comment: string;
  id: number;
  createdDate: Date;
  createdBy: string;
}

export interface Metrics {
  NEW: number;
  CLOSED: number;
  ACTIVE?: number;
  INACTIVE: number;
  'IN-PROGRESS': number;
}

export enum StatusType {
  CLOSED = 3,
  'IN-PROGRESS' = 2,
  NEW = 1,
}

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  getTickets(page: number) {
    return this.http.get<TicketElement[]>(
      `${environment.baseUrl}/management/support-tickets?page=${page}&size=10`
    );
  }

  getTicketById(id: number): Observable<TicketElement | null> {
    return this.http.get<TicketElement>(
      `${environment.baseUrl}/management/support-tickets/${id}`
    );
  }

  createTicket(payload: TicketElement) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post(`${environment.baseUrl}/management/support-tickets`, payload)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updateTicket(id: number, payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .put<TicketElement>(
        `${environment.baseUrl}/management/support-tickets/${id}`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  getMemberTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/members/member-types`
    );
  }

  getMembers() {
    return this.http.get<any[]>(`${environment.baseUrl}//management/members`);
  }

  getMetaDataMembers() {
    return this.http.get<any[]>(`${environment.baseUrl}/metadata/members`);
  }

  getSupportTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/support-tickets/support-types`
    );
  }

  getDepartmentTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/support-tickets/stma-departments`
    );
  }

  getStatusTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/support-tickets/status-types`
    );
  }

  getComments(ticketId: number) {
    return this.http.get<Comment[]>(
      `${environment.baseUrl}/management/support-tickets/${ticketId}/comments`
    );
  }

  sendComment(ticketId: number, payload: any) {
    return this.http.post(
      `${environment.baseUrl}/management/support-tickets/${ticketId}/comments`,
      payload
    );
  }

  getMetrics() {
    return this.http.get<Metrics>(
      `${environment.baseUrl}/management/support-tickets/metrics`
    );
  }

  uploadAttachments(ticketId: number, payload: FormData) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post(
        `${environment.baseUrl}/management/support-tickets/${ticketId}/attachments`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  deleteAttachment(ticketId: number, attachmentId: number) {
    return this.http.delete(
      `${environment.baseUrl}/management/support-tickets/${ticketId}/attachments/${attachmentId}`
    );
  }

  searchTicket(query: any, page: number, size: number) {
    return this.http.get<TicketElement>(
      `${environment.baseUrl}/management/support-tickets//search?query=${query}&page=${page}&size=${size}`
    );
  }

  updateStatus(id: number, payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .patch(
        `${environment.baseUrl}/management/support-tickets/${id}/status`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  getAttachments(id: number, attachmentId: number) {
    return this.http.get(
      `${environment.baseUrl}/management/support-tickets/${id}/attachments/${attachmentId}`,
      { responseType: 'blob' }
    );
  }

  getAttachmentThumbnail(id: number, attachmentId: number) {
    return this.http.get(
      `${environment.baseUrl}/metadata/support-tickets/${id}/attachments/${attachmentId}/thumbnail`,
      { responseType: 'blob' }
    );  
  }

  setReminder(id: number, payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post(
        `${environment.baseUrl}/management/support-tickets/${id}/reminders`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }
}
