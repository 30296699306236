import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

export interface VendorElement {
  id: number;
  isActive: boolean;
  name: string;
  businessType: KeyValue;
  address: Address;
  contacts: Contacts[];
}

export interface Address {
  city: string;
  state: string;
  street1: string;
  street2: string;
  zipCode: string;
}

export interface Contacts {
  firstName: string;
  lastName: string;
  jobTitle: string;
  departmentName: string;
  departmentTypeId: number;
  email: string;
  phone: string;
  isAccountManager?: boolean;
}

export interface VendorResponse {
  pageNumber: number;
  totalPages: number;
  totalRecords: number;
  vendors: VendorElement[];
}

export interface KeyValue {
  key: string;
  value: number;
}
export interface Metrics {
  ACTIVE: number;
  INACTIVE: number;
}

@Injectable({
  providedIn: 'root',
})
export class VendorsService {
  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  getVendors(page: number) {
    return this.http.get<VendorElement[]>(
      `${environment.baseUrl}/management/vendors?page=${page}&size=10`
    );
  }

  getVendorById(id: number): Observable<VendorElement | null> {
    return this.http.get<VendorElement>(
      `${environment.baseUrl}/management/vendors/${id}`
    );
  }

  createVendor(payload: VendorElement) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post(`${environment.baseUrl}/management/vendors`, payload)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updateVendor(id: number, payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .put<VendorElement>(
        `${environment.baseUrl}/management/vendors/${id}`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  deactivateVendor(id: number) {
    return this.http.patch<VendorElement>(
      `${environment.baseUrl}/management/vendors/${id}/deactivate`,
      null
    );
  }

  activateVendor(id: number) {
    return this.http.patch<VendorElement>(
      `${environment.baseUrl}/management/vendors/${id}/activate`,
      null
    );
  }

  getVendor() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/vendors`
    );
  }

  getBusinessTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/vendors/business-types`
    );
  }

  getDepartmentTypes() {
    return this.http.get<KeyValue[]>(
      `${environment.baseUrl}/metadata/vendors/department-contact-types`
    );
  }

  getMetrics() {
    return this.http.get<Metrics>(
      `${environment.baseUrl}/management/vendors/metrics`
    );
  }

  createContact(vendorId: number, payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post<VendorElement>(
        `${environment.baseUrl}/management/vendors/${vendorId}/contacts`,
        payload
      )
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updateContact(vendorId: number, contactId: number, payload: any) {
    return this.http.put<VendorElement>(
      `${environment.baseUrl}/management/vendors/${vendorId}/contacts/${contactId}`,
      payload
    );
  }
  deleteContact(vendorId: number, contactId: number) {
    return this.http.delete<VendorElement>(
      `${environment.baseUrl}/management/vendors/${vendorId}/contacts/${contactId}`
    );
  }

  searchVendor(query: any, page: number, size: number) {
    return this.http.get<VendorElement>(
      `${environment.baseUrl}/management/vendors/search?query=${query}&page=${page}&size=${size}`
    );
  }
}
