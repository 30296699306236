import { Component, DestroyRef, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DataSharingService } from '../../services/data-sharing.service';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-incentive-create',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './incentive-create.component.html',
  styleUrl: './incentive-create.component.scss',
})
export class IncentiveCreateComponent {
  incentiveForm!: FormGroup;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private dataService: DataSharingService,
    private dialogService: DialogService
  ) {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogService.onData(this.incentiveForm.value);
      });
  }

  ngOnInit(): void {
    this.incentiveForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.incentiveForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.incentiveForm.valid);
    });
  }
}
