<div class="bg-white relative rounded-lg" [class.full-height]="minheight">
  <div class="flex items-center justify-between container-header">
    <div class="flex flex-col title">
      <h2 class="text-xl">{{ title }}</h2>
      @if (totalRecords){
      <p class="item-count">{{ totalRecords }} items</p>
      }
    </div>
    <div class="flex justify-end">
      <div [class.hidden]="hideSearch">
        <mat-form-field subscriptSizing="dynamic">
          <input
            #searchInput
            class="bg-slate-200"
            matInput 
            [placeholder]="searchPlaceholder"
            #input
          />
          <mat-icon matSuffix svgIcon="search"></mat-icon>
        </mat-form-field>
      </div> 
      @if (publishBtn){
        <button 
          mat-stroked-button
          color="primary"
          class="theme-stroke-btn ml-4"
          [class.opacity-50]="!enablePublish" 
        > 
          <span class="ml-1">{{publishBtn}}</span>
        </button>
      }
      @if (downloadbtn){
        <button 
        mat-stroked-button
          color="primary"
          class="theme-stroke-btn ml-4"
        >
          <mat-icon svgIcon="download"></mat-icon>
          <span class="ml-1">{{downloadbtn}}</span>
        </button>
      }
      @if (cta){
      <button
        (click)="openDialog()"
        mat-flat-button
        color="primary"
        class="theme-btn ml-4"
      >
        <mat-icon svgIcon="add"></mat-icon>
        <span class="ml-1">{{cta}}</span>
      </button>
    }
  </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
