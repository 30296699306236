<app-header>
    <p title>Member Applications</p>
  </app-header>
  <div class="main-content tickets p-6">
    <div class="flex justify-center space-x-5 mb-6"> 
      <div class="metrics-counter">
        <div class="flex flex-col items-center justify-center">
          <p class="text-lg font-normal">Total Applications</p>
          <p class="text-xl mt-2 font-bold">16</p>
        </div>
        <div class="flex flex-col items-center justify-center">
          <p class="text-lg font-normal">Total Submitted</p>
          <p class="text-xl mt-2 font-bold">6</p>
        </div>
        <div class="flex flex-col items-center justify-center">
          <p class="text-lg font-normal">Total Approved</p>
          <p class="text-xl mt-2 font-bold">2</p>
        </div>
      </div> 
    </div>
  
    <app-theme-container
      (filter)="applyFilter($event)"
      title=" Member Applications"
      [totalRecords]="100"
      searchPlaceholder="Search application by Name, phone or Date" 
    >
      <app-theme-table
        #table
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [isLoading]="false"
        (rowClick)="viewDetail($event.id)"
        (pageChange)="pageChange($event)"
        [totalPages]="totalPages"
        [totalRecords]="totalRecords"
        [pageNumber]="pageNumber"
        title="Applications"
        searchPlaceholder="Search application by Name, phone or Date"
      > 
      </app-theme-table>
    </app-theme-container>
  </div>
  