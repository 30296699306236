<app-header>
  <p title>Publications</p>
</app-header>
@if (isLoading) {
<div
  class="fixed flex h-[100vh] items-center justify-center w-[100vw] top-[0] left-[0] z-50"
>
  <div class="absolute">
    <mat-spinner></mat-spinner>
  </div>
</div>
}
<div class="main-content p-6">
  <app-theme-container
    title="Publications"
    (filter)="applyFilter($event)"
    searchPlaceholder="Search..."
    [totalRecords]="totalRecords"
    [cta]="'Add New Publication'"
    (ctaClick)="openDialog()"
    [minheight]="true"
  >
    @if (publications.length) {
    <div class="box-container p-6">
      @for (publication of publications; track publication) {
      <div class="item-card">
        <div class="thumbnail">
          <img src="assets/thumbnail-rect.png" alt="" />
        </div>
        <div class="details">
          <h3>
            {{
              publication.name.substring(0, 1).toUpperCase() +
                "" +
                publication.name
                  .substring(1, publication.name.length)
                  .toLowerCase()
            }}
          </h3>
          <p class="font-light text-sm">
            {{ publication.createdDate | date : "MMMM d, y" }}
          </p>
          <div class="action-btns">
            <button
              mat-button
              class="cursor-pointer"
              (click)="deletePublication(publication.id)"
            >
              <span class="flex text-[#F62005] items-center font-medium">
                Delete
              </span>
            </button>
            <button
              mat-stroked-button
              color="primary"
              (click)="downloadPublication(publication.id)"
              class="cursor-pointer px-2.5 theme-stroke-btn"
            >
              <span
                class="flex text-mat-primary uppercase items-center font-medium"
              >
                Download
              </span>
            </button>
          </div>
        </div>
      </div>
      }
    </div>
    }
  </app-theme-container>
</div>
