import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appCustomColumn]',
  standalone: true
})
export class CustomColumnDirective {

  @Input('appCustomColumn') columnKey!: string;

  constructor(public templateRef: TemplateRef<any>) {
  }

}
