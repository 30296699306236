import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentCardComponent } from '../document-card/document-card.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-store-manager',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    DocumentCardComponent,
  ],
  templateUrl: './store-manager.component.html',
  styleUrl: './store-manager.component.scss',
})
export class StoreManagerComponent {
  @Output() remarks = new EventEmitter<any>();
  managers: any;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.matIconRegistry.addSvgIcon(
      'attachedImg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/attached_img.svg'
      )
    );

    this.http
      .get<{ [state: string]: string[] }>('/assets/json/store_manager.json')
      .subscribe((data) => {
        this.managers = data;
      });
  }

  addRemarks() {
    this.remarks.emit();
  }
}
