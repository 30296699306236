import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataSharingService } from '../services/data-sharing.service';

export interface DocumentElement {
  id: number;
  name: string;
  description: string;
  createdDate: string;
  createdBy: string;
  size: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private readonly data = new BehaviorSubject<DocumentElement[]>([]);

  data$ = this.data.asObservable();
  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  getDocuments(page: number) {
    return this.http.get<DocumentElement[]>(
      `${environment.baseUrl}/management/documents?page=${page}&size=10`
    );
  }

  createDocument(data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post<any>(`${environment.baseUrl}/management/documents`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updateDocument(id: any, data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .put<any>(`${environment.baseUrl}/management/documents/${id}`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  downloadDocument(id: any) { 
    return this.http.get(`${environment.baseUrl}/management/documents/${id}`, { responseType: 'blob' });
  }

  getDocumentById(id: number): Observable<DocumentElement | null> {
    return this.data$.pipe(
      map((data) => data.find((document) => document.id === id) || null)
    );
  }

  deleteDocument(id: number) {
    return this.http.delete<any>(
      `${environment.baseUrl}/management/documents/${id}`
    );
  }

  search(query: any) {
    return this.http.get<DocumentElement>(
      `${environment.baseUrl}/management/documents/search?query=${query}`
    );
  }
}
