<mat-dialog-content class="">
    <div class="flex mb-9">
        <mat-icon [mat-dialog-close]="true" class="cross cursor-pointer ml-auto" svgIcon="cross"></mat-icon>
    </div>
    <div class="">
        <div class="">
          <main class="">
            <!-- image -->
            <swiper-container #swiper navigation="true" space-between="10" free-mode="true" watch-slides-progress="true" class="mySwiper" thumbs-swiper=".mySwiper2" (slidechange)="slideChange($event)">
              @for(image of images; track image) {
                <swiper-slide>
                    <div class="w-full h-full main-image" [ngStyle]="{backgroundImage: 'url(' + image +')'}"></div>
                </swiper-slide>
              }
            </swiper-container>
            @if(images.length > 0) {
                <swiper-container #swiperThumbs space-between="10" centered-slides="false" slides-per-view="auto" class="mb-4 mySwiper2">
                @for(image of images; track image) {
                    <swiper-slide>
                        <div class="w-full h-full thumbnail-image" [ngStyle]="{backgroundImage: 'url(' + image +')'}"></div>
                    </swiper-slide>
                }
                </swiper-container>
            }
      
          </main>
        </div>
    </div>
</mat-dialog-content>
