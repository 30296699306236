import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../../services/dialog.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

export interface DialogData {
  title: string;
  component: any;
  actionLabel: string;
  closeLabel?: string;
  data?: any;
  hideCloseIcon?: boolean;
  themeModal?: boolean;
}

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    NgComponentOutlet,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    LoadingSpinnerComponent,
    CommonModule,
    MatProgressBarModule
  ],
  providers: [HttpClient],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  public isDisabled: boolean = true;
  public subscription!: Subscription;
  loading = false;
  constructor(
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogComponent>,
    private dataService: DataSharingService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data = { ...this.data, closeLabel: this.data.closeLabel ?? 'Cancel' };
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cross.svg')
    );
  }

  ngOnInit() {
    this.subscription = this.dataService.isFormValid.subscribe(
      (res: boolean) => {
        this.isDisabled = res;
      }
    );
    this.dataService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // Unsubscribe to avoid memory leaks
  }

  create() {
    this.dialogService.onSave();
  }
}
