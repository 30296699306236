import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { Publication, PublicationsService } from './publications.service';
import { finalize, Subscription, switchMap } from 'rxjs';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { HeaderComponent } from '../components/header/header.component';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notification.service';
import { PublicationDialogComponent } from './publication-dialog/publication-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { DialogBoxComponent } from '../components/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-publications',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    PaginatorComponent,
    ThemeContainerComponent,
    MatProgressSpinnerModule,
  ],
  providers: [HttpClient],
  templateUrl: './publications.component.html',
  styleUrl: './publications.component.scss',
})
export class PublicationsComponent {
  dataLength = 0;
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;
  dialogSubscription!: Subscription;
  public publications: Publication[] = [];
  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    private publicationsService: PublicationsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
  }

  ngOnInit() {
    this.pageChange(0);
  }

  viewDetail(id: number) {
    this.router.navigate(['/publications/detail', id]);
  }

  openDialog(item?: any) {
    const dialogRef = this.dialogService.open({
      component: PublicationDialogComponent,
      title: 'Create Publication',
      actionLabel: 'Upload',
      data: item ? item : null,
      themeModal: true,
      hideCloseIcon: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$
      .pipe(
        switchMap((data) =>
          this.publicationsService.createPublication(data as Publication)
        )
      )
      .subscribe((data) => {
        dialogRef.close();
        this.pageChange(0);
        this.notificationService.success('Your publication has been created');
      });
  }

  pageChange(page: number) {
    this.isLoading = true;
    this.publicationsService
      .getPublications(page)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        const { publications, totalPages, pageNumber, totalRecords } =
          response as any;
        this.isLoading = false;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
        this.pageNumber = pageNumber;
        this.publications = publications;
        this.updateThumbnails();
      });
  }

  updateThumbnails(): void {
    this.publications.forEach((publication) => {
      this.publicationsService.getPublicationThumbnail(publication.id).subscribe(
        (thumbnailBlob) => { 
          const url = URL.createObjectURL(thumbnailBlob);  
      publication.thumbnail = url; 
        }
      );
    });
  }

  downloadPublication(id: number) { 
      this.publicationsService.downloadPublication(id).subscribe((res:any)=>{ 
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
      })
  }

  deletePublication(id: number) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this publication?',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.publicationsService.deletePublication(id).subscribe((res) => {
          this.notificationService.success('Publication has been deleted');
          this.pageChange(0);
        });
      }
    });
  }

  applyFilter(event: any) {
    this.isLoading = true;
    this.publicationsService.search(event).subscribe((response: any) => {
      const { publications, totalPages, pageNumber, totalRecords } =
        response as any;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber;
      this.publications = publications;
    });
  }
  handleError(event: Event) {
    const element = event.target as HTMLImageElement;
    element.src = 'assets/thumbnail-rect.png';
  }
}
