<app-header>
  <p title>Vendor Management</p>
</app-header>
<div class="main-content vendors p-6">
  <div class="flex justify-center space-x-5 mb-6">
    @if (metrics$ | async; as metrics) {
    <div class="metrics-counter">
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Total Vendors</p>
        <p class="text-xl mt-2 font-bold">
          {{ metrics.ACTIVE + metrics.INACTIVE || "0" }}
        </p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Active Vendors</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.ACTIVE || "0" }}</p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Inactive Vendors</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.INACTIVE || "0" }}</p>
      </div>
    </div>
    }
  </div>

  <app-theme-container
    (filter)="applyFilter($event)"
    title="Vendors"
    [totalRecords]="totalRecords"
    searchPlaceholder="Search vendors by ID, Name, Business Type, Representative or Status"
    [cta]="'Add New Vendor'"
    (ctaClick)="openDialog()"
  >
    <app-theme-table
      #table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isLoading]="isLoading"
      (rowClick)="viewDetail($event.id)"
      (pageChange)="pageChange($event)"
      [totalPages]="totalPages"
      [totalRecords]="totalRecords"
      [pageNumber]="pageNumber"
      title="Tickets"
      searchPlaceholder="Search vendors by ID, Name, Business Type, Representative or Status"
    >
      <ng-container *appCustomColumn="'representative'; let item">
        <div [innerHTML]="getRepresentatives(item)"></div>
      </ng-container>
      <ng-container *appCustomColumn="'isActive'; let item">
        <div class="status-badge flex items-center">
          <span
            [ngClass]="{ 
          'grey': !item.isActive,
          'green': item.isActive,
        }"
          >
            {{ item.isActive ? "Active" : "Inactive" }}
          </span>
        </div>
      </ng-container>
    </app-theme-table>
  </app-theme-container>
</div>
