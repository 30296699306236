import { NgClass } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { RouterLink, RouterLinkActive } from "@angular/router";

@Component({
  selector: "app-sidemenu",
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatRippleModule,
    HttpClientModule,
  ],
  providers: [HttpClient],
  templateUrl: "./sidemenu.component.html",
  styleUrl: "./sidemenu.component.scss",
})
export class SidemenuComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.menuList.forEach((menuItem) => {
      this.matIconRegistry.addSvgIcon(
        menuItem.iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(menuItem.icon)
      );
    });
  }
  menuList = [
    {
      label: "Dashboard",
      route: "/dashboard",
      iconName: "dashboard",
      icon: "assets/icons/dashboard.svg",
    },
    {
      label: "Member Applications",
      route: "member-applications",
      iconName: "memberPlus",
      icon: "assets/icons/memberPlus.svg",
    },
    {
      label: "Members",
      route: "members",
      iconName: "user",
      icon: "assets/icons/user.svg",
    },
    {
      label: "Vendors",
      route: "vendors",
      iconName: "vendors",
      icon: "assets/icons/vendors.svg",
    },
    {
      label: "Support Tickets",
      route: "tickets",
      iconName: "tickets",
      icon: "assets/icons/tickets.svg",
    },
    {
      label: "Finance",
      route: "finance",
      iconName: "finance",
      icon: "assets/icons/finance.svg",
    },
    {
      label: "Quest Fuel",
      route: "quest-fuel",
      iconName: "quest-fuel",
      icon: "assets/icons/Off - Quest Fuel.svg",
    },
    {
      label: "Promotions",
      route: "promotions",
      iconName: "promotion",
      icon: "assets/icons/promotion.svg",
    },
    {
      label: "Publications",
      route: "publications",
      iconName: "menu-board",
      icon: "assets/icons/menu-board.svg",
    },
    {
      label: "Announcements",
      route: "announcement",
      iconName: "announcement",
      icon: "assets/icons/announcement.svg",
    },
    {
      label: "Documents",
      route: "documents",
      iconName: "clipboard",
      icon: "assets/icons/clipboard.svg",
    },
    /* {
      label: 'Settings',
      route: 'settings',
      iconName: 'settings',
      icon: 'assets/icons/settings.svg',
    }, */
  ];
}
