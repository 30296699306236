<div class="flex w-full items-center justify-center bg-white z-50 relative">
  @if (isLoading) {
  <div class="theme-spinner">
      <mat-spinner></mat-spinner>
  </div>
  }
</div>
<div class="relative -z-0">
  <div class="table-container" [class.container-metrics]="noMetrics">
    <table
      mat-table
      [dataSource]="dataSourceMat"
      matSort
      matSortDisableClear
      matSortDirection="desc"
      class="w-full whitespace-nowrap"
    >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"   
                      onKeyPress
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    @for (column of displayedColumns; track column) {
      @if(column.key!="select"){
      <ng-container [matColumnDef]="column.key">
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
        <td onKeyPress mat-cell matSort matSortDisableClear *matCellDef="let item" 
        (click)="column.key!='action' ? onRowClick(item): ''"  >
          @if(getCustomColTempRef(column.key); as customTemplateRef) {
          <ng-container
            *ngTemplateOutlet="customTemplateRef; context: { $implicit: item }"
          ></ng-container>
          } @else if (column.key === 'id') {
          <button class="w-full text-left" (click)="onRowClick(item)">
            {{ item.id }}
          </button>
          } @else if (column.isDate) {
          <p class="truncate">{{ item[column.key] | date : "MMMM d, y" }}</p>
          } @else {
          <p class="truncate">
            {{
              column.displayValue ? column.displayValue(item) : item[column.key]
            }}
          </p>
          }
        </td>
      </ng-container>
      } }
      <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true"></tr>
      <tr
        [class.cursor-pointer]="rowClick.observers.length > 0"
        mat-row
        *matRowDef="let row; columns: columnKeys"
        onKeyDown
      ></tr>
    </table>
  </div>
  <mat-paginator
    class="py-3"
    showFirstLastButtons
    [length]="totalRecords"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
