import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-spinner',
  template: `
    <div class="spinner-container">
      <mat-progress-spinner
        [diameter]="20"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
  `,
  styles: [
    `
      .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    `,
  ],
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class LoadingSpinnerComponent {}
