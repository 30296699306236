<form [formGroup]="publicationForm" class="">
  <mat-form-field appearance="outline" class="w-full white-backgrounded-field">
    <mat-label>Publication Name</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full white-backgrounded-field">
    <mat-label>Select Date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="createdDate" />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <app-theme-upload
    [form]="publicationForm"
    formArrayName="attachments"
    [allowedExtensions]="['.pdf']"
    [showExtensions]="false"
    [showChange]="false"
  />
</form>
