import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VendorsService } from '../vendors.service';
import { CustomeValidationsService } from '../../services/validations.service';
import { NotificationService } from '../../services/notification.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vendor-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxMaskDirective,
    MatIconModule
  ],
  providers: [provideNgxMask()],
  templateUrl: './vendor-dialog.component.html',
  styleUrl: './vendor-dialog.component.scss',
})
export class VendorDialogComponent {
  businessTypes$ = this.vendorsService.getBusinessTypes();
  departmentTypes$ = this.vendorsService.getDepartmentTypes();
  public strictEmailValidator = this.customValidationsService.strictEmailValidator
  vendorForm = this.fb.group({
    name: ['', [Validators.required]],
    vendorBusinessTypeId: [null, [Validators.required]],
    address: this.fb.group({
      street1: ['', Validators.required],
      street2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
    }),
    contacts: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobTitle: ['', [Validators.required]],
      email: ['', [Validators.required,this.strictEmailValidator]],
      phone: [
        '',
        [Validators.required],
      ],
      departmentTypeId: [{ value: 1, disabled: true }, [Validators.required]],
      departmentName: ['', [Validators.required]],
    }),
  });
  private readonly destroyRef = inject(DestroyRef);
  public numericValidator = this.customValidationsService.numericValidator;

  constructor(
    private vendorsService: VendorsService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private customValidationsService: CustomeValidationsService,
    private notificationService: NotificationService,
    private dataService: DataSharingService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg')
    );
  }

  ngOnInit() {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.vendorForm.valid) {
          this.dialogService.onData(this.createData());
        } else {
          this.vendorForm.markAllAsTouched();
          this.notificationService.error('Please fill all required fields.');
        }
      });
    this.vendorForm.statusChanges.subscribe((status: any) => {
      console.log(this.vendorForm);

      this.dataService.isFormValid.next(this.vendorForm.valid);
    });
  }


  createData() {
    const { name, vendorBusinessTypeId, address, contacts } =
      this.vendorForm.value;
    const body = {
      name,
      vendorBusinessTypeId,
      address,
      contacts: [
        {
          firstName: contacts?.firstName,
          lastName: contacts?.lastName,
          jobTitle: contacts?.jobTitle,
          email: contacts?.email,
          phone: contacts?.phone,
          isAccountManager: true,
          departmentTypeId: 1,
          departmentName: contacts?.departmentName,
        },
      ],
    };
    return body;
  }
}
