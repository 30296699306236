import { NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { DataSharingService } from '../../services/data-sharing.service';

@Component({
  selector: 'app-theme-container',
  standalone: true,
  imports: [
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    NgClass
  ],
  providers: [HttpClient],
  templateUrl: './theme-container.component.html',
  styleUrl: './theme-container.component.scss',
})
export class ThemeContainerComponent {
  @Input() title!: string;
  @Input() cta!: string;
  @Input() totalRecords!: number;
  @Input() searchPlaceholder!: string;
  @Input() minheight!: boolean;
  @Input() hideSearch: boolean = false;
  @Input() downloadbtn: string | null = null;
  @Input() publishBtn: string | null = null;
  enablePublish : boolean = false;
  @Output() filter = new EventEmitter();
  @Output() ctaClick = new EventEmitter();
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  @ViewChild('publishButton', { static: true }) publishButton!: ElementRef;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private dataService: DataSharingService
  ) {
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/add.svg'
      )
    );
  }
  
  ngOnInit() {
    if(!this.hideSearch){
      this.applyFilter();
    }
    this.dataService.areRowsSelected.subscribe((res: boolean) => {
      this.enablePublish = res;
    });
  }

  applyFilter() {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((event: any) => (event.target as HTMLInputElement).value),
        debounceTime(300), // Debounce time to delay the API call
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.filter.emit(query);
      });
  }

  openDialog() {
    this.ctaClick.emit();
  }
}
