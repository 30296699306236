import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentCardComponent } from '../document-card/document-card.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-required-docs',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, DocumentCardComponent],
  templateUrl: './required-docs.component.html',
  styleUrl: './required-docs.component.scss',
})
export class RequiredDocsComponent {
  @Output() remarks = new EventEmitter<any>();
  documents: any = [];

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.matIconRegistry.addSvgIcon(
      'attachedImg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/attached_img.svg'
      )
    );

    this.http
      .get<{ [state: string]: string[] }>('/assets/json/doc.json')
      .subscribe((data) => {
        this.documents = data;
      });
  }

  addRemarks() {
    this.remarks.emit();
  }
}
