import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-accounting-review",
  standalone: true,
  imports: [MatInputModule, MatButtonModule],
  templateUrl: "./accounting-review.component.html",
  styleUrl: "./accounting-review.component.scss",
})
export class AccountingReviewComponent {
  @Output() remarks = new EventEmitter<any>();

  addRemarks() {
    this.remarks.emit();
  } 
}
