import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../components/header/header.component';
import { NotificationService } from '../services/notification.service';
import { DialogService } from '../services/dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Promotion, PromotionsService } from './promotions.service';
import { Subscription, switchMap } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { PromotionDialogComponent } from './promotion-dialog/promotion-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { PromtionBannerComponent } from './promtion-banner/promtion-banner.component';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogBoxComponent } from '../components/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { ThemeContainerComponent } from "../components/theme-container/theme-container.component";

@Component({
  selector: 'app-promotions',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    MatTabsModule,
    MatMenuModule,
    PromtionBannerComponent,
    ThemeContainerComponent
],
  providers: [HttpClient],
  templateUrl: './promotions.component.html',
  styleUrl: './promotions.component.scss',
})
export class PromotionsComponent {
  dialogSubscription!: Subscription;
  public isLoading: boolean = false;
  public currentPromotions: Promotion[] = [];
  public expiredPromotions: Promotion[] = [];
  public upcomingPromotions: Promotion[] = [];
  selectedTabIndex: number = 0;
  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private promotionsService: PromotionsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataService: DataSharingService,
    private dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'more_vert',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/menu-bar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pencil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/pencil.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/download.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/calendar.svg'
      )
    );
  }

  ngOnInit() {
    this.pageChange('current');
    this.pageChange('expired');
    this.pageChange('upcoming');
  }

  openDialog(promotion?: Promotion) {
    const dialogRef = this.dialogService.open({
      component: PromotionDialogComponent,
      title: promotion ? 'Update Promotion' : 'Create Promotion',
      actionLabel: promotion ? 'Update' : 'Add',
      data: promotion,
      themeModal: true,
      hideCloseIcon: true
    });

    this.dialogSubscription?.unsubscribe();
    if (promotion) {
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.promotionsService.updatePromotion(promotion.id, data as any)
          )
        )
        .subscribe((data) => {
          dialogRef.close();
          this.pageChange('current');
          this.pageChange('expired');
          this.pageChange('upcoming');
          this.notificationService.success('Your promotion has been updated');
        });
    } else {
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.promotionsService.createPromotion(data as any)
          )
        )
        .subscribe((data) => {
          dialogRef.close();
          this.pageChange('current');
          this.pageChange('expired');
          this.pageChange('upcoming');
          this.notificationService.success('Your promotion has been created');
        });
    }
  }

  pageChange(status: string) {
    this.isLoading = true;
    this.promotionsService.getPromotions(status).subscribe((response) => {
      this.isLoading = false;
      if (response.length) {
        if (status === 'current') {
          this.currentPromotions = response;
          this.getPromotionsImage(this.currentPromotions[0].id);
        } else if (status === 'expired') {
          this.expiredPromotions = response;
        } else if (status === 'upcoming') {
          this.upcomingPromotions = response;
        }
      }
    });
  }

  getPromotionsImage(id: number) {
    this.isLoading = true;
    this.promotionsService.getPromotionsImage(id).subscribe((response) => {
      this.isLoading = false; 
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  deletePromotion(id: number) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this Promotion?', 
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.promotionsService.deletePromotion(id).subscribe((res) => {
          this.notificationService.success('Promotion has been deleted');
          this.pageChange('current');
          this.pageChange('expired');
          this.pageChange('upcoming');
          // if (this.selectedTabIndex === 0) {
          //   this.pageChange('current');
          // } else if (this.selectedTabIndex === 1) {
          //   this.pageChange('expired');
          // } else if (this.selectedTabIndex === 2) {
          //   this.pageChange('upcoming');
          // }
        });
      }})
  }

  openSlider() {
    this.dialogService.openSlider([
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
      'assets/publication.jpeg',
      'assets/logo.png',
      'assets/publication.jpeg',
    ]);
  }
}
