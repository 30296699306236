import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DataSharingService } from '../../../services/data-sharing.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-ticket-status',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  templateUrl: './ticket-status.component.html',
  styleUrl: './ticket-status.component.scss',
})
export class TicketStatusComponent {
  statusForm;
  constructor(
    private readonly fb: FormBuilder,
    private readonly dataService: DataSharingService,
    private readonly dialogService: DialogService
  ) {
    this.statusForm = this.fb.group({
      assignedTo: ['', Validators.required],
    });

    this.dialogService.save$.subscribe(() => {
      if (this.statusForm.valid) {
        this.dialogService.onData(this.statusForm.value);
      }
    });
  }

  ngOnInit() {
    this.statusForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.statusForm.valid);
    });
  }
}
