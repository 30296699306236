import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup, 
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DataSharingService } from '../../../services/data-sharing.service';
import { DialogService } from '../../../services/dialog.service';
@Component({
  selector: 'app-ticket-closing',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  templateUrl: './ticket-closing.component.html',
  styleUrl: './ticket-closing.component.scss',
})
export class TicketClosingComponent { 
  statusForm: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly dataService: DataSharingService,
    private readonly dialogService: DialogService
  ) { 

    this.statusForm = this.fb.group({
      closingStatement: ['', Validators.required],
    });

    this.dialogService.save$.subscribe(() => {
      if (this.statusForm.valid) {
        this.dialogService.onData(this.statusForm.value);
      }
    });
  }
  ngOnInit() {
    this.statusForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.statusForm.valid);
    });
  }
}
