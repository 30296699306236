import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

export interface Publication {
  id: number;
  title: string;
  name: string;
  description: string;
  createdDate: number;
  thumbnail?: any;
}

@Injectable({
  providedIn: 'root',
})
export class PublicationsService {
  private readonly data = new BehaviorSubject<Publication[]>([]);

  data$ = this.data.asObservable();
  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  getPublicationById(id: number): Observable<Publication | null> {
    return this.data$.pipe(
      map((data) => data.find((publication) => publication.id === id) || null)
    );
  }

  getPublicationThumbnail(publicationId: number) {
    return this.http.get(
      `${environment.baseUrl}/metadata/publications/${publicationId}/thumbnail`,
      { responseType: 'blob' }
    );  
  }

  getPublications(page: number) {
    return this.http.get<Publication[]>(
      `${environment.baseUrl}/management/publications?page=${page}&size=10`
    );
  }

  searchPublications(query: string) {
    return this.http.get<Publication[]>(
      `${environment.baseUrl}/management/publications/search?query=${query}`
    );
  }

  downloadPublication(id: any) { 
    return this.http.get(`${environment.baseUrl}/management/publications/${id}`, { responseType: 'blob' });
  }

  createPublication(data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post<any>(`${environment.baseUrl}/management/publications`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  deletePublication(id: number) {
    return this.http.delete<Publication>(
      `${environment.baseUrl}/management/publications/${id}`
    );
  }

  search(query: any) {
    return this.http.get<Publication>(
      `${environment.baseUrl}/management/publications/search?query=${query}`
    );
  }
}
