import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../components/header/header.component';
import { NotificationService } from '../services/notification.service';
import {
  TableColumns,
  TableComponent,
} from '../components/table/table.component';
import { DialogService } from '../services/dialog.service';
import { ContainerComponent } from '../components/container/container.component';
import { MatButtonModule } from '@angular/material/button';
import { Subscription, switchMap } from 'rxjs';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { VendorElement, VendorsService } from './vendors.service';
import { VendorDialogComponent } from './vendor-dialog/vendor-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import { DataSharingService } from '../services/data-sharing.service';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../components/theme-table/theme-table.component';

@Component({
  selector: 'app-vendors',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    TableComponent,
    ContainerComponent,
    CustomColumnDirective,
    MatChipsModule,
    ThemeContainerComponent,
    ThemeTableComponent,
  ],
  providers: [HttpClient, DataSharingService],
  templateUrl: './vendors.component.html',
  styleUrl: './vendors.component.scss',
})
export class VendorsComponent {
  selection = new SelectionModel<VendorElement>(true, []);
  displayedColumns: TableColumns[] = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'Name' },
    {
      key: 'vendorBusinessType',
      label: 'Business Type',
      displayValue: (data) => data.vendorBusinessType.key,
    },
    {
      key: 'address',
      label: 'Address',
      displayValue: (data) =>
        data.address.street1 +
        ', ' +
        data.address.street2 +
        ', ' +
        data.address.city +
        ',' +
        data.address.state,
    },
    {
      key: 'representative',
      label: 'Representative(s)',
      displayValue: (data) =>
        data.accountManagers.toString().slice(0, 35) +
        (data.accountManagers.toString().length > 35 ? '...' : ''),
    },
    { key: 'isActive', label: 'Status' },
  ];
  dataSource = new MatTableDataSource<VendorElement>([]);
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;
  dialogSubscription!: Subscription;
  metrics$ = this.vendorsService.getMetrics();

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private vendorsService: VendorsService,
    private router: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataService: DataSharingService
  ) {
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'total',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/total.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'progress',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/progress.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pencil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/pencil.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eye-open',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/eye-open.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'completed',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/completed.svg'
      )
    );
  }

  openDialog(id?: number) {
    const dialogRef = this.dialogService.open({
      component: VendorDialogComponent,
      title: 'Add Vendor',
      actionLabel: 'Create',
      hideCloseIcon: true,
      themeModal: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$
      .pipe(
        switchMap((data) =>
          this.vendorsService.createVendor(data as VendorElement)
        )
      )
      .subscribe({
        complete: () => {},
        error: (err: any) => {
          this.notificationService.error(err.message);
        },
        next: (res: any) => {
          dialogRef.close();
          this.dataService.isFormValid.next(true);
          this.pageChange(0);
          this.notificationService.success('Vendor has been added');
        },
      });
  }

  pageChange(page: number) {
    this.vendorsService.getVendors(page).subscribe((response: any) => {
      const { vendors, totalPages, pageNumber, totalRecords } = response;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber;
      this.dataSource = vendors;
    });
  }

  viewDetail(id: number) {
    this.router.navigate(['/vendors/detail', id]);
  }

  applyFilter(event: any) {
    this.isLoading = true;
    this.vendorsService
      .searchVendor(event, 0, 10)
      .subscribe((response: any) => {
        const { vendors, totalPages, pageNumber, totalRecords } = response;
        this.isLoading = false;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
        this.pageNumber = pageNumber;
        this.dataSource = vendors;
      });
  }

  getRepresentatives(item: any) {
    const accountManagers = item.accountManagers
      .toString()
      .split(',')
      .map((manager: any) => manager.trim());
    if (accountManagers.length > 1) {
      return `${
        accountManagers[0]
      } <span class="additional-count text-[#3F31F2]">+${
        accountManagers.length - 1
      }</span>`;
    }
    return accountManagers[0];
  }
}
