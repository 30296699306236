import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

export interface Promotion {
  id: number;
  name: string;
  title: string;
  description: string;
  createDate: number;
  startDate?: string;
  endDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  private readonly data = new BehaviorSubject<Promotion[]>([]);

  data$ = this.data.asObservable();
  private readonly promotion: Promotion = {
    id: 1,
    name: '',
    title: 'Convenience store with fuel',
    description:
      'Convenience store with fuelConvenience store with fuelConvenience store with fuel',
    createDate: 1714387419022,
  };

  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  private generatePromotions(): void {
    const Promotions: Promotion[] = [];
    for (let i = 0; i < 30; i++) {
      const Promotion = {
        ...this.promotion,
        id: this.promotion.id + 1,
      };
      Promotions.push(Promotion);
    }
    this.data.next(Promotions);
  }

  getData(): Promotion[] {
    return this.data.getValue();
  }

  createPromotion(data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post<any>(`${environment.baseUrl}/management/promotions`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updatePromotion(id: number, data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .put<any>(`${environment.baseUrl}/management/promotions/${id}`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  getPromotionById(id: number): Observable<Promotion | null> {
    return this.data$.pipe(
      map((data) => data.find((promotion) => promotion.id === id) || null)
    );
  }

  getPromotions(status: string) {
    return this.http.get<Promotion[]>(
      `${environment.baseUrl}/management/promotions?status=${status}`
    );
  }

  getPromotionsImage(id: number) {
    return this.http.get(`${environment.baseUrl}/management/promotions/${id}`, {
      responseType: 'arraybuffer',
    });
  }

  deletePromotion(id: number) {
    return this.http.delete<Promotion>(
      `${environment.baseUrl}/management/promotions/${id}`
    );
  }
}
