<div class="document-item p-4 rounded-xl">
  <h4 class="flex items-center justify-between">
    {{ documentData?.docType?.key }}
    <div class="flex items-center gap-3">
      <mat-icon class="download-icon" svgIcon="download"></mat-icon
      ><mat-icon svgIcon="view" onkeydown (click)="viewImage()"></mat-icon>
    </div>
  </h4>
  <ul class="flex justify-between attachment-info">
    <li><span>Number</span>{{ documentData?.number }}</li>
    @if(documentData?.issuingState) {
    <li><span>State</span>{{ documentData?.issuingState }}</li>
    } @if(documentData?.expirationDate) {
    <li><span>Expiry Date</span>{{ documentData?.expirationDate }}</li>
    }
  </ul>
</div>
