import { Component } from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { MemberElement, MembersService } from '../members.service';
import { Observable, filter, tap } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';
import { AlertService } from '../../services/alert.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { VendorsService } from '../../vendors/vendors.service';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import {MatRadioModule} from '@angular/material/radio';
import { MemberDetailFuelComponent } from "./member-detail-fuel/member-detail-fuel.component";
@Component({
  selector: 'app-member-detail',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    HeaderComponent,
    MatChipsModule,
    MatTabsModule,
    MemberDetailFuelComponent
],
  providers: [],
  templateUrl: './member-detail.component.html',
  styleUrl: './member-detail.component.scss',
})
export class MemberDetailComponent {
  memberForm = this.fb.group({
    businessName: [' ', [Validators.required]],
    businessEmail: [' ', [Validators.required]],
    businessType: [' ', [Validators.required]],
    businessNumber: [' ', [Validators.required]],
    businessContact: [' ', [Validators.required]],
    businessMembership: [' ', [Validators.required]],
    businessAs: [' ', [Validators.required]],
    businessAddress1: [' ', [Validators.required]],
    businessAddress2: [' ', [Validators.required]],
    businessState: [' ', [Validators.required]],
    businessCity: [' ', [Validators.required]],
    businessZip: [' ', [Validators.required]],
    businessRepAs: [' ', [Validators.required]],
    businessRepName: [' ', [Validators.required]],
    businessRepEmail: [' ', [Validators.required]],
    businessRepPhone: [' ', [Validators.required]],
    businessRepTitle: [' ', [Validators.required]],
    businessRepPercentage: [' ', [Validators.required]],
    businessOwnerAs: [' ', [Validators.required]],
    businessOwnerEmail: [' ', [Validators.required]],
    businessOwnerName: [' ', [Validators.required]],
    businessOwnerTitle: [' ', [Validators.required]],
    businessOwnerPercentage: [' ', [Validators.required]],
    businessOwnerPhone: [' ', [Validators.required]],
    owners: this.fb.array([]),
  });

  member$!: Observable<MemberElement | null>;
  isLoading!: boolean;
  member!: any;
  memberId!: number;
  previousFormValue: any; 

  constructor(
    private alertService: AlertService,
    private membersService: MembersService,
    private fb: FormBuilder,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private vendorsService: VendorsService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.headerService.setShowBackIcon(true);
  }

  ngOnInit(): void {
    this.memberId = +this.route.snapshot.paramMap.get('id')!;

    // this.member$ = this.membersService.getMemberById(this.memberId);
    // this.membersService.getMemberById(this.memberId)
    //   .subscribe(member => {
    //     if (member) {
    //       this.memberForm.patchValue(member);
    //     }
    //   });
    this.memberForm.disable();
    this.fetchMemberDetail().subscribe();
  }

  deactivate() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to deactivate this member?',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.membersService.deactivateMember(this.memberId).subscribe((res) => {
          this.notificationService.success('Member has been deactivated');
          this.router.navigate(['/members']);
        });
      }
    });
  }

  activate() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to activate this member?',
        type: 'primary',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.membersService.activateMember(this.memberId).subscribe((res) => {
          this.notificationService.success('Member has been activated');
          this.router.navigate(['/members']);
        });
      }
    });
  }

  get owners(): FormArray {
    return this.memberForm.get('owners') as FormArray;
  }

  fetchMemberDetail() {
    this.isLoading = true;
    this.member$ = this.membersService.getMemberDetailsById(this.memberId);
    return this.membersService.getMemberDetailsById(this.memberId).pipe(
      tap((member) => {
        if (member) {
          this.member = member;
          const {
            id,
            name,
            email,
            phone,
            memberType,
            membershipType,
            address,
            owners,
            dba,
          } = this.member;

          const formValues = {
            businessName: name,
            businessEmail: email,
            businessType: memberType.key,
            businessNumber: id,
            businessContact: phone,
            businessMembership: membershipType.key,
            businessAs: dba,
            businessAddress1: address.street1,
            businessAddress2: address.street2,
            businessState: address.state,
            businessCity: address.city,
            businessZip: address.zipcode,
            businessRepAs: owners[0].ownerType.key,
            businessRepName: owners[0].name,
            businessRepEmail: owners[0].email,
            businessRepPhone: owners[0].phone,
            businessRepTitle: owners[0].title,
            businessRepPercentage: owners[0].ownership * 100,
            businessOwnerAs: owners[0].ownerType.key,
            businessOwnerEmail: owners[0].email,
            businessOwnerTitle: owners[0].title,
            businessOwnerName: owners[0].name,
            businessOwnerPhone: owners[0].phone,
            businessOwnerPercentage: owners[0].ownership * 100,
          };
          this.memberForm.patchValue(formValues as any);
          // this.memberForm.disable(); 
          this.isLoading = false;
          this.owners.clear(); 
          owners.forEach((value:any) => {
            this.owners.push(this.fb.group({
              businessOwnerEmail: [value.email],
              businessOwnerName: [value.name],
              businessOwnerTitle: [value.title],
              businessOwnerPercentage: [value.ownership * 100],
              businessOwnerPhone: [value.phone]
            }));
          });
          this.previousFormValue = this.memberForm.value;
          this.memberForm.disable();
        }
      })
    );
  }
}
