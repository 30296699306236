import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DocumentCardComponent } from '../document-card/document-card.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-owner-information',
  standalone: true,
  imports: [
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    DocumentCardComponent,
  ],
  templateUrl: './owner-information.component.html',
  styleUrl: './owner-information.component.scss',
})
export class OwnerInformationComponent {
  @Output() remarks = new EventEmitter<any>();
  owners: any = [];

  constructor(private http: HttpClient) {
    this.http
      .get<{ [state: string]: string[] }>('/assets/json/owner_info.json')
      .subscribe((data) => {
        this.owners = data;
      });
  }

  addRemarks() {
    this.remarks.emit();
  }
}
