import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class HeaderService {
    private showBackIconSubject = new BehaviorSubject<boolean>(false);
    showBackIcon$: Observable<boolean> = this.showBackIconSubject.asObservable();

    constructor() {}

    setShowBackIcon(show: boolean) {
        this.showBackIconSubject.next(show);
    }

    getShowBackIcon(): Observable<boolean> {
        return this.showBackIcon$;
    }
  }