<form [formGroup]="statusForm">
  <mat-form-field appearance="outline" class="w-full white-backgrounded-field">
    <mat-label>Closing Statement</mat-label>
    <textarea
      matInput
      formControlName="closingStatement"
      rows="3"
      placeholder="Add closing remarks here..."
    ></textarea>
  </mat-form-field>
</form>
