<form [formGroup]="vendorContactForm" class="contacts">
  <div class="flex">
    <div class="flex flex-auto space-x-5">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
        [ngClass]="{'contacts__readonly': data.data}"
      >
        <mat-label>First Names</mat-label>
        <input matInput formControlName="firstName" [readonly]="data.data"/>
        @if(vendorContactForm.controls.firstName.hasError('required')){
          <mat-error> First Name is required. </mat-error>
          }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
        [ngClass]="{'contacts__readonly': data.data}"
      >
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" [readonly]="data.data"/>
        @if(vendorContactForm.controls.lastName.hasError('required')){
          <mat-error> Last Name is required. </mat-error>
          }
      </mat-form-field>
    </div>
  </div>
  <div class="flex">
    <div class="flex flex-auto space-x-5">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Job Title</mat-label>
        <input matInput formControlName="jobTitle" />
        @if(vendorContactForm.controls.jobTitle.hasError('required')){
          <mat-error> Job Title is required. </mat-error>
          }
      </mat-form-field>
    </div>
  </div>
  <div class="flex">
    <div class="flex flex-auto space-x-5">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Department Name</mat-label>
        <input matInput formControlName="departmentName" />
        @if(vendorContactForm.controls.departmentName.hasError('required')){
          <mat-error> Department name is required. </mat-error>
          }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Department Type</mat-label>
        <mat-select formControlName="departmentTypeId" name="departmentTypeId">
          @for (department of departmentTypes$ | async; track department) {
          <mat-option [value]="department.value">{{
            department.key
          }}</mat-option>
          }
        </mat-select>
        @if(vendorContactForm.controls.departmentTypeId.hasError('required')){
          <mat-error> Department type is required. </mat-error>
          }
      </mat-form-field>
    </div>
  </div>
  <div class="flex">
    <div class="flex flex-auto space-x-5">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" />
        @if(vendorContactForm.controls.email.hasError('required')){
          <mat-error> Email is required. </mat-error>
          }
          @if(vendorContactForm.controls.email.hasError('email')){
            <mat-error> Invalid email </mat-error>
            }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phone" mask="(000) 000-0000" (keydown)="numericValidator($event)" minlength="10" />
        @if(vendorContactForm.controls.phone.hasError('required')){
          <mat-error> Phone number is required. </mat-error>
          }
      </mat-form-field>
    </div>
  </div>
</form>
