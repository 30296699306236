import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private _snackBar: MatSnackBar) {
  }

  success(message: string) {
    return this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: 'Success',
        message: message
      },
      duration: 5 * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['snackbar-success']
    });
  }

  error(message: string) {
    return this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: 'Error',
        message: message
      },
      duration: 5 * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['snackbar-error']
    });
  }
}
