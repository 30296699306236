import {
  Component, 
  DestroyRef,
  inject
} from '@angular/core';
import {
  FormBuilder, 
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { QuestFuelService } from '../quest-fuel.service';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataSharingService } from '../../services/data-sharing.service';
import { NotificationService } from '../../services/notification.service'; 
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser'; 
@Component({
  selector: 'app-add-quest-fuel',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    AsyncPipe,
    MatInputModule,
    FormsModule,
    MatIconModule
  ],
  templateUrl: './add-quest-fuel.component.html',
  styleUrl: './add-quest-fuel.component.scss',
})
export class AddQuestFuelComponent {
  private readonly destroyRef = inject(DestroyRef);
  questFuelForm = this.fb.group({
    geographyTypeId: [null, [Validators.required]],
    formulaRegular: ['', [Validators.required]],
    formularSuper: ['', [Validators.required]],
    formulaDiesel: ['', [Validators.required]],
    formulaBioDiesel: ['', [Validators.required]],
    regularRegular: ['', [Validators.required]],
    regularrSuper: ['', [Validators.required]],
    regularDiesel: ['', [Validators.required]],
    regularBioDiesel: ['', [Validators.required]],
  });
  locations$ = this.questFueService.getLocations();
  constructor(
    private questFueService: QuestFuelService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private dataService: DataSharingService,
    private notificationService: NotificationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );  
  }

  ngOnInit() {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.questFuelForm.valid) { 
          this.dialogService.onData(this.questFuelForm);
        } else {
          this.questFuelForm.markAllAsTouched();
          this.notificationService.error('Please fill all required fields.');
        }
      });
    this.questFuelForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.questFuelForm.valid);
    });
  }
}
