import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../services/notification.service';
import { DataSharingService } from '../../services/data-sharing.service';

export interface Attachment {
  id: number;
  name: string;
}

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule, HttpClientModule],
  providers: [HttpClient],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss',
})
export class UploadComponent {
  @Input() form!: FormGroup;
  @Input() formArrayName!: string;
  @Input() attachments: Attachment[] = [];
  @Input() isDisabled = false;
  @Input() maxSizeMB: number = 50;
  @Input() allowedExtensions: any = [];
  @ViewChild('fileInput') fileInputRef!: ElementRef;
  private maxSizeInBytes = 0;
  public isButtonDisabled: boolean = true

  constructor(
    private fb: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private dataService: DataSharingService
  ) {
    this.matIconRegistry.addSvgIcon(
      'upload',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/upload.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/trash-filled.svg'
      )
    );
  }

  ngOnInit() {
    this.maxSizeInBytes = this.maxSizeMB * 1024 * 1024;
    this.dataService.isFormValid.subscribe((res: boolean) => {
      this.isButtonDisabled = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachments']) {
      this.fillAttachments();
    }
  }

  fillAttachments() {
    const attachmentsForm = this.form.get(this.formArrayName) as FormArray;
    attachmentsForm.clear();

    this.attachments.forEach((attachment) => {
      const { id, name } = attachment;
      const group = this.fb.group({ id, name, deleted: false });
      attachmentsForm.controls.push(group);
    });
  }

  getAllowedTypes(): string {
    return this.allowedExtensions.join(',');
  }

  onFileSelected(event: any) {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;
    if (files && files.length > 0) {
      const file: File = files[0];
      if (file.size > this.maxSizeInBytes) {
        target.value = '';
        this.notificationService.error('maximum file size exceeded');
        return;
      }
      this.addFiles(files);
      target.value = '';
    }
  }

  browseFile() {
    this.fileInputRef.nativeElement.click();
  }

  addFiles(files: FileList) {
    const attachments = this.form.get(this.formArrayName) as FormArray;
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      attachments.push(this.createFileFormGroup(file));
    }
  }

  createFileFormGroup(file: File) {
    return this.fb.group({
      id: null,
      name: [file.name],
      file: [file],
      deleted: false,
    });
  }

  removeFile(index: number) {
    const attachments = this.form.get(this.formArrayName) as FormArray;
    const fileGroup = attachments.at(index);
    if (fileGroup.value.id) {
      fileGroup.patchValue({ deleted: true });
    } else {
      attachments.removeAt(index);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.addFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  getFilesArray(): FormArray {
    return this.form.get(this.formArrayName) as FormArray;
  }
}
