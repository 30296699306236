import { Component } from '@angular/core';
import { ThemeUploadComponent } from '../../../components/theme-upload/theme-upload.component';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { VendorsService } from '../../../vendors/vendors.service';
import { GenericService } from '../../../services/generic.service';

@Component({
  selector: 'app-transaction-create',
  standalone: true,
  imports: [
    MatLabel,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    ThemeUploadComponent
  ],
  templateUrl: './transaction-create.component.html',
  styleUrl: './transaction-create.component.scss',
})
export class TransactionCreateComponent {
  transactionForm!: FormGroup;
  attachmentForm!: FormGroup;
  attachment: any;
  vendors: any;
  fiscalPeriods: any;
  transactionTypes: any;
  rebateTypes: any
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];

  constructor(
    private fb: FormBuilder,
    private vendorService: VendorsService,
    private genericService: GenericService
  ) {}

  ngOnInit(): void {
    this.transactionForm = this.fb.group({
      vendorId: ['', Validators.required],
      rebateRate: ['', Validators.required],
      rebateTypeId: ['', Validators.required],
      transactionTypeId: ['', Validators.required],
      fiscalYear: ['', Validators.required], 
      qType: ['', Validators.required], 
    });
    this.attachmentForm = this.fb.group({ 
      attachments: this.fb.array([]), 
    });
    this.vendorService.getVendor().subscribe((res: any) => {
      this.vendors = res;
    });
    this.genericService.getData(this.genericService.endPoints.finance.getAllTransactionTypes).subscribe((res: any) => {
      this.transactionTypes = res;
    });
    this.genericService.getData(this.genericService.endPoints.finance.getAllFiscalPeriods).subscribe((res: any) => {
      this.fiscalPeriods = res;
    });
    this.genericService.getData(this.genericService.endPoints.finance.getAllRebateTypes).subscribe((res: any) => {
      this.rebateTypes = res;
    });
  }

  onSubmit(): void {
    if (this.transactionForm.valid) {
      console.log(this.transactionForm.value);
    } else {
      console.error('Form is invalid');
    }
  }
}
