import { Component } from "@angular/core";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-add-remarks",
  standalone: true,
  imports: [MatInputModule],
  templateUrl: "./add-remarks.component.html",
  styleUrl: "./add-remarks.component.scss",
})
export class AddRemarksComponent {}
