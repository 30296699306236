import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestFuelService {

  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  getLocations() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/metadata/quest-fuel/geographies`
    );
  }

  getBrands() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/metadata/quest-fuel/brands`
    );
  }

  getPricingTiers() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/metadata/quest-fuel/pricing-tiers`
    );
  }

  getProducts() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/metadata/quest-fuel/products`
    );
  }

  getCurrentPricing(tierId: number, brandId: number) {
    return this.http.get<any[]>(
      `${environment.baseUrl}/management/quest-fuel/current-pricing?tierId=${tierId}&brandId=${brandId}`
    );
  }

  createPricing(payload: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post(`${environment.baseUrl}/management/quest-fuel/pricing`, payload)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }
}


