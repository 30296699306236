import { Component } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../components/theme-table/theme-table.component';
import { TableColumns } from '../components/table/table.component';
import { Router } from '@angular/router';
import { CustomColumnDirective } from '../directives/custom-column.directive';

@Component({
  selector: 'app-member-applications',
  standalone: true,
  imports: [HeaderComponent, ThemeContainerComponent, ThemeTableComponent],
  templateUrl: './member-applications.component.html',
  styleUrl: './member-applications.component.css',
})
export class MemberApplicationsComponent {
  applyFilter(event: any) {}
  dataSource = [{
    companyInfo: 'Seven Eleven',
    date: '10 - 02 - 2024',
    address: 'NY. New York',
    mob: '+91 3225445577',
    status: 'Submitted'
  }];
  totalPages = 10;
  totalRecords = 10;
  pageNumber = 5;
  displayedColumns: TableColumns[] = [
    {
      key: 'companyInfo',
      label: 'Company Information',
    },
    {
      key: 'date',
      label: 'Created Date',
    },
    {
      key: 'address',
      label: 'Store Address',
    },
    {
      key: 'mob',
      label: 'Mobile Number',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ];
  constructor( private router: Router){}
  viewDetail(id: number = 1) {
    this.router.navigate(['/member-applications/member-applications-detail']);
  }
  pageChange(event: any) {}
}
