// src/app/auth.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, from, Observable, of, switchMap, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('msalToken');
    const authReq = this.addAuthHeader(req, token);

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.acquireTokenSilent().pipe(
            switchMap((newToken) => {
              const newReq = this.addAuthHeader(req, newToken);
              return next.handle(newReq);
            }),
            catchError((innerError) => {
              console.error('Error acquiring token silently:', innerError);
              return throwError(innerError);
            })
          );
        } else {
          console.error('HTTP error:', error);
          return throwError(error);
        }
      })
    );
  }

  private addAuthHeader(req: HttpRequest<any>, token: string | null) {
    if (token) {
      return req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    return req;
  }

  acquireTokenSilent(): Observable<string> {
    const request = {
      scopes: [environment.readAllScope, environment.readWriteScope],
    };

    return from(this.msalService.acquireTokenSilent(request)).pipe(
      switchMap((response) => {
        localStorage.setItem('msalToken', response.accessToken);
        return of(response.accessToken);
      }),
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return from(this.msalService.acquireTokenPopup(request)).pipe(
            switchMap((response) => {
              localStorage.setItem('msalToken', response.accessToken);
              return of(response.accessToken);
            })
          );
        } else {
          console.error('Error acquiring token:', error);
          return throwError(error);
        }
      })
    );
  }
}
