<div class="h-full">
  <mat-sidenav-container class="h-full" autosize autoFocus>
    <mat-sidenav opened class="" mode="side">
      <app-sidemenu />
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap">
      <!-- <app-header /> -->
      <div class="h-full">
        <router-outlet />
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
