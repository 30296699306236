@if (isLoading) {
  <div class="theme-spinner">
    <mat-spinner></mat-spinner>
</div>
}
<app-header>
  <p title class="items-center flex">
    {{ vendor?.name }}
  </p>
  @if(vendor){ @if(vendorId!=1){ @if (vendor?.isActive) {
  <button (click)="deactivate()" mat-stroked-button class="deactivate-btn">
    Deactivate Vendor
  </button>
  } @else {
  <button
    mat-stroked-button
    color="primary"
    (click)="activate()"
    class="theme-stroke-btn activate-btn"
  >
    <span class="uppercase">Activate Vendor</span>
  </button>
  } } }
</app-header>
<div class="details main-content">
  <div class="col-span-4 flex flex-col justify-center space-y-5 p-6">
    <div class="theme-card" [class.isEditing]="isAddressEditing">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-medium">Address</h2>
        <div>
          @if (!isAddressEditing){
          <button
            (click)="edit('address')"
            [disabled]="isAddressEditing"
            mat-stroked-button
            color="primary"
            class="cursor-pointer ml-auto px-2.5 theme-stroke-btn"
          >
            <span
              class="flex text-mat-primary uppercase items-center font-medium"
            >
              <mat-icon class="mr-2" svgIcon="note-edit"></mat-icon>
              Edit
            </span>
          </button>
          } @if (isAddressEditing) {
          <div class="flex gap-4">
            <button
              mat-button
              (click)="cancelEdit()"
              class="ml-auto font-medium"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              (click)="update('address')"
              color="primary"
              class="ml-2 font-medium theme-btn"
            >
              Update
            </button>
          </div>
          }
        </div>
      </div>
      <form [formGroup]="vendorForm" class="space-y-5">
        <div class="flex py-6" formGroupName="address">
          <div class="flex-1 mr-4">
            <mat-form-field
              appearance="outline"
              class="w-full white-backgrounded-field mb-6"
            >
              <mat-label>Address 1</mat-label>
              <input matInput formControlName="street1" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="w-full white-backgrounded-field"
            >
              <mat-label>Select City</mat-label>
              <input matInput formControlName="city" />
            </mat-form-field>
          </div>
          <div class="flex-1 mr-4">
            <mat-form-field
              appearance="outline"
              class="w-full white-backgrounded-field mb-6"
            >
              <mat-label>Address 2</mat-label>
              <input matInput formControlName="street2" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="w-full white-backgrounded-field"
            >
              <mat-label>Zip Code</mat-label>
              <input matInput formControlName="zipcode" />
            </mat-form-field>
          </div>
          <div class="flex-1 mr-4">
            <mat-form-field
              appearance="outline"
              class="w-full white-backgrounded-field"
            >
              <mat-label>Select State</mat-label>
              <input matInput formControlName="state" />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="theme-card">
      <div class="flex items-center justify-center">
        <h2 class="text-lg font-medium">Representative Contacts</h2>
        <button
          mat-stroked-button
          color="primary"
          class="cursor-pointer ml-auto px-2.5 theme-stroke-btn"
          (click)="openDialog('Add Representative Contact', 'Save', null)"
        >
          <span
            class="flex text-mat-primary uppercase items-center font-medium"
          >
            <mat-icon class="mr-2" svgIcon="add"></mat-icon>
            Add
          </span>
        </button>
      </div>
      @if(vendorContactForm?.value?.contacts?.length){
      <form [formGroup]="vendorContactForm" class="pt-5">
        <div
          class="flex flex-col mt-0"
          *ngFor="
            let control of vendorContactForm['controls'].contacts['controls'];
            let idx = index
          "
        >
          <ng-container formArrayName="contacts">
            <div class="flex justify-between">
              <h2 class="text-base font-medium">
                {{ control.value.firstName }} {{ control.value.lastName }}
              </h2>
              <div class="flex gap-5">
                @if(vendorContactForm['controls'].contacts['controls'].length >
                1){
                <button mat-button color="error" class="cursor-pointer ml-auto">
                  <span
                    class="flex text-[#F62005] items-center font-medium"
                    onKeyPress
                    (click)="deleteContact(control)"
                  >
                    Remove
                  </span>
                </button>
                }
                <button
                  (click)="
                    openDialog(
                      'Edit Representative Contact',
                      'Update',
                      control.value
                    )
                  "
                  mat-button
                  color="primary"
                  class="cursor-pointer ml-auto px-2.5 theme-stroke-btn"
                >
                  <span class="flex text-mat-primary items-center font-medium">
                    Edit
                  </span>
                </button>
              </div>
            </div>
            <div class="flex py-6">
              <div class="flex-1 mr-4" formGroupName="{{ idx }}">
                <mat-form-field
                  appearance="outline"
                  class="w-full white-backgrounded-field mb-6"
                >
                  <mat-label>Job Title</mat-label>
                  <input matInput formControlName="jobTitle" />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="w-full white-backgrounded-field"
                >
                  <mat-label>Email Address</mat-label>
                  <input matInput formControlName="email" />
                </mat-form-field>
              </div>
              <div class="flex-1 mr-4" formGroupName="{{ idx }}">
                <mat-form-field
                  appearance="outline"
                  class="w-full white-backgrounded-field mb-6"
                >
                  <mat-label>Department Name</mat-label>
                  <input matInput formControlName="departmentName" />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="w-full white-backgrounded-field"
                >
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="phone" />
                </mat-form-field>
              </div>
              <div class="flex-1 mr-4" formGroupName="{{ idx }}">
                <mat-form-field
                  appearance="outline"
                  class="w-full white-backgrounded-field"
                >
                  <mat-label>Department Type</mat-label>
                  <mat-select
                    formControlName="departmentTypeId"
                    name="departmentTypeId"
                  >
                    @for (department of departmentTypes$ | async; track
                    department) {
                    <mat-option [value]="department.value">{{
                      department.key
                    }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
      }
    </div>
    <div class="theme-card" [class.isEditing]="isEditing">
      <div class="justify-center flex items-center">
        <h2 class="text-lg font-medium">Department Contacts</h2>
        <button
          mat-stroked-button
          color="primary"
          class="cursor-pointer ml-auto px-2.5 theme-stroke-btn"
          (click)="openDialog('Add Department Contact', 'Save', null)"
        >
          <span
            class="flex text-mat-primary uppercase items-center font-medium"
          >
            <mat-icon class="mr-2" svgIcon="add"></mat-icon>
            Add
          </span>
        </button>
      </div>

      <div class="flex flex-col">
        @if(!departmentContactForm?.value?.contacts?.length){
        <ng-container>
          <div class="flex flex-col justify-center items-center p-6 space-y-3">
            <h4 class="text-base text-gray-700">
              Start adding vendor department contact by clicking on the button
            </h4>
          </div>
        </ng-container>
        } @if(departmentContactForm?.value?.contacts?.length){
        <form [formGroup]="departmentContactForm" class="space-y-5">
          <div
            class="flex flex-col mt-0"
            *ngFor="
              let deptControl of departmentContactForm['controls'].contacts[
                'controls'
              ];
              let i = index
            "
          >
            <ng-container formArrayName="contacts">
              <div class="flex items-center mt-5 justify-between">
                <h2 class="text-base font-medium">
                  {{ deptControl.value.firstName }}
                  {{ deptControl.value.lastName }}
                </h2>
                <div class="flex gap-4">
                  <button mat-button class="cursor-pointer ml-auto">
                    <span
                      mat-button
                      class="flex text-[#F62005] items-center font-medium"
                      onKeyPress
                      (click)="deleteContact(deptControl)"
                    >
                      Remove
                    </span>
                  </button>
                  <button
                    (click)="
                      openDialog(
                        'Edit Department Contact',
                        'Update',
                        deptControl.value
                      )
                    "
                    mat-button
                    color="primary"
                    class="cursor-pointer ml-auto px-2.5"
                  >
                    <span
                      class="flex text-mat-primary items-center font-medium theme-stroke-btn"
                    >
                      Edit
                    </span>
                  </button>
                </div>
              </div>
              <div class="flex py-6">
                <div class="flex-1 mr-4" formGroupName="{{ i }}">
                  <mat-form-field
                    appearance="outline"
                    class="w-full white-backgrounded-field mb-6"
                  >
                    <mat-label>Job Title</mat-label>
                    <input matInput formControlName="jobTitle" />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="w-full white-backgrounded-field"
                  >
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" />
                  </mat-form-field>
                </div>
                <div class="flex-1 mr-4" formGroupName="{{ i }}">
                  <mat-form-field
                    appearance="outline"
                    class="w-full white-backgrounded-field mb-6"
                  >
                    <mat-label>Department Name</mat-label>
                    <input matInput formControlName="departmentName" />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="w-full white-backgrounded-field"
                  >
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone" />
                  </mat-form-field>
                </div>
                <div class="flex-1 mr-4" formGroupName="{{ i }}">
                  <mat-form-field
                    appearance="outline"
                    class="w-full white-backgrounded-field"
                  >
                    <mat-label>Department Type</mat-label>
                    <mat-select
                      formControlName="departmentTypeId"
                      name="departmentTypeId"
                    >
                      @for (department of departmentTypes$ | async; track
                      department) {
                      <mat-option [value]="department.value">{{
                        department.key
                      }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
        }
      </div>
    </div>
  </div>
</div>
