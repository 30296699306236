import { Component } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../services/dialog.service';
import { finalize, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { DataSharingService } from '../services/data-sharing.service';
import { IncentiveCreateComponent } from './incentive-create/incentive-create.component';
import { GenericService } from '../services/generic.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-finance',
  standalone: true,
  imports: [
    HeaderComponent,
    ThemeContainerComponent,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './finance.component.html',
  styleUrl: './finance.component.scss',
})
export class FinanceComponent {
  dialogSubscription!: Subscription;
  incentiveProgramData: any;
  isLoading: boolean = false;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private dialogService: DialogService,
    private router: Router,
    private notificationService: NotificationService,
    private dataService: DataSharingService,
    private genericService: GenericService
  ) {
    this.matIconRegistry.addSvgIcon(
      'dollar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/dollar.svg'
      )
    );
  }

  ngOnInit() {
    this.getAllIncentivePrograms();
  }

  getAllIncentivePrograms() {
    this.isLoading = true;
    this.genericService
      .getData(this.genericService.endPoints.finance.allIncentivePrograms)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.incentiveProgramData = res?.incentivePrograms;
      });
  }

  openDialog() {
    const dialogRef = this.dialogService.open({
      component: IncentiveCreateComponent,
      title: 'Create Incentive Program',
      actionLabel: 'Create',
      themeModal: true,
      hideCloseIcon: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe({
      complete: () => {},
      error: (err: any) => {
        this.notificationService.error(err.message);
      },
      next: (res: any) => {
        dialogRef.close();
        this.dataService.isFormValid.next(true);
        let url = this.genericService.endPoints.finance.createIncentiveProgram;
        this.genericService.postData(url, res).subscribe((res) => {
          this.notificationService.success('Incentive program has been created');
          this.getAllIncentivePrograms();
        });
      },
    });
  }

  viewDetail(id: number) {
    this.router.navigate(['/finance/detail', id]);
  }
}
