import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component} from '@angular/core';
import { MatIconModule } from '@angular/material/icon'; 
import { DataSharingService } from '../../services/data-sharing.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar'; 
import { DialogService } from '../../services/dialog.service'; 
import {  MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ThemeUploadComponent } from "../../components/theme-upload/theme-upload.component";

export interface Attachment {
  id: number | null;
  name: string;
  file?: File;
  deleted: boolean;
}

@Component({
  selector: 'app-ticket-attachment',
  standalone: true,
  imports: [
    MatIconModule,
    HttpClientModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    ReactiveFormsModule,
    ThemeUploadComponent
],
  providers: [HttpClient],
  templateUrl: './ticket-attachment.component.html',
  styleUrls: ['./ticket-attachment.component.scss'],
})
export class TicketAttachmentComponent {
  ticketAttachmentForm: FormGroup;  
  public isButtonDisabled: boolean = true;

  constructor(
    private readonly dataService: DataSharingService,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder, 
  ) {
    this.ticketAttachmentForm  = this.fb.group({ 
      attachments: this.fb.array([], Validators.required)
    }); 
    this.ticketAttachmentForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.ticketAttachmentForm.valid);
    });
    this.dialogService.save$ 
    .subscribe(() => {
      if (this.ticketAttachmentForm.valid) { 
        this.dialogService.onData(this.ticketAttachmentForm);
      }  
    });
  } 

  ngOnInit() {
    this.isButtonDisabled = true;
  }
}
