import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  private readonly baseUrl = environment.baseUrl;

  endPoints = {
    finance: {
      //Finance Metadata
      getAllFiscalPeriods: this.baseUrl + '/metadata/finance/fiscal-periods',
      getAllTransactionTypes:
        this.baseUrl + '/metadata/finance/transaction-types',
      getAllRebateTypes: this.baseUrl + '/metadata/finance/rebate-types',
      //Finance Management
      createIncentiveProgram:
        this.baseUrl + '/management/finance/incentive-programs',
      allIncentivePrograms:
        this.baseUrl + '/management/finance/incentive-programs',
      incentiveProgramById:
        this.baseUrl +
        '/management/finance/incentive-programs/:rebateProgramId',
      incentiveProgramsTransaction:
        this.baseUrl +
        '/management/finance/incentive-programs/:rebateProgramId/transactions?page=:pageNumber&size=:pageSize',
    },
  };

  constructor(private http: HttpClient) {}

  postData<T>(endPoint: any, data: any): Observable<any> {
    return this.http.post<any>(endPoint, data);
  }

  putData<T>(endPoint: any, data: any): Observable<any> {
    return this.http.put<any>(endPoint, data);
  }

  deleteData<T>(endPoint: any): Observable<any> {
    return this.http.delete<any>(endPoint);
  }

  getData<T>(endPoint: any): Observable<any> {
    return this.http.get<any>(endPoint);
  }
}
