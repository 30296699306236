<div class="wrapper flex items-center">
  <div class="wrapper-inner flex w-full items-center bg-white">
    @if (showBackIcon$ | async) {
    <mat-icon
      (click)="goBack()"
      svgIcon="arrow-left"
      class="cursor-pointer mr-4"
    ></mat-icon>
    }
    <div class="title items-center flex">
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="ml-auto flex items-center">
      <ng-content></ng-content>
    </div>
    <div class="flex flex-col items-center ml-10">
      <!-- <img
        src="assets/profile.png"
        class=" cursor-pointer"
        mat-icon-button
        [matMenuTriggerFor]="menu"
      /> -->
      <span [matMenuTriggerFor]="menu" class="cursor-pointer w-11 h-11 bg-gray-200 rounded-full text-center flex justify-center items-center">
        {{userInitials}}
      </span>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
      <!-- <p>{{userDetails.name || 'John Smith'}}</p> -->
    </div>
  </div>
</div>
