<div class="p-6">
  <h1 class="text-xl font-medium mb-6 flex justify-between">
    References <button class="btn-remarks" mat-button (click)="addRemarks()">Add Remarks</button>
  </h1>
  @for(reference of references; track reference; let i = $index) {
  <h5 class="mb-6" [ngClass]="i >= 1 ? 'my-2' : 'sm:mt-10 mt-4'">
    Reference {{ i + 1 }}
  </h5>
  <div class="grid sm:grid-cols-4 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>STMA Membership Number</mat-label>
      <input matInput [value]="reference?.membershipNumber" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Company Name</mat-label>
      <input matInput [value]="reference?.companyName" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Store Name</mat-label>
      <input matInput [value]="reference?.dba" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Authorised Representative Name</mat-label>
      <input matInput [value]="reference?.authorizedRepName" />
    </mat-form-field>
  </div>
  }
</div>
