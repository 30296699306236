<div class="p-6">
  <div class="flex justify-between gap-2 mb-4">
    <h1 class="text-xl font-medium shrink-0">Accounting Review</h1>
    <div class="flex gap-3 items-center">
      <button class="btn-remarks" mat-button (click)="addRemarks()">Add Remarks</button>
      <button mat-stroked-button color="primary" class="theme-stroke-btn">
        Deny
      </button>
      <button class="theme-btn" color="primary" mat-flat-button>
        Accounting Reviewed
      </button>
    </div>
  </div>
  <div class="grid sm:grid-cols-3 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Accounting Name</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Date</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Time</mat-label>
      <input matInput />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Comments</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>E-Signature</mat-label>
      <input matInput />
    </mat-form-field>
  </div>
</div>
