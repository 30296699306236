import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  public isFormValid = new BehaviorSubject<boolean>(false);
  public isAPICalling = new BehaviorSubject<boolean>(false);
  public areRowsSelected = new BehaviorSubject<boolean>(false);

  public loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpClient) {}

  // getCall(request: string) {
  //   this.loadingSubject.next(true);
  //   return this.http
  //     .get(`${environment.baseUrl}${request}`)
  //     .pipe(finalize(() => this.loadingSubject.next(false)));
  // }

  // postCall(data: any) {
  //   this.loadingSubject.next(true);
  //   return this.http
  //     .post('https://api.example.com/data', data)
  //     .pipe(finalize(() => this.loadingSubject.next(false)));
  // }
}
