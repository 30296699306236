<div class="paginator flex justify-end items-center">
    <mat-icon [class.disabled]="pageNumber === 0" svgIcon="first" class="cursor-pointer first" (click)="onFirstPage()" />
    <mat-icon [class.disabled]="pageNumber === 0" svgIcon="previous" class="cursor-pointer previous" (click)="onPreviousPage()" />
    <div class="pagination-boxes flex justify-center items-center">
      <span class="pagenum-container flex">
          @for (page of totalPagesArray; track page; let i = $index) {
              <span (click)="onPage(page - 1)" class="pagination-box" [class.selected]="page === pageNumber + 1">{{page}}</span>
          }
      </span>
    </div>
    <mat-icon [class.disabled]="pageNumber + 1 === totalPages" svgIcon="next" class="cursor-pointer next" (click)="onNextPage()" />
    <mat-icon [class.disabled]="pageNumber + 1 === totalPages" svgIcon="last" class="cursor-pointer last" (click)="onLastPage()" />
</div>