<form [formGroup]="rebateForm">
  <div class="pt-4">
    <mat-label class="radio-label">
      Is it Volume Based? <span>*</span>
    </mat-label>
  </div>
  <div class="radio-group mb-3">
    <mat-radio-group 
      formControlName="isCountBased"
    >
      <mat-radio-button [value]="true" class="mr-1" color="primary">Yes</mat-radio-button>
      <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Description</mat-label>
      <textarea matInput  formControlName="description"></textarea>
    </mat-form-field>
  </div> 
</form>
