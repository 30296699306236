import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, DialogData } from '../components/dialog/dialog.component';
import { Subject } from 'rxjs';
import { SliderComponent } from '../components/slider/slider.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private save = new Subject();
  public save$ = this.save.asObservable();

  private data = new Subject();
  public data$ = this.data.asObservable();

  constructor(public dialog: MatDialog) { }

  open(data: DialogData) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '640px',
      disableClose: true,
      data
    });
    return dialogRef;
  }

  openSlider(data: any) {
    const dialogRef = this.dialog.open(SliderComponent, {
      minWidth: '640px',
      data
    });
    return dialogRef;
  }

  onSave() { 
    this.save.next(null);
  }

  onData(data: any) { 
    this.data.next(data);
  }
}
