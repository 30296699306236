<form [formGroup]="vendorForm" class="">
  <h4 class="mb-6 font-medium text-base text-gray-950">Information</h4>
  <div class="flex gap-3 mb-2">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Vendor Name</mat-label>
      <input matInput formControlName="name" />
      @if(vendorForm.controls.name.hasError('required')){
      <mat-error> Name is required. </mat-error>
      }
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Business Type</mat-label>
      <mat-select
        formControlName="vendorBusinessTypeId"
        name="vendorBusinessTypeId"
      >
        @for (type of businessTypes$ | async; track type) {
        <mat-option [value]="type.value">{{ type.key }}</mat-option>
        }
      </mat-select>
      @if(vendorForm.controls.vendorBusinessTypeId.hasError('required')){
      <mat-error> Business Type is required. </mat-error>
      }
    </mat-form-field>
  </div>
  <div class="flex gap-3 mb-2">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Address 1</mat-label>
      <input matInput formControlName="street1" />
      @if(vendorForm.controls.address.controls.street1.hasError('required')){
      <mat-error> Address 1 is required. </mat-error>
      }
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Address 2</mat-label>
      <input matInput formControlName="street2" />
    </mat-form-field>
  </div>
  <div class="flex gap-3 mb-2">
    <mat-form-field appearance="outline" class="white-backgrounded-field">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" />
      @if(vendorForm.controls.address.controls.city.hasError('required')){
      <mat-error> City is required. </mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" class="white-backgrounded-field">
      <mat-label>State</mat-label>
      <input matInput formControlName="state" />
      @if(vendorForm.controls.address.controls.state.hasError('required')){
      <mat-error> State is required. </mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" class="white-backgrounded-field">
      <mat-label>Zip Code</mat-label>
      <input matInput formControlName="zipcode" />
      @if(vendorForm.controls.address.controls.zipcode.hasError('required')){
      <mat-error> Zip Code is required. </mat-error>
      }
    </mat-form-field>
  </div>

  <h4 class="mb-5 font-medium text-base text-gray-950">
    Representative Contact
  </h4>
  <div formGroupName="contacts">
    <div class="flex gap-3 mb-2">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
        @if(vendorForm.controls.contacts.controls.firstName.hasError('required')){
        <mat-error> First Name is required. </mat-error>
        }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
        @if(vendorForm.controls.contacts.controls.lastName.hasError('required')){
        <mat-error> Last Name is required. </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="mb-2">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Job Title</mat-label>
        <input matInput formControlName="jobTitle" />
        @if(vendorForm.controls.contacts.controls.jobTitle.hasError('required')){
        <mat-error> Job title is required. </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="flex gap-3 mb-2">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Department Name</mat-label>
        <input matInput formControlName="departmentName" />
        @if(vendorForm.controls.contacts.controls.departmentName.hasError('required')){
        <mat-error> Department name is required. </mat-error>
        }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Department Type</mat-label>
        <mat-select formControlName="departmentTypeId" name="departmentTypeId">
          @for (department of departmentTypes$ | async; track department) {
          <mat-option [value]="department.value">{{
            department.key
          }}</mat-option>
          }
        </mat-select>
        @if(vendorForm.controls.contacts.controls.departmentTypeId.hasError('required')){
        <mat-error> Department type is required. </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="flex gap-3">
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" />
        @if(vendorForm.controls.contacts.controls.email.hasError('required')){
        <mat-error> Email is required. </mat-error>
        } @if(vendorForm.controls.contacts.controls.email.hasError('email')){
        <mat-error> Invalid email </mat-error>
        }
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Phone Number</mat-label>
        <input
          matInput
          (keydown)="numericValidator($event)"
          formControlName="phone"
          minlength="10"
          mask="(000) 000-0000"
        />
        @if(vendorForm.controls.contacts.controls.phone.hasError('required')){
        <mat-error> Phone number is required. </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
</form>
<div class="info-box">
  <mat-icon svgIcon="info"></mat-icon>
  You can also add multiple vendor representatives and vendor contacts on vendor details screen
</div>
