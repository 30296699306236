import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from '../../environments/environment';

export interface Promotion {
  id: number;
  name: string;
  title: string;
  description: string;
  createDate: number;
  startDate?: string;
  endDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(
    private http: HttpClient,
    private dataService: DataSharingService
  ) {}

  createAnnouncement(data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .post<any>(`${environment.baseUrl}/management/announcements`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  updateAnnouncement(id: number, data: any) {
    this.dataService.loadingSubject.next(true);
    return this.http
      .put<any>(`${environment.baseUrl}/management/announcements/${id}`, data)
      .pipe(finalize(() => this.dataService.loadingSubject.next(false)));
  }

  getAnnouncements() {
    return this.http.get<Promotion[]>(
      `${environment.baseUrl}/management/announcements`
    );
  }

  deleteAnnouncement(id: number) {
    return this.http.delete<Promotion>(
      `${environment.baseUrl}/management/announcements/${id}`
    );
  }
}
