<form>
  <mat-form-field
    appearance="outline"
    class="mb-6 w-full white-backgrounded-field"
  >
    <mat-label>Subject</mat-label>
    <input matInput placeholder="Type subject of email" />
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="mb-6 w-full white-backgrounded-field"
  >
    <mat-label>Recipients</mat-label>
    <input matInput placeholder="Type email address of the recipients" />
  </mat-form-field>
</form>
