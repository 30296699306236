@if (!fuelProfileForm){
<div class="flex flex-col items-center theme-card-body gap-8 py-6">
  <img src="assets/chat.svg" />
  <h4 class="font-bold text-2xl">Manage Your Fuel Profile</h4>
  <p class="text-light-grey">Fuel profile does not exist. Create a profile</p>
  <button
    mat-flat-button
    color="primary"
    class="theme-btn"
    (click)="createNewProfile()"
  >
    <mat-icon svgIcon="add"></mat-icon>Create
  </button>
</div>
} @if (fuelProfileForm){
<div class="theme-card tab-card">
  <div class="flex items-center justify-between flex-wrap gap-2">
    <h2 class="text-lg font-medium">Add New Fuel Quest</h2>
    @if (newForm){
      <div class="flex md:gap-3 items-center gap-2 flex-wrap justify-end">
        <button mat-flat-button (click)="cancelCreate()">
          <span class="text-mat-primary">Cancel</span>
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="onSubmit()"
          class="theme-stroke-btn"
        >
          Create
        </button>
      </div>
    }
    @if(!newForm && isEditing) {
    <div class="flex md:gap-3 items-center gap-2 flex-wrap justify-end">
      <button mat-flat-button (click)="cancelEditing()">
        <span class="text-mat-primary">Cancel</span>
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="theme-stroke-btn"
        (click)="updateProfile()"
      >
        Update
      </button>
    </div>
    }
    @if (!newForm && !isEditing){
      <div class="flex md:gap-3 items-center gap-2 flex-wrap justify-end">
        <button
          mat-button
          color="error"
          class="cursor-pointer"
          (click)="deleteProfile()"
        >
          <span class="flex text-[#F62005] items-center font-medium"
            >Delete
          </span>
        </button>
        <button mat-stroked-button color="primary" class="theme-stroke-btn" (click)="questFuelFormEnable()">
          Edit
        </button>
      </div>
    }
  </div>
  <form [formGroup]="questFuelForm" (ngSubmit)="onSubmit()">
    <div class="pt-4">
      <mat-label class="radio-label">
        Quest Fuel Customer <span>*</span>
      </mat-label>
    </div>
    <div class="radio-group">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="isQuestFuelCustomer"
      >
        <mat-radio-button value="1" color="primary">Yes</mat-radio-button>
        <mat-radio-button value="2" color="primary">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 form-grid pt-4">
      @if (questFuelForm.get('isQuestFuelCustomer')?.value === '1'){
      <div class="flex-1">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Quest Fuel Customer Number</mat-label>
          <input matInput formControlName="questFuelAccountNumber" />
        </mat-form-field>
      </div>
      }

      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Brand</mat-label>
        <mat-select formControlName="brandTypeId">
          @for (brand of brandTypes; track brand) {
          <mat-option [value]="brand.value">
            {{ brand.key }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Pricing Tier</mat-label>
        <mat-select formControlName="pricingTierTypeId">
          @for (tier of pricingTier; track tier) {
          <mat-option [value]="tier.value">
            {{ tier.key }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="w-full white-backgrounded-field"
      >
        <mat-label>Location</mat-label>
        <mat-select formControlName="geographyTypeId">
          @for (loc of locations; track loc) {
          <mat-option [value]="loc.value">
            {{ loc.key }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
}
