import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { TicketElement, TicketsService } from './tickets.service';
import { HeaderComponent } from '../components/header/header.component';
import { NotificationService } from '../services/notification.service';
import { TableColumns } from '../components/table/table.component';
import { DialogService } from '../services/dialog.service';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { finalize, Subscription, switchMap } from 'rxjs';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { ThemeTableComponent } from '../components/theme-table/theme-table.component';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';

@Component({
  selector: 'app-tickets',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    RouterModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    ThemeTableComponent,
    ThemeContainerComponent,
    CustomColumnDirective,
  ],
  providers: [HttpClient],
  templateUrl: './tickets.component.html',
  styleUrl: './tickets.component.scss',
})
export class TicketsComponent {
  selection = new SelectionModel<TicketElement>(true, []);
  displayedColumns: TableColumns[] = [
    { key: 'id', label: 'Ticket No' },
    {
      key: 'supportType',
      label: 'Support Type',
      displayValue: (data) => data.supportType.key,
    },
    {
      key: 'member',
      label: 'Members',
      displayValue: (data) => data.member.key,
    },
    { key: 'vendor', label: 'Vendor', displayValue: (data) => data.vendor.key },
    { key: 'issueOrConcern', label: 'Issue' },
    { key: 'statusType', label: 'Status' }
  ];
  dataSource = new MatTableDataSource<TicketElement>([]);
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;
  dialogSubscription!: Subscription;
  metrics$ = this.ticketsService.getMetrics();

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private ticketsService: TicketsService,
    private router: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'total',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/total.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'progress',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/progress.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'completed',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/completed.svg'
      )
    );
  }

  openDialog() {
    const dialogRef = this.dialogService.open({
      component: TicketDialogComponent,
      title: 'Create New Ticket',
      actionLabel: 'Create',
      hideCloseIcon: true,
      themeModal: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$
      .pipe(
        switchMap((data) =>
          this.ticketsService.createTicket(data as TicketElement)
        )
      )
      .subscribe((data) => {
        dialogRef.close();
        this.pageChange(0);
        this.notificationService.success(
          'Your ticket has been created successfully !'
        );
      });
  }

  viewDetail(id: number) {
    this.router.navigate(['/tickets/detail', id]);
  }

  pageChange(page: number,) {
    this.isLoading = true;
    this.ticketsService
      .getTickets(page)
      .pipe(finalize(() => {this.isLoading = false;}))
      .subscribe((response) => {
        const { tickets, totalPages, pageNumber, totalRecords } =
          response as any;
        this.isLoading = false;
        this.totalPages = totalPages;
        this.totalRecords = totalRecords;
        this.pageNumber = pageNumber;
        this.dataSource = tickets;
      });
  }

  formatStatusKey(key: string): string {
    return key
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  applyFilter(event: any) {
    if (event.trim() === '') {
      this.pageChange(0);
    } else {
      this.isLoading = true;
      this.ticketsService
        .searchTicket(event, 0, 10)
        .subscribe((response: any) => {
          const { tickets, totalPages, pageNumber, totalRecords } =
            response as any;
          this.isLoading = false;
          this.totalPages = totalPages;
          this.totalRecords = totalRecords;
          this.pageNumber = pageNumber;
          this.dataSource = tickets;
        });
    }
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }
}

