<form [formGroup]="promotionForm" class="">
  <app-theme-upload [form]="promotionForm" formArrayName="attachments" [allowedExtensions]="['.jpg', '.png', '.jpeg']" [slimUpload]="true"/>
  <mat-form-field
    appearance="outline"
    class="w-full white-backgrounded-field mt-6"
  >
    <mat-label>Promotion Name</mat-label>
    <input matInput formControlName="name" placeholder="Enter announcement title" />
  </mat-form-field>
  <div class="flex space-x-5">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Start Date</mat-label>
      <input
        matInput
        [matDatepicker]="startpicker"
        formControlName="startDate"
        (dateChange)="onStartDateChange($event)"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="startpicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startpicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endpicker" formControlName="endDate" [min]="minEndDate"/>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="endpicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endpicker></mat-datepicker>
    </mat-form-field>
  </div>

</form>
