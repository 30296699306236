<div class="p-6">
  <h1 class="text-xl font-medium mb-6 flex justify-between">
    Company Information
    <button class="btn-remarks" mat-button (click)="addRemarks()">Add Remarks</button>
  </h1>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Name</mat-label>
      <input matInput [value]="memberShipData?.companyName" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Store Name (DBA)</mat-label>
      <input matInput [value]="memberShipData?.dba" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Email</mat-label>
      <input matInput [value]="memberShipData?.email" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Phone</mat-label>
      <input matInput [value]="memberShipData?.phone" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Address Line 1</mat-label>
      <input matInput [value]="memberShipData?.address?.street1" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Address Line 2</mat-label>
      <input matInput [value]="memberShipData?.address?.street2" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-4 gap-x-4 grid-cols-2">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>City</mat-label>
      <input matInput [value]="memberShipData?.address?.city" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>State</mat-label>
      <input matInput [value]="memberShipData?.address?.state" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>County</mat-label>
      <input matInput [value]="memberShipData?.address?.county" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Zip Code</mat-label>
      <input matInput [value]="memberShipData?.address?.zipcode" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-2">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Country</mat-label>
      <input matInput [value]="memberShipData?.address?.country" />
    </mat-form-field>
  </div>

  <mat-checkbox
    [checked]="memberShipData?.hasMailingAddress"
    color="primary"
    class="mx-2 mb-6"
    >Mail address same as store address?</mat-checkbox
  >
  @if(memberShipData?.hasMailingAddress) {
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Address Line 1</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.street1" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Address Line 2</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.street2" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-4 gap-x-4 grid-cols-2">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>City</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.city" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>State</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.state" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>County</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.county" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Zip Code</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.zipcode" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Country</mat-label>
      <input matInput [value]="memberShipData?.mailingAddress?.country" />
    </mat-form-field>
  </div>
  }
</div>
