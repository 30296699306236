<form [formGroup]="questFuelForm">
  <label>Locations</label>
  <mat-form-field appearance="outline" class="w-full mt-4">
    <mat-label>Add Location</mat-label>
    <mat-select formControlName="geographyTypeId" multiple>
      @for (location of locations$ | async; track location) {
      <mat-option [value]="location.value">{{ location.key }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <label>Add Prices (Formula)</label>
  <div class="flex space-x-5 mt-4">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>87-Regular</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="formulaRegular"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>93-Super</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="formularSuper"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>20-Diesel</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="formulaDiesel"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>B20-Bio Diesel</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="formulaBioDiesel"
      />
    </mat-form-field>
  </div>
  <label>Add Prices (Regular)</label>
  <div class="flex space-x-5 mt-4">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>87-Regular</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="regularRegular"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>93-Super</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="regularrSuper"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>20-Diesel</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="regularDiesel"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>B20-Bio Diesel</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="regularBioDiesel"
      />
    </mat-form-field>
  </div>
</form>
