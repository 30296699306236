import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DocumentsService } from '../documents.service';
import { UploadComponent } from '../../components/upload/upload.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSharingService } from '../../services/data-sharing.service';
import { ThemeUploadComponent } from '../../components/theme-upload/theme-upload.component';

@Component({
  selector: 'app-document-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UploadComponent,
    ThemeUploadComponent
  ],
  providers: [],
  templateUrl: './document-dialog.component.html',
  styleUrl: './document-dialog.component.scss',
})
export class DocumentDialogComponent {
  // @Input() data: any;
  documentForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    attachments: this.fb.array([]),
  });
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private documentsService: DocumentsService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private dataService: DataSharingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.data) {
      this.documentForm.patchValue(this.data.data);
    }
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogService.onData(this.createData());
      });

    this.documentForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.documentForm.valid);
    });
  }

  createData() {
    const { name, description } = this.documentForm.value;
    const body = {
      name,
      description,
    };
    const formData = new FormData();
    formData.append(
      'details',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );
    const attachments = this.documentForm.get('attachments') as FormArray;
    attachments.controls.forEach((control) => {
      const file: File = control?.get('file')?.value;
      formData.append('attachment', file, file.name);
    });
    return formData;
  }
}
