<app-header>
  <p title>Members</p>
</app-header>
<div class="main-content members p-6">
  <div class="flex justify-center space-x-5 mb-6">
    @if (metrics$ | async; as metrics) {
    <div class="metrics-counter">
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Total Members</p>
        <p class="text-xl mt-2 font-bold">
          {{ metrics.ACTIVE + metrics.INACTIVE || "0" }}
        </p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Active Members</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.ACTIVE || "0" }}</p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Inactive Members</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.INACTIVE || "0" }}</p>
      </div>
    </div>
    }
  </div>
  <app-theme-container
    (filter)="applyFilter($event)"
    title="Members"
    [totalRecords]="totalRecords"
    searchPlaceholder="Search members"
  >
    <app-theme-table
      #table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isLoading]="isLoading"
      (rowClick)="viewDetail($event.id)"
      (pageChange)="pageChange($event)"
      [totalPages]="totalPages"
      [totalRecords]="totalRecords"
      [pageNumber]="pageNumber" 
      searchPlaceholder="Search members"
    >
      <ng-container *appCustomColumn="'isMembershipActive'; let item">
        <div class="status-badge flex items-center">
          <span
                [ngClass]="{ 
            'grey': !item.isMembershipActive,
            'green': item.isMembershipActive,
          }"
          >
            {{ item.isMembershipActive ? "Active" : "Inactive" }}
          </span>
        </div>
      </ng-container>
    </app-theme-table>
  </app-theme-container>
</div>
