import { DatePipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { OnInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DocumentElement, DocumentsService } from './documents.service';
import { HeaderComponent } from '../components/header/header.component';
import { NotificationService } from '../services/notification.service';
import {
  TableColumns,
  TableComponent,
} from '../components/table/table.component';
import { DialogService } from '../services/dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { Subscription, filter, switchMap } from 'rxjs';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { AlertService } from '../services/alert.service';
import { environment } from '../../environments/environment';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../components/theme-table/theme-table.component';
import { DialogBoxComponent } from '../components/dialog-box/dialog-box.component';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [
    NgClass,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    ThemeTableComponent,
    ThemeContainerComponent,
    CustomColumnDirective,
  ],
  providers: [HttpClient, DatePipe],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
})
export class DocumentsComponent {
  displayedColumns: TableColumns[] = [
    { key: 'name', label: ' Name' },
    { key: 'description', label: 'Description' },
    {
      key: 'createdDate',
      label: 'Uploaded on',
      displayValue: (data) => this.formatDate(data.audit.createdDate),
    },
    {
      key: 'createdBy',
      label: 'Uploaded by',
      displayValue: (data) => data.audit.updatedBy,
    },
    { key: 'actions', label: 'Actions' },
  ];
  dataSource = new MatTableDataSource<DocumentElement>([]);
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;
  dialogSubscription!: Subscription;

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private documentsService: DocumentsService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private alertService: AlertService,
    private datePipe: DatePipe
  ) {
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pencil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/pencil.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/download.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/delete.svg'
      )
    );
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'medium', 'UTC') || '';
  }

  openDialog(item?: any) {
    const dialogRef = this.dialogService.open({
      component: DocumentDialogComponent,
      title: 'Create Document',
      actionLabel: 'Upload',
      data: item ? item : null,
      themeModal: true,
      hideCloseIcon: true,
    });

    if (item) {
      this.dialogSubscription?.unsubscribe();
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.documentsService.updateDocument(
              item.id,
              data as DocumentElement
            )
          )
        )
        .subscribe((data) => {
          dialogRef.close();
          this.pageChange(0);
          this.notificationService.success('Document updated successfully');
        });
    } else {
      this.dialogSubscription?.unsubscribe();
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.documentsService.createDocument(data as DocumentElement)
          )
        )
        .subscribe({
          complete: () => {},
          error: (err: any) => {
            this.notificationService.error(err.message);
          },
          next: (data: any) => {
            dialogRef.close();
            this.pageChange(0);
            this.notificationService.success('New document uploaded successfully');
          },
        });
    }
  }

  pageChange(page: number) {
    this.documentsService.getDocuments(page).subscribe((response) => {
      const { documents, totalPages, pageNumber, totalRecords } =
        response as any;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber;
      this.dataSource = documents;
    });
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this document?',
        description:
          'This document will no longer be able to view again and will be removed from here permanently',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.documentsService.deleteDocument(id).subscribe((res) => {
          this.notificationService.error('Document deleted permanently');
          this.pageChange(0);
        });
      }
    });
  }

  download(id: number) {
    this.documentsService.downloadDocument(id).subscribe((res:any)=>{ 
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
      }) 
  }

  applyFilter(event: any) {
    this.isLoading = true;
    this.documentsService.search(event).subscribe((response: any) => {
      const { documents, totalPages, pageNumber, totalRecords } =
        response as any;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber;
      this.dataSource = documents;
    });
  }
}
