import { Component, DestroyRef, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DataSharingService } from '../../services/data-sharing.service';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-rebate-create',
  standalone: true,
  imports: [
    MatLabel,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  templateUrl: './rebate-create.component.html',
  styleUrl: './rebate-create.component.scss',
})
export class RebateCreateComponent {
  rebateForm!: FormGroup;
  frequencyTypes: any;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private dataService: DataSharingService,
    private dialogService: DialogService
  ) {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogService.onData(this.rebateForm.value);
      });
  }

  ngOnInit(): void {
    this.rebateForm = this.fb.group({
      isCountBased: [null, Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.rebateForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.rebateForm.valid);
    });
  }
}
