import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { Metrics } from '../tickets/tickets.service';

export interface MemberElement {
  id: number;
  businessName: string;
  membershipNo: string;
  businessType: string;
  memberType: string;
  phoneNumber: string;
  businessLicense: string;
}

const numberOfItems = 50;

const dataArray: MemberElement[] = Array.from(
  { length: numberOfItems },
  (_, index) => ({
    id: index + 1,
    businessName: 'Seven Eleven',
    membershipNo: '1234',
    businessType: 'Con. Fuel',
    memberType: 'Pepsi',
    phoneNumber: '9234567',
    businessLicense: 'Food',
  })
);

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  private readonly data = new BehaviorSubject<MemberElement[]>(dataArray);

  data$ = this.data.asObservable();

  constructor(private http: HttpClient) {}

  getMembersById(id: number) {
    return this.http.get<MemberElement[]>(
      `${environment.baseUrl}/management/members/${id}`
    );
  }

  getMembers(page: number) {
    return this.http.get<MemberElement[]>(
      `${environment.baseUrl}/management/members?page=${page}&size=10`
    );
  }

  getMetrics() {
    return this.http.get<Metrics>(
      `${environment.baseUrl}/management/members/metrics`
    );
  }

  getMemberDetailsById(id: number): Observable<MemberElement | null> {
    return this.http.get<MemberElement>(
      `${environment.baseUrl}/management/members/${id}`
    );
  }

  deactivateMember(id: number) {
    return this.http.patch<MemberElement>(
      `${environment.baseUrl}/management/members/${id}/deactivate`,
      null
    );
  }

  activateMember(id: number) {
    return this.http.patch<MemberElement>(
      `${environment.baseUrl}/management/members/${id}/activate`,
      null
    );
  }

  searchMember(query: any, page: number, size: number) {
    return this.http.get<MemberElement>(
      `${environment.baseUrl}/management/members/search?query=${query}&page=${page}&size=${size}`
    );
  }

  getFuelProfile(id: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/management/members/${id}/fuel-profile`
    );
  } 

  deleteFuelProfile(id: number) {
    return this.http.delete(
      `${environment.baseUrl}/management/members/${id}/fuel-profile`
    );
  } 
  
  createFuelProfile(id: number, payload: any) {
    return this.http.post<any>(
      `${environment.baseUrl}/management/members/${id}/fuel-profile`,
      payload
    );
  }

  updateFuelProfile(id: number, payload: any) {
    return this.http.put<MemberElement>(
      `${environment.baseUrl}/management/members/${id}/fuel-profile`,
      payload
    );
  }

}
