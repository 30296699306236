<div class="flex w-full items-center justify-center bg-white z-50 relative">
  @if (isLoading) {
  <div class="absolute">
    <mat-spinner></mat-spinner>
  </div>
  }
</div>
<div class="relative -z-0">
  <div class="table-container" [class.container-metrics]="noMetrics">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      matSortDirection="desc"
      class="w-full whitespace-nowrap"
    >
      @for (column of displayedColumns; track column) {
      <ng-container [matColumnDef]="column.key">
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
        <td mat-cell matSort matSortDisableClear *matCellDef="let item">
          @if(getCustomColTempRef(column.key); as customTemplateRef) {
          <ng-container
            *ngTemplateOutlet="customTemplateRef; context: { $implicit: item }"
          ></ng-container>
          } @else if (column.key === 'id') {
          <button class="w-full text-left" (click)="onRowClick(item)">
            {{ item.id }}
          </button>
          } @else if (column.isDate) {
          <p class="truncate">{{ item[column.key] | date : "MMMM d, y" }}</p>
          } @else {
          <p class="truncate">
            {{
              column.displayValue ? column.displayValue(item) : item[column.key]
            }}
          </p>
          }
        </td>
      </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true"></tr>
      <tr
        [class.cursor-pointer]="rowClick.observers.length > 0"
        mat-row
        *matRowDef="let row; columns: columnKeys" 
        onKeyDown (click)="onRowClick(row)"
      ></tr>
    </table>
  </div>
  <mat-paginator
    class="py-3"
    showFirstLastButtons
    [length]="totalRecords"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
