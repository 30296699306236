<div class="p-6">
  <h1 class="text-xl font-medium mb-6 flex justify-between">
    Name of the Store Supervisor
    <button class="btn-remarks" mat-button (click)="addRemarks()">
      Add Remarks
    </button>
  </h1>
  <div class="grid sm:grid-cols-3 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>First Name</mat-label>
      <input matInput [value]="managers?.firstName" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Middle Name</mat-label>
      <input matInput [value]="managers?.middleName" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Last Name</mat-label>
      <input matInput [value]="managers?.lastName" />
    </mat-form-field>
  </div>
  <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Mobile/ Phone Number</mat-label>
      <input matInput [value]="managers?.phone" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full mb-2">
      <mat-label>Email Address</mat-label>
      <input matInput [value]="managers?.email" />
    </mat-form-field>
  </div>
  <div class="flex gap-2 items-center justify-between mb-4">
    <h1 class="text-xl font-medium shrink-0">Additional Info</h1>
  </div>
  <div class="grid md:grid-cols-3 gap-4 grid-cols-1">
    @for(document of managers.documents; track document) {
    <app-document-card [documentData]="document"></app-document-card>
    }
  </div>
</div>
