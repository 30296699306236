import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'; 
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { DataSharingService } from '../../services/data-sharing.service';
import { ThemeUploadComponent } from '../../components/theme-upload/theme-upload.component';

@Component({
  selector: 'app-promotion-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeUploadComponent
  ],
  providers: [provideNativeDateAdapter(), DatePipe],
  templateUrl: './promotion-dialog.component.html',
  styleUrl: './promotion-dialog.component.scss',
})
export class PromotionDialogComponent {
  public allowedExtensions = ['.pdf'];
  minEndDate: Date | null = null;
  promotions = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  promotionForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['test', [Validators.required]],
    startDate: [' ', [Validators.required]],
    endDate: [' ', [Validators.required]],
    attachments: this.fb.array([]),
  });
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataService: DataSharingService
  ) {}

  ngOnInit() {
    if (this.data.data) {
      this.patchFormValues();
    }
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => { 
        this.dialogService.onData(this.createData());
      });

    this.promotionForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.promotionForm.valid);
    });
  }

  onStartDateChange(event: any) {
    this.minEndDate = event.value;
  }

  createData() { 
    const { name, description, startDate, endDate } = this.promotionForm.value;
    const locale = 'en-US';
    const body = {
      name,
      description,
      startDate: startDate
        ? `${this.datePipe.transform(startDate, 'yyyy')}-${formatDate(
            startDate,
            'MM',
            locale
          )}-${this.datePipe.transform(startDate, 'dd')}`
        : '',
      endDate: startDate
        ? `${this.datePipe.transform(
            endDate,
            'yyyy'
          )}-${this.datePipe.transform(
            endDate,
            'MM'
          )}-${this.datePipe.transform(endDate, 'dd')}`
        : '',
    };
    const formData = new FormData();
    formData.append(
      'details',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );
    const attachments = this.promotionForm.get('attachments') as FormArray;
    attachments.controls.forEach((control) => {
      const file: File = control?.get('file')?.value;
      formData.append('attachment', file, file.name);
    });
    return formData;
  }

  patchFormValues() { 
    const { name, description, startDate, endDate } = this.data.data;
    const formValues = {
      name,
      description,
      startDate,
      endDate,
    };
    this.promotionForm.patchValue(formValues as any);
  }
}
