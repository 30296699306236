<app-header>
  <p title>Documents</p>
  <!-- <button (click)="openDialog()" mat-raised-button color="primary">
        <mat-icon svgIcon="cloud-upload"></mat-icon>
      Upload Documents
    </button> -->
</app-header>
<div class="main-content p-6">
  <app-theme-container
    (filter)="applyFilter($event)"
    title="Documents"
    searchPlaceholder="Search documents by name"
    [totalRecords]="totalRecords"
    [cta]="'Upload New Document'"
    (ctaClick)="openDialog()"
  >
    <app-theme-table
      #table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isLoading]="isLoading"
      (pageChange)="pageChange($event)"
      [totalPages]="totalPages"
      [totalRecords]="totalRecords"
      [pageNumber]="pageNumber"
      [noMetrics]="true"
    >
      <ng-container *appCustomColumn="'actions'; let item">
        <button (click)="download(item.id)">
          <mat-icon svgIcon="download" class="actionIcons"></mat-icon>
        </button>
        <button (click)="openDialog(item)">
          <mat-icon svgIcon="pencil" class="actionIcons"></mat-icon>
        </button>
        <button (click)="delete(item.id)">
          <mat-icon svgIcon="trash" class="actionIcons"></mat-icon>
        </button>
      </ng-container>
    </app-theme-table>
  </app-theme-container>
</div>
