@if (!isDisabled) {
    <div (drop)="onDrop($event)" (dragover)="onDragOver($event)" class="upload-files flex flex-col items-center justify-center">
        <mat-icon svgIcon="upload"></mat-icon>
        <p class="drag"><span (click)="browseFile()" class="click text-base">Click to upload</span> or drag and drop attachments</p>
        <p class="extensions">
            @for (extension of allowedExtensions; track extension; let i = $index) {
            {{ extension.toUpperCase().substring(1) }} @if(i < allowedExtensions.length - 1) { or }
            } (max. {{maxSizeMB}}MB)
        </p>
    </div>
}
@if (getFilesArray().controls.length) {
    <ng-container [formGroup]="form">
        <div [formArrayName]="formArrayName" class="">
            @for (fileGroup of getFilesArray().controls; track fileGroup; let i = $index) {
                @if (!fileGroup.get('deleted')?.value) {
                    <div class="file-container flex items-center justify-between">
                        <p class="text-base">{{ fileGroup.get('name')?.value }}</p>
                        @if (!isDisabled) {
                            <mat-icon (click)="removeFile(i)" class="cursor-pointer" svgIcon="trash-filled"></mat-icon>
                        }
                    </div>
                }
            }
        </div>
    </ng-container>
    }
<input type="file" [accept]="getAllowedTypes()" #fileInput class="hidden" (change)="onFileSelected($event)" />
