import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ImagePopupComponent } from '../../../components/image-popup/image-popup.component';

@Component({
  selector: 'app-document-card',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './document-card.component.html',
  styleUrl: './document-card.component.scss',
})
export class DocumentCardComponent {
  @Input() documentData: any
  dialogSubscription!: Subscription;
  readonly dialog = inject(MatDialog);

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/download-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/view.svg')
    );
  }

  viewImage() {
    this.dialog.open(ImagePopupComponent, {
      data: {
        imagePath:
          'https://images.pexels.com/photos/45113/pexels-photo-45113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      },
      panelClass: 'image-popup',
    });
  }
}
