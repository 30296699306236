import { Routes } from '@angular/router';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TicketsComponent } from './tickets/tickets.component';
import { PublicationsComponent } from './publications/publications.component';
import { SettingsComponent } from './settings/settings.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { DocumentsComponent } from './documents/documents.component';
import { MembersComponent } from './members/members.component';
import { TicketDetailComponent } from './tickets/ticket-detail/ticket-detail.component';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { PublicationDetailComponent } from './publications/publication-detail/publication-detail.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { VendorsComponent } from './vendors/vendors.component';
import { VendorDetailComponent } from './vendors/vendor-detail/vendor-detail.component';
import { QuestFuelComponent } from './quest-fuel/quest-fuel.component';

export const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '', loadChildren: () => import('./auth/auth.routes') },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'tickets', component: TicketsComponent },
            { path: 'tickets/detail/:id', component: TicketDetailComponent },
            { path: 'vendors', component: VendorsComponent },
            { path: 'vendors/detail/:id', component: VendorDetailComponent },
            { path: 'quest-fuel', component: QuestFuelComponent },
            { path: 'members', component: MembersComponent },
            { path: 'members/detail/:id', component: MemberDetailComponent },
            { path: 'publications', component: PublicationsComponent },
            { path: 'publications/detail/:id', component: PublicationDetailComponent },
            { path: 'documents', component: DocumentsComponent },
            { path: 'promotions', component: PromotionsComponent },
            { path: 'announcement', component: AnnouncementComponent },
            { path: 'settings', component: SettingsComponent },
        ],
    },
];
