import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { PromotionsService } from '../promotions.service';

@Component({
  selector: 'app-promtion-banner',
  standalone: true,
  imports: [],
  templateUrl: './promtion-banner.component.html',
  styleUrl: './promtion-banner.component.scss',
})
export class PromtionBannerComponent implements OnInit {
  @Input() id: number = 0;
  imageBase64: string | undefined;
  public sliderImages: any[] = [];
  constructor(
    private dialogService: DialogService,
    private promotionsService: PromotionsService
  ) {}

  ngOnInit(): void {
    this.getPromotionsImage(this.id);
  }

  getPromotionsImage(id: number) {
    this.promotionsService.getPromotionsImage(id).subscribe({
      complete: () => {},
      error: (err: any) => {
      },
      next: (data: any) => {
        this.imageBase64 = this.arrayBufferToBase64(data);
        this.sliderImages.push(`data:image/png;base64,${this.imageBase64}`);
      },
    });
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  openSlider() {
    this.dialogService.openSlider(this.sliderImages);
  }
}
