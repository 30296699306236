import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const DEFAULT_PAGE_SIZE = 10;

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [MatIconModule, HttpClientModule],
  providers: [HttpClient],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss'
})
export class PaginatorComponent {
  @Input() totalRecords: number = 0;
  @Input() pageNumber: number = 0;
  @Input() totalPages: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_SIZE;
  @Output() pageChange = new EventEmitter<number>();

  totalPagesArray: number[] = [];

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        'previous',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/previous.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'next',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/next.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'last',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/last.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'first',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/first.svg')
      );
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalPages'] || changes['pageSize']) {
      this.generateTotalPagesArray();
    }
  }

  calculateTotalPages(dataLength: number) {
    return Math.ceil(dataLength / this.pageSize);
  }

  generateTotalPagesArray() {
    this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  onNextPage() {
    if (this.pageNumber < this.totalPages - 1) {
      this.pageChange.emit(this.pageNumber + 1);
    }
  }

  onPreviousPage() {
    if (this.pageNumber > 0) {
      this.pageChange.emit(this.pageNumber - 1);
    }
  }

  onFirstPage() {
    if (this.pageNumber > 0) {
      this.pageChange.emit(0);
    }
  }

  onLastPage() {
    if (this.pageNumber + 1 < this.totalPages) {
      this.pageChange.emit(this.totalPages - 1);
    }
  }

  onPage(page: number) {
    this.pageChange.emit(page);
  }

}
