import { Component } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { HeaderComponent } from "../../components/header/header.component";
import { CompanyInformationComponent } from "./company-information/company-information.component";
import { OwnerInformationComponent } from "./owner-information/owner-information.component";
import { StoreSupervisorComponent } from "./store-supervisor/store-supervisor.component";
import { StoreManagerComponent } from "./store-manager/store-manager.component";
import { ReferenceComponent } from "./reference/reference.component";
import { RequiredDocsComponent } from "./required-docs/required-docs.component";
import { AccountingReviewComponent } from "./accounting-review/accounting-review.component";
import { DirectorReviewComponent } from "./director-review/director-review.component";
import { MatButtonModule } from "@angular/material/button";
import { HeaderService } from "../../services/header.service";
import { DialogService } from "../../services/dialog.service";
import { AddRemarksComponent } from "./add-remarks/add-remarks.component";

@Component({
  selector: "app-member-appliations-detail",
  standalone: true,
  imports: [
    MatTabsModule,
    HeaderComponent,
    CompanyInformationComponent,
    OwnerInformationComponent,
    StoreSupervisorComponent,
    StoreManagerComponent,
    ReferenceComponent,
    RequiredDocsComponent,
    AccountingReviewComponent,
    DirectorReviewComponent,
    MatButtonModule,
  ],
  templateUrl: "./member-appliations-detail.component.html",
  styleUrl: "./member-appliations-detail.component.scss",
})
export class MemberAppliationsDetailComponent {
  constructor(
    private headerService: HeaderService,
    private dialogService: DialogService
  ) {}

  ngAfterViewInit() {
    this.headerService.setShowBackIcon(true);
  }

  openRemarksDialog() {
    const dialogRef = this.dialogService.open({
      component: AddRemarksComponent,
      title: "Add Remarks",
      actionLabel: "Submit",
      themeModal: true,
      hideCloseIcon: true,
    });
  }
}
