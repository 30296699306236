import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-reference",
  standalone: true,
  imports: [MatInputModule, CommonModule, MatButtonModule],
  templateUrl: "./reference.component.html",
  styleUrl: "./reference.component.scss",
})
export class ReferenceComponent {
  @Output() remarks = new EventEmitter<any>();
  references = [
    {
      membershipNumber: 1,
      companyName: "ABC Corp",
      dba: "Food Mart #1",
      authorizedRepName: "John Doe",
    },
    {
      membershipNumber: 2,
      companyName: "ABC Corp",
      dba: "Food Mart #2",
      authorizedRepName: "John Doe",
    },
  ];
  
  addRemarks() {
    this.remarks.emit();
  }
}
