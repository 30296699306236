import { Component } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { QuestFuelService } from './quest-fuel.service';
import { AsyncPipe } from '@angular/common';
import { forkJoin, map, Subscription, Observable } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NotificationService } from '../services/notification.service';
import {
  TableColumns,
  TableComponent,
} from '../components/table/table.component';
import { MatChipsModule } from '@angular/material/chips';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { ContainerComponent } from '../components/container/container.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { DialogService } from '../services/dialog.service';
import { AddQuestFuelComponent } from './add-quest-fuel/add-quest-fuel.component';
import { MatSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-quest-fuel',
  standalone: true,
  imports: [
    HeaderComponent,
    MatTabsModule,
    AsyncPipe,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    ContainerComponent,
    CustomColumnDirective,
    MatChipsModule,
    MatTableModule,
    MatButtonToggleModule,
    ThemeContainerComponent,
    MatSpinner,
  ],
  templateUrl: './quest-fuel.component.html',
  styleUrl: './quest-fuel.component.scss',
})
export class QuestFuelComponent {
  questFuelForm: any;
  brandDropdown: FormGroup;
  locations$ = this.questFueService.getLocations();
  public brands$: Observable<any[]>;
  selectedTabIndex = 0;
  public pricingTiers: any = [];
  public products: any = [];

  public dataSource: any[] = [];
  dataSourceB = ['location', 'regular', 'super', 'diesel', 'bioDiesel'];
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;
  dialogSubscription!: Subscription;
  public pricingTiersType: string = 'formula';

  constructor(
    private questFueService: QuestFuelService,
    private fb: FormBuilder,
    public notificationService: NotificationService,
    private readonly dialogService: DialogService
  ) {
    this.getData();
    this.brandDropdown = this.fb.group({
      brandSelected: [null],
    });
    this.brands$ = this.questFueService.getBrands();
    this.brands$.subscribe((brands) => { 
      if (brands && brands.length > 0) {
        this.brandDropdown.patchValue({
          brandSelected: brands[0].value,
        });
      }
      this.getCurrentPricing(this.pricingTiersType);
    });
  }

  getData() {
    forkJoin([
      this.questFueService.getPricingTiers(),
      this.questFueService.getProducts(),
    ])
      .pipe(
        map((responses) => {
          const [response1, response2] = responses;
          this.pricingTiers = response1;
          this.products = response2;
        })
      )
      .subscribe(
        (combinedResponse) => {
          console.log('Combined Response:', combinedResponse);
        },
        (error) => {
          console.error('Error occurred:', error);
        }
      );
  }

  saveHandler(brand: any) {
    const geographyTypeId: any = this.questFuelForm.value.geographyTypeId;
    let arr: any = [];
    this.products.forEach((element: any) => {
      this.pricingTiers.forEach((e: any) => {
        let payload = {
          productId: element.value,
          brandId: brand.value,
          geographyId: geographyTypeId,
          pricingTierId: e.value,
          price: this.calculatePricing(e, element),
        };
        arr.push(payload);
      });
    });

    this.questFueService.createPricing(arr).subscribe({
      complete: () => {},
      error: (err: any) => {
        this.notificationService.error(err.message);
      },
      next: (res: any) => {
        this.notificationService.success('Pricing has been added');
        this.getCurrentPricing(this.pricingTiersType);
      },
    });
  }

  calculatePricing(pricingTier: any, product: any) {
    if (pricingTier.value === this.pricingTiers[0].value) {
      let value: any;

      switch (product.value) {
        case 1:
          value = this.questFuelForm.value.formulaRegular;
          break;
        case 2:
          value = this.questFuelForm.value.formularSuper;
          break;
        case 3:
          value = this.questFuelForm.value.formulaDiesel;
          break;
        case 4:
          value = this.questFuelForm.value.formulaBioDiesel;
          break;
        default:
          value = this.questFuelForm.value.formulaBioDiesel;
          break;
      }

      return value;
    } else {
      let value: any;
      switch (product.value) {
        case 1:
          value = this.questFuelForm.value.regularRegular;
          break;
        case 2:
          value = this.questFuelForm.value.regularrSuper;
          break;
        case 3:
          value = this.questFuelForm.value.regularDiesel;
          break;
        case 4:
          value = this.questFuelForm.value.regularBioDiesel;
          break;
        default:
          value = this.questFuelForm.value.regularBioDiesel;
          break;
      }

      return value;
    }
  }

  pricingHandler(type: string) {
    this.getCurrentPricing(type);
  }

  getCurrentPricing(type: string) {
    this.isLoading = true;
    this.questFueService
      .getCurrentPricing(
        this.getPricingTier(type),
        this.brandDropdown.value.brandSelected
      )
      .subscribe((response: any) => {
        if (response) {
          const { pricing, totalPages, pageNumber, totalRecords } = response;
          this.isLoading = false;
          this.totalPages = totalPages;
          this.totalRecords = totalRecords;
          this.pageNumber = pageNumber;
          this.dataSource = [];
          this.locations$.subscribe((data) => {
            data.map((geo) => {
              let geoPricing = pricing.filter(
                (f: any) => f.geography.key === geo.key
              );
              if (geoPricing && geoPricing.length > 0) {
                this.dataSource.push({
                  location: geoPricing[0].geography.key,
                  regular: geoPricing[0].price,
                  super: geoPricing[1].price,
                  diesel: geoPricing[2].price,
                  bioDiesel: geoPricing[3].price,
                });
              }
            });
          });
        }
      });
  }

  tabChanged(event: any) {}

  getPricingTier(type: string) {
    if (type === 'regular') {
      return 2;
    } else {
      return 1;
    }
  }

  brandChangeHandler(event: any) {
    this.getCurrentPricing(this.pricingTiersType);
  }

  togglePricingHandler(event: any) {
    this.pricingHandler(event.tab.textLabel.toLowerCase());
  }
  capitalize(str: string) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char: any) => char.toUpperCase());
  }
  addQuestFuel() {
    let selectedBrandKey;
    console.log(this.brandDropdown.value.brandSelected);
    this.brands$.subscribe((brands) => {
      const selectedValue = this.brandDropdown.value.brandSelected;
      const selectedBrand = brands.find(
        (brand) => brand.value === selectedValue
      );
      selectedBrandKey = selectedBrand ? selectedBrand.key : 'Key not found';
      const dialogRef = this.dialogService.open({
        component: AddQuestFuelComponent,
        title: `Add ${selectedBrandKey
          .toLowerCase()
          .replace(/\b\w/g, (char: any) => char.toUpperCase())}`,
        actionLabel: 'Add',
        closeLabel: 'Cancel',
        hideCloseIcon: true,
        themeModal: true,
      });

      this.dialogSubscription?.unsubscribe();
      this.dialogSubscription = this.dialogService.data$.subscribe(
        (formData: any) => {
          dialogRef.close();
          this.questFuelForm = formData;
          const geographyTypeId: any = formData.value.geographyTypeId;
          let arr: any = [];
          this.products.forEach((element: any) => {
            this.pricingTiers.forEach((e: any) => {
              let payload = {
                productId: element.value,
                brandId: this.brandDropdown.value.brandSelected,
                geographyId: geographyTypeId,
                pricingTierId: e.value,
                price: this.calculatePricing(e, element),
              };
              arr.push(payload);
            });
          });

          this.questFueService.createPricing(arr).subscribe({
            complete: () => {},
            error: (err: any) => {
              this.notificationService.error(err.message);
            },
            next: (res: any) => {
              this.notificationService.success('Pricing has been added');
              this.getCurrentPricing(this.pricingTiersType);
            },
          });
        }
      );
    });
  }
}
