<form [formGroup]="attachmentForm" class="mb-6">
  <app-theme-upload
    [form]="attachmentForm"
    formArrayName="attachments"
    [allowedExtensions]="['.pdf']"
    [showExtensions]="false"
    [slimUpload]="true"
  ></app-theme-upload>
</form>
<form [formGroup]="transactionForm" (ngSubmit)="onSubmit()">
  <div class="flex-1 mb-3">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Transaction Type</mat-label>
      <mat-select formControlName="transactionTypeId">
        @for (transactionType of transactionTypes; track $index) {
        <mat-option [value]="transactionType.value">{{transactionType.key}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Vendor</mat-label>
      <mat-select formControlName="vendorId">
        @for (vendor of vendors; track $index) {
        <mat-option [value]="vendor.value">{{ vendor.key }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Fiscal Period</mat-label>
      <mat-select formControlName="qType">
        @for (fiscalPeriod of fiscalPeriods; track $index) {
        <mat-option [value]="fiscalPeriod.value">{{fiscalPeriod.key}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Fiscal Year</mat-label>
      <mat-select formControlName="fiscalYear">
        <mat-option value="2015">2015</mat-option>
        <mat-option value="2016">2016</mat-option>
        <mat-option value="2017">2017</mat-option>
        <mat-option value="2018">2018</mat-option>
        <mat-option value="2019">2019</mat-option>
        <mat-option value="2020">2020</mat-option>
        <mat-option value="2021">2021</mat-option>
        <mat-option value="2022">2022</mat-option>
        <mat-option value="2023">2023</mat-option>
        <mat-option value="2024">2024</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field
      appearance="outline"
      class="w-full white-backgrounded-field"
    >
      <mat-label>Rebate Type</mat-label>
      <mat-select formControlName="rebateTypeId">
        @for (rebateType of rebateTypes; track $index) {
        <mat-option [value]="rebateType.value">{{rebateType.key}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex-1 mb-3">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Rebate Rate</mat-label>
      <input matInput formControlName="rebateRate" />
    </mat-form-field>
  </div>
</form>
