import { AsyncPipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { MemberElement, MembersService } from './members.service';
import { HeaderComponent } from '../components/header/header.component';
import {
  TableColumns
} from '../components/table/table.component'; 
import { MatChipsModule } from '@angular/material/chips';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';
import { ThemeTableComponent } from '../components/theme-table/theme-table.component';

@Component({
  selector: 'app-members',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent, 
    ThemeContainerComponent,
    ThemeTableComponent,
    CustomColumnDirective,
    MatChipsModule
  ],
  providers: [HttpClient],
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss',
})
export class MembersComponent {
  dataSource!: MatTableDataSource<MemberElement>;
  displayedColumns: TableColumns[] = [
    {
      key: 'id',
      label: 'Membership No.',
      displayValue: (data) => '#' + data.id,
    },
    { key: 'name', label: 'Business Name' },
    {
      key: 'membershipType',
      label: 'Membership Type',
      displayValue: (data) => data.membershipType?.key,
    },
    { key: 'email', label: 'Email' },
    { key: 'phone', label: 'Phone Number' },
    {
      key: 'address',
      label: 'Address',
      displayValue: (data) =>
        data.address?.street1 +
        ', ' +
        data.address?.city +
        ',' +
        data.address?.state,
    },
    { key: 'isMembershipActive', label: 'Status' },
  ];
  public membersStats: any = [
    {
      title: 'Total Members',
      count: '1,323',
      icon: 'total-members',
    },
    {
      title: 'New Members',
      count: '1,323',
      icon: 'new-members',
    },
    {
      title: 'Active Members',
      count: '1,323',
      icon: 'active-members',
    },
  ];
  isLoading = false;
  totalRecords!: number;
  totalPages!: number;
  pageNumber!: number;
  metrics$ = this.membersService.getMetrics();

  currentPage = 1;
  pageSize = 10;
  calculateTotalPages(dataLength: number) {
    return Math.ceil(dataLength / this.pageSize);
  }

  updateTableData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    this.membersService.data$.subscribe((data) => {
      this.totalPages = this.calculateTotalPages(data.length);
      const slicedData = data.slice(startIndex, endIndex);
      this.dataSource.data = slicedData;
    });
  }

  memberForm = this.fb.group({
    vendorBusinessTypeId: [0, [Validators.required]],
    address: this.fb.group({
      street1: ['', Validators.required],
      street2: ['', Validators.required],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
    }),
  });
  member$!: Observable<MemberElement | null>;
  memberId!: number;
  public member?: MemberElement;
  previousFormValue: any;

  constructor(
    private membersService: MembersService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.matIconRegistry.addSvgIcon(
      'total-members',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/total-members.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'new-members',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/new-member.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'active-members',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/active-members.svg'
      )
    );
    this.dataSource = new MatTableDataSource<MemberElement>([]);
  }


  viewDetail(id: number) {
    this.router.navigate(['/members/detail', id]);
  }

  pageChange(page: number) {
    this.membersService.getMembers(page).subscribe((response: any) => { 
      const { members, totalPages, pageNumber, totalRecords } = response;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber; 
      this.dataSource = members.sort((a: any, b: any) => {
        return b.isMembershipActive - a.isMembershipActive;
      });
    });
  }

  applyFilter(event: any) {
    this.isLoading = true
    this.membersService
      .searchMember(event, 0, 10)
      .subscribe((response: any) => {
        const { members, totalPages, pageNumber, totalRecords } = response;
      this.isLoading = false;
      this.totalPages = totalPages;
      this.totalRecords = totalRecords;
      this.pageNumber = pageNumber;
      this.dataSource = members;
      });
  }
}
