import { AsyncPipe, CommonModule, DatePipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { MatChipsModule } from '@angular/material/chips';
import { Observable, Subscription, filter, finalize, switchMap, tap } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';
import { NotificationService } from '../../services/notification.service';
import { MatButtonModule } from '@angular/material/button';
import { VendorElement, VendorsService } from '../vendors.service';
import { DialogService } from '../../services/dialog.service';
import { VendorContactDialogComponent } from '../vendor-contact-dialog/vendor-contact-dialog.component';
import { AlertService } from '../../services/alert.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';

@Component({
  selector: 'app-vendor-detail',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    DatePipe,
    NgClass,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    HeaderComponent,
    MatChipsModule,
    MatProgressSpinnerModule,
  ],
  providers: [HttpClient],
  templateUrl: './vendor-detail.component.html',
  styleUrl: './vendor-detail.component.scss',
})
export class VendorDetailComponent {
  vendorForm = this.fb.group({
    vendorBusinessTypeId: [0, [Validators.required]],
    address: this.fb.group({
      street1: ['', Validators.required],
      street2: ['', Validators.required],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
    }),
  });
  vendorContactForm = this.fb.group({
    contacts: this.fb.array([this.initContacts()]),
  });
  departmentContactForm = this.fb.group({
    contacts: this.fb.array([this.initContacts()]),
  });
  isLoading: boolean = false;
  vendor$!: Observable<VendorElement | null>;
  vendorId: number;
  dialogSubscription!: Subscription;
  isEditing = false;
  isAddressEditing = false;
  isAccountManagerEditing = true;
  previousFormValue: any;
  public vendor?: VendorElement;
  departmentTypes$ = this.vendorsService.getDepartmentTypes();

  constructor(
    private notificationService: NotificationService,
    private vendorsService: VendorsService,
    private fb: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialogService: DialogService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      'note-edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/note-edit.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/add-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/trash-active.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/send.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'more-vertical',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/more-vertical.svg'
      )
    );
    this.headerService.setShowBackIcon(true);
    this.vendorId = +this.route.snapshot.paramMap.get('id')!;
  }

  ngOnInit(): void {
    this.fetchVendorDetail().subscribe();
    this.vendorContactForm.disable();
  }

  get representativeContacts(): FormArray {
    return this.vendorContactForm.get('contacts') as FormArray;
  }

  get departmentaContacts(): FormArray {
    return this.departmentContactForm.get('contacts') as FormArray;
  }

  fetchVendorDetail() {
    this.isLoading = true;
    this.vendor$ = this.vendorsService.getVendorById(this.vendorId);
    return this.vendorsService.getVendorById(this.vendorId).pipe(
      tap((vendor) => {
        if (vendor) {
          this.vendor = vendor;
          this.representativeContacts.clear();
          this.departmentaContacts.clear();
          this.vendor.contacts.forEach((element) => {
            if (element.isAccountManager) {
              this.representativeContacts.push(this.updateContacts(element));
            } else {
              this.departmentaContacts.push(this.updateContacts(element));
            }
          });

          const formValues = {
            address: vendor.address,
            name: vendor.name,
            vendorBusinessTypeId: vendor.businessType.value,
            id: vendor.id,
          };
          this.vendorForm.patchValue(formValues as any);
          this.vendorForm.disable();
          this.vendorContactForm.disable();
          this.departmentContactForm.disable();
          this.isLoading = false;
          this.previousFormValue = this.vendorForm.value;
        }
      })
    );
  }

  initContacts() {
    return this.fb.group({
      id: null,
      departmentName: ['', [Validators.required]],
      departmentTypeId: [1],
      email: ['', [Validators.required]],
      firstName: ['', Validators.required],
      isAccountManager: [null, [Validators.required]],
      jobTitle: ['', Validators.required],
      phone: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  updateContacts(object: any) {
    return this.fb.group({
      id: object.id,
      departmentName: [object.departmentName, [Validators.required]],
      departmentTypeId: [object.departmentType.value],
      email: [object.email, [Validators.required]],
      firstName: [object.firstName, Validators.required],
      isAccountManager: [object.isAccountManager, [Validators.required]],
      jobTitle: [object.jobTitle, Validators.required],
      phone: [object.phone, [Validators.required]],
      lastName: [object.lastName, [Validators.required]],
    });
  }

  edit(form?: string) {
    if (form === 'address') {
      this.isAddressEditing = true;
      this.vendorForm.enable();
    }
  }

  cancelEdit() {
    this.isAddressEditing = false;
    this.vendorForm.patchValue(this.previousFormValue);
    this.vendorForm.disable();
  }

  update(type?: string) {
    if (type === 'address') {
      this.vendorsService
        .updateVendor(this.vendorId, this.vendorForm.value)
        .pipe(switchMap(() => this.fetchVendorDetail()))
        .subscribe((data) => {
          this.isAddressEditing = false;
          this.notificationService.success('Vendor address has been updated');
        });
    }
  }

  openDialog(title: string, button: string, contact?: any) {
    const dialogRef = this.dialogService.open({
      component: VendorContactDialogComponent,
      title: title,
      actionLabel: button,
      data: contact,
      hideCloseIcon: true,
      themeModal: true,
    });
    this.dialogSubscription?.unsubscribe();
    if (contact) {
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.vendorsService.updateContact(this.vendorId, contact.id, data)
          )
        )
        .pipe(switchMap(() => this.fetchVendorDetail()))
        .subscribe((data) => {
          dialogRef.close();
          this.notificationService.success('Contact has been updated');
        });
    } else {
      this.dialogSubscription = this.dialogService.data$
        .pipe(
          switchMap((data) =>
            this.vendorsService.createContact(this.vendorId, data)
          )
        )
        .pipe(switchMap(() => this.fetchVendorDetail()))
        .subscribe((data: any) => {
          dialogRef.close();
          this.notificationService.success('Contact has been added');
        });
    }
  }

  deleteContact(contact: any) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this contact?',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.isLoading = true;
        this.vendorsService
          .deleteContact(this.vendorId, contact.value.id)
          .pipe(finalize(()=>{this.isLoading = false}))
          .subscribe((res) => {
            let control = <FormArray>(
              this.vendorContactForm.controls['contacts']
            );
            let deptControl = <FormArray>(
              this.departmentContactForm.controls['contacts']
            );
            if (contact.value.isAccountManager) {
              let idx = this.vendorContactForm.controls[
                'contacts'
              ].value.findIndex((x) => x.id === contact.value.id);
              control.removeAt(idx);
            } else {
              let idx = this.departmentContactForm.controls[
                'contacts'
              ].value.findIndex((x) => x.id === contact.value.id);
              deptControl.removeAt(idx);
            }
            this.notificationService.success('Contact has been deleted');
          });
      }
    });
  }

  deactivate() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to deactivate this account?',
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.vendorsService.deactivateVendor(this.vendorId).subscribe((res) => {
          this.notificationService.success('Vendor has been deactivated');
          this.router.navigate(['/vendors']);
        });
      }
    });
  }

  activate() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to activate this account?',
        type: 'primary',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.vendorsService.activateVendor(this.vendorId).subscribe((res) => {
          this.notificationService.success('Vendor has been activated');
          this.router.navigate(['/vendors']);
        });
      }
    });

    // const title = 'Are you sure you want to activate this account?';
    // const message = `The user of this account will no longer be able to view or perform<br /> other actions but their data won't be removed`;
    // const alertRef = this.alertService.show(
    //   { title, message, actionLabel: 'Activate' },
    //   'vendor-activate'
    // );
    // alertRef
    //   .afterClosed()
    //   .pipe(filter((result) => !!result))
    //   .subscribe((res: any) => {
    //     if (res) {
    //       this.vendorsService.activateVendor(this.vendorId).subscribe((res) => {
    //         this.notificationService.success('Vendor has been activated');
    //         this.router.navigate(['/vendors']);
    //       });
    //     }
    //   });
  }
}
