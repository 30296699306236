<div class="p-6">
  <div id="ownerFormRefs">
    @for(owner of owners; track owner; let i = $index) {
    <div
      class="flex gap-2 items-center justify-between mb-4"
      [ngClass]="i >= 1 ? 'mt-6' : ''"
    >
      <h1 class="text-xl font-medium shrink-0">
        {{ owner?.firstName }} {{ owner?.middleName ? owner?.middleName : "" }}
        {{ owner?.lastName }}
      </h1>
      @if(i == 0) {
      <button class="btn-remarks" mat-button (click)="addRemarks()">
        Add Remarks
      </button>
      }
    </div>
    <div class="mb-6">
      <div
        class="flex items-start gap-3 flex-col sm:flex-row sm:gap-8 sm:items-center"
      >
        <mat-checkbox [checked]="owner?.isAuthorizedRep" color="primary"
          >Authorised Representative</mat-checkbox
        >
        <mat-checkbox [checked]="owner?.isAuthorizedOfficer" color="primary"
          >Authorized Officer</mat-checkbox
        >
        <mat-checkbox [checked]="owner?.isSecretary" color="primary"
          >Authorized Secretary</mat-checkbox
        >
      </div>
    </div>
    <div class="grid sm:grid-cols-3 gap-x-4 grid-cols-1">
      <mat-form-field appearance="outline" class="w-full mb-2">
        <mat-label>First Name</mat-label>
        <input matInput [value]="owner?.firstName" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full mb-2">
        <mat-label>Middle Name</mat-label>
        <input matInput [value]="owner?.middleName" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full mb-2">
        <mat-label>Last Name</mat-label>
        <input matInput [value]="owner?.lastName" />
      </mat-form-field>
    </div>
    <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
      <div class="grid grid-cols-2 gap-x-4">
        <mat-form-field appearance="outline" class="w-full mb-2">
          <mat-label>Title</mat-label>
          <input matInput [value]="owner?.title" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full mb-2">
          <mat-label>Ownership %</mat-label>
          <input matInput [value]="owner?.ownershipStake" />
        </mat-form-field>
      </div>
      <div class="grid sm:grid-cols-2 gap-x-4 grid-cols-1">
        <mat-form-field appearance="outline" class="w-full mb-2">
          <mat-label>Mobile/ Phone Number</mat-label>
          <input matInput [value]="owner?.phone" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full mb-2">
          <mat-label>Email Address</mat-label>
          <input matInput [value]="owner?.email" />
        </mat-form-field>
      </div>
    </div>

    <div class="flex gap-2 items-center justify-between mb-4">
      <h1 class="text-xl font-medium shrink-0">Additional Info</h1>
    </div>
    <div class="grid md:grid-cols-3 gap-4 grid-cols-1">
      @for(document of owner.documents; track document) {
      <app-document-card [documentData]="document"></app-document-card>
      }
    </div>
    }
  </div>
</div>
