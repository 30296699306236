<app-header>
  <p title>Support Tickets</p>
</app-header>
<div class="main-content tickets p-6">
  <div class="flex justify-center space-x-5 mb-6">
    @if (metrics$ | async; as metrics) {
    <div class="metrics-counter">
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">New Tickets</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.NEW }}</p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Tickets In Progress</p>
        <p class="text-xl mt-2 font-bold">{{ metrics["IN-PROGRESS"] }}</p>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="text-lg font-normal">Tickets Closed</p>
        <p class="text-xl mt-2 font-bold">{{ metrics.CLOSED }}</p>
      </div>
    </div>
    }
  </div>

  <app-theme-container
    (filter)="applyFilter($event)"
    title="Support Tickets"
    [totalRecords]="totalRecords"
    searchPlaceholder="Search tickets by business name, membership no. etc..."
    [cta]="'Create New Ticket'"
    (ctaClick)="openDialog()"
  >
    <app-theme-table
      #table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isLoading]="isLoading"
      (rowClick)="viewDetail($event.id)"
      (pageChange)="pageChange($event)"
      [totalPages]="totalPages"
      [totalRecords]="totalRecords"
      [pageNumber]="pageNumber" 
      searchPlaceholder="Search tickets by business name, membership no. etc..."
    >
      <ng-container *appCustomColumn="'statusType'; let item">
        <div class="status-badge flex items-center">
          <span
            [ngClass]="{
            'blue': item.statusType.key === 'NEW',
            'grey': item.statusType.key === 'CLOSED',
            'green': item.statusType.key === 'IN-PROGRESS',
          }"
          >
            {{ formatStatusKey(item.statusType.key) }}
          </span>
        </div>
      </ng-container>
    </app-theme-table>
  </app-theme-container>
</div>
