<div class="details">
    @if (publication$ | async; as publication) {
        <div class="content">
            <div class="image">
                <img src="assets/publication.jpeg">
            </div>
            <h2 class="text-lg font-bold">{{ publication.title }}</h2>
            <p class="sub text-base font-bold">{{ publication.description }}</p>
        </div>
    }
</div>
