<div [class.slim-upload]="slimUpload">
  @if (!isDisabled) {
  <div
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    class="upload-files flex flex-col items-center justify-center"
  >
    @if(!slimUpload){
      <mat-icon class="cursor-pointer" svgIcon="image"></mat-icon>
    }
    <p class="drag">Drag & Drop File here</p>
    <p class="text-sm text-light-grey">Or</p>
    <button (click)="browseFile()" class="upload-btn">
      <mat-icon class="cursor-pointer" svgIcon="uploadImage"></mat-icon>Upload
      File
    </button>
    @if(showExtensions){
    <p class="text-sm text-light-grey">(JPG, JPEG, PNG)</p>
    }
  </div>
  } @if (getFilesArray().controls.length) {
  <ng-container [formGroup]="form">
    <div [formArrayName]="formArrayName" class="upload-listing">
      @for (fileGroup of getFilesArray().controls; track fileGroup; let i =
      $index) { @if (!fileGroup.get('deleted')?.value) {
      <div class="list-items">
        <div class="file-info">
          <mat-icon class="cursor-pointer" svgIcon="fileImage"></mat-icon>
          <div class="file-name">
            <p class="font-medium">{{ fileGroup.get("name")?.value }}</p>
          </div>
        </div>
        @if (!isDisabled) {
        <div class="file-cta">
          @if(showChange){
          <button
            mat-flat-button
            (click)="browseFile(i)"
            class="btn-transparent"
          >
            Change
          </button>
          }
          <button mat-icon-button class="flex" (click)="removeFile(i)">
            <mat-icon class="cursor-pointer" svgIcon="trash"></mat-icon>
          </button>
        </div>
        }
      </div>
      } }
    </div>
  </ng-container>
  }
  <input
    type="file"
    [accept]="getAllowedTypes()"
    #fileInput
    class="hidden"
    (change)="onFileSelected($event)"
  />
  <input
    type="file"
    [accept]="getAllowedTypes()"
    #changeFileInput
    class="hidden"
    (change)="onChangeFileSelected($event)"
  />
</div>
