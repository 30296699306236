import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VendorsService } from '../vendors.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSharingService } from '../../services/data-sharing.service';
import { CustomeValidationsService } from '../../services/validations.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-vendor-contact-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxMaskDirective
  ],
  providers: [provideNgxMask()],
  templateUrl: './vendor-contact-dialog.component.html',
  styleUrl: './vendor-contact-dialog.component.scss',
})
export class VendorContactDialogComponent {
  businessTypes$ = this.vendorsService.getBusinessTypes();
  departmentTypes$ = this.vendorsService.getDepartmentTypes();
  public numericValidator = this.customValidationsService.numericValidator;
  public strictEmailValidator = this.customValidationsService.strictEmailValidator
  vendorContactForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    jobTitle: ['', [Validators.required]],
    email: ['', [Validators.required, this.strictEmailValidator]],
    phone: [null, [Validators.required]],
    departmentTypeId: [1, [Validators.required]],
    departmentName: ['', [Validators.required]],
    isAccountManager: [true]
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private vendorsService: VendorsService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private dataService: DataSharingService,
    private customValidationsService: CustomeValidationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogService.onData(this.createData());
      });
    if (this.data.data) {
      this.patchFormValues();
    }
    this.vendorContactForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.vendorContactForm.valid);
    });
  }

  patchFormValues() {
    const { firstName, lastName, jobTitle, email, phone, departmentName, departmentTypeId, isAccountManager} =
      this.data.data;
    const formValues = {
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      email: email,
      phone: phone,
      departmentTypeId,
      departmentName: departmentName,
      isAccountManager
    };
    this.vendorContactForm.patchValue(formValues as any);
  }

  createData() {
    if (this.data.data) {
      const {
        firstName,
        lastName,
        jobTitle,
        email,
        phone,
        departmentName,
        departmentTypeId,
        isAccountManager
      } = this.vendorContactForm.value;
      const body = {
        firstName,
        lastName,
        jobTitle,
        email,
        phone,
        isAccountManager,
        departmentTypeId,
        departmentName,
      };
      return body;
    } else {
      const { firstName, lastName, jobTitle, email, phone, departmentName, departmentTypeId } =
        this.vendorContactForm.value;
      const body = [
        {
          firstName: firstName,
          lastName: lastName,
          jobTitle: jobTitle,
          email: email,
          phone: phone,
          isAccountManager: this.data.title.toLowerCase().includes('department')
            ? false
            : true,
          departmentTypeId: departmentTypeId,
          departmentName: departmentName,
        },
      ];
      return body;
    }
  }
}
