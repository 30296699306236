<div class="bg-white px-6 pb-6 relative drop-shadow-md rounded-2xl">
    <div class="flex rounded-2xl items-center py-6">
        <h2 class="text-xl font-medium">{{title}}</h2>
        <mat-form-field class="w-1/2 ml-auto" subscriptSizing="dynamic">
            <mat-icon matPrefix svgIcon="search"></mat-icon>
            <input #searchInput class="bg-slate-200" matInput (keyup)="applyFilter($event)" [placeholder]="searchPlaceholder" #input>
        </mat-form-field>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>
