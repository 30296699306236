import { ApplicationConfig, importProvidersFrom } from '@angular/core';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRouter, withHashLocation } from '@angular/router';
import {
  MSAL_INSTANCE,
  MsalService,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { msalConfig, MSALGuardConfigFactory } from './msal.config';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthInterceptor } from './auth/auth.interceptor';

function MSALInstanceFactory() {
  return new PublicClientApplication(msalConfig);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(HttpClientModule),
    provideRouter(routes, withHashLocation()),
    provideAnimationsAsync(),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(HttpClientModule),

    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    {
      provide: 'MSALGuardConfig',
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MsalService,
    MsalBroadcastService,
  ],
};
