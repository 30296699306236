import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { PaginatorComponent } from '../paginator/paginator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CustomColumnDirective } from '../../directives/custom-column.directive';
import { DialogService } from '../../services/dialog.service';
import { TicketReminderComponent } from '../../tickets/ticket-reminder/ticket-reminder.component';
import { Subscription } from 'rxjs';


export interface TableColumns {
  key: string,
  label: string,
  isDate?: boolean;
  displayValue?: (args: any) => string;
};

@Component({
  selector: 'app-theme-table',
  standalone: true,
  imports: [NgTemplateOutlet, DatePipe, MatProgressSpinnerModule, MatInputModule, MatTableModule, MatIconModule, MatSortModule, MatPaginatorModule, HttpClientModule, PaginatorComponent, CustomColumnDirective],
  providers: [HttpClient],
  templateUrl: './theme-table.component.html',
  styleUrl: './theme-table.component.scss'
})
export class ThemeTableComponent {
  @Input() displayedColumns: TableColumns[] = [];
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @Input() totalRecords!: number;
  @Input() totalPages!: number;
  @Input() pageNumber!: number;
  @Input() isLoading = true;
  @Input() noMetrics = false;
  @Input() title: string = '';
  @Input() searchPlaceholder: string = '';
  @Output() rowClick = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ContentChildren(CustomColumnDirective) customColumnDirectives!: QueryList<CustomColumnDirective>;
  public columnKeys: string[] = [];
  dialogSubscription!: Subscription;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly dialogService: DialogService,
    private readonly domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        'timer',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
      );
  }

  ngOnInit() {
    this.columnKeys = this.displayedColumns.map(column => column.key);
    this.pageChange.emit(0); 
  }

  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe(() => {
      this.isLoading = true;
      this.paginator.firstPage();
    });
  }

  getCustomColTempRef(columnKey: string): TemplateRef<any> | null {
    const customColumnDirective = this.customColumnDirectives.find(directive =>
      directive.columnKey === columnKey
    );
    return customColumnDirective ? customColumnDirective.templateRef : null;
  }

  onRowClick(item: any) { 
    this.rowClick.emit(item);
  }

  onPageChange(page: PageEvent) {
    this.pageChange.emit(this.paginator.pageIndex);
  }
  
  onTimerClick(rowData: any){
    const dialogRef = this.dialogService.open({
      component: TicketReminderComponent,
      title: 'Set Reminder',
      actionLabel: 'Submit',
      hideCloseIcon: true,
      themeModal: true,
      data: {
        ticketId: rowData.id
      }
    });
    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe(
      (res: any) => { 
        dialogRef.close();
      }
    );
  }
}
