
@if(loading){
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<div mat-dialog-title [class.theme-modal-header]="data.themeModal">
  <div class="flex items-center">
    <span class="support-title text-xl font-medium">{{ data.title }}</span>
    @if(!data.hideCloseIcon){
      <mat-icon
        [mat-dialog-close]="true"
        class="cross cursor-pointer ml-auto"
        svgIcon="cross"
      ></mat-icon>
    }
  </div>
</div>
<mat-dialog-content [class.theme-modal-body]="data.themeModal">
  <ng-container *ngComponentOutlet="data.component" />
</mat-dialog-content>
<mat-dialog-actions align="end" [class.theme-modal-action]="data.themeModal">
  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="close font-medium"
  >
    {{ data.closeLabel }}
  </button>
  <button
    mat-raised-button
    (click)="create()"
    [disabled]="!isDisabled || loading"
    color="primary"
    class="ml-2 mb-5 font-medium flex"
  >
    @if(loading){
    <ng-container>
      <app-loading-spinner></app-loading-spinner>
    </ng-container>

    } @else {

    {{ data.actionLabel }}
    }
  </button>
</mat-dialog-actions>
