import { Component } from '@angular/core'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-ticket-link-mail',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatIconModule],
  templateUrl: './ticket-link-mail.component.html',
  styleUrl: './ticket-link-mail.component.scss',
})
export class TicketLinkMailComponent {}
