@if (isLoading) {
  <div class="theme-spinner">
    <mat-spinner></mat-spinner>
  </div>
}
<app-header>
  <p title class="items-center flex">
    {{ member?.name }}
    @if(member){
    <mat-chip
      class="ml-4 chip"
      [class.active-chip]="member?.isMembershipActive"
      [class.inactive-chip]="!member?.isMembershipActive"
    >
      {{ member?.isMembershipActive ? "Active" : "In Active" }}</mat-chip
    >

    }
  </p>
  @if(member){ @if (member?.isMembershipActive) {
  <button (click)="deactivate()" mat-stroked-button class="deactivate-btn">
    Deactivate Account
  </button>
  } @else {
  <button mat-stroked-button (click)="activate()" class="activate-btn">
    <span class="uppercase">Activate Account</span>
  </button>
  } }
</app-header>
<div class="main-content">
  <div class="p-6">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Details">
        <form [formGroup]="memberForm">
          <div class="theme-card tab-card">
            <div class="details">
              <h2 class="text-lg font-medium">Information</h2>
              <div class="detail-container py-6">
                <div class="flex">
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Name</mat-label>
                      <input matInput formControlName="businessName" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Email</mat-label>
                      <input matInput formControlName="businessEmail" />
                    </mat-form-field>
                  </div>
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Type</mat-label>
                      <input matInput formControlName="businessType" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Membership Number</mat-label>
                      <input matInput formControlName="businessNumber" />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Doing Business As</mat-label>
                      <input matInput formControlName="businessAs" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Membership Type</mat-label>
                      <input matInput formControlName="businessMembership" />
                    </mat-form-field>
                  </div>
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Contact-Phone</mat-label>
                      <input matInput formControlName="businessContact" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-card tab-card">
            <div class="details">
              <h2 class="text-lg font-medium">Address</h2>
              <div class="detail-container py-6">
                <div class="flex">
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Address 1</mat-label>
                      <input matInput formControlName="businessAddress1" />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>State</mat-label>
                      <input matInput formControlName="businessState" />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Business Address 2</mat-label>
                      <input matInput formControlName="businessAddress2" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Zip Code</mat-label>
                      <input matInput formControlName="businessZip" />
                    </mat-form-field>
                  </div>
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>City</mat-label>
                      <input matInput formControlName="businessCity" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-card tab-card">
            <div class="details">
              <h2 class="text-lg font-medium">Representative</h2>
              <div class="detail-container py-6">
                <div class="flex">
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Title</mat-label>
                      <input matInput formControlName="businessRepTitle" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="businessRepEmail" />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Name</mat-label>
                      <input matInput formControlName="businessRepName" />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Ownership Percentage</mat-label>
                      <input matInput formControlName="businessRepPercentage" />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Phone</mat-label>
                      <input matInput formControlName="businessRepPhone" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-card tab-card" formArrayName="owners">
            <div class="details">
              <h2 class="text-lg font-medium">Owners</h2>
              @for(owner of owners.controls; let i = $index; track owner){
              <div class="detail-container py-6" [formGroupName]="i">
                <div class="flex">
                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Title</mat-label>
                      <input matInput formControlName="businessOwnerTitle" />
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="businessOwnerEmail" />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Name</mat-label>
                      <input matInput formControlName="businessOwnerName" />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Ownership Percentage</mat-label>
                      <input
                        matInput
                        formControlName="businessOwnerPercentage"
                      />
                    </mat-form-field>
                  </div>

                  <div class="flex-1 mr-4">
                    <mat-form-field
                      appearance="outline"
                      class="w-full field-enable-text"
                    >
                      <mat-label>Phone</mat-label>
                      <input matInput formControlName="businessOwnerPhone" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              }
              @if (owners.controls.length==0){
                <h3 class="text-[#5F5E5E] text-center">No owners found</h3>
              }
            </div>
          </div>
        </form>
      </mat-tab> 
      <mat-tab label="Finance"></mat-tab>
      <mat-tab label="Fuel Profile">
        <app-member-detail-fuel [memberId]="memberId"></app-member-detail-fuel>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
