<app-header>
    <p title>Transaction # 001</p>
    <button class="status-btn" mat-flat-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="dropdown"></mat-icon>
      Published
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon svgIcon="cross"></mat-icon>
        <span>Unpublish</span>
      </button> 
    </mat-menu>
  </app-header>
  <div class="main-content p-6">
    <app-theme-container
    class="mt-5 block"
      (filter)="applyFilter($event)"
      title="Transaction Entries"
      [totalRecords]="totalRecords"
      searchPlaceholder="Search Transactions Entry by ID or Member ID"
      [downloadbtn]="'Download CSV'"
    >
      <app-theme-table
        #table
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns" 
        (pageChange)="pageChange($event)"
        [totalPages]="totalPages"
        [totalRecords]="totalRecords"
        [pageNumber]="pageNumber"
        [isLoading]="isLoading" 
        searchPlaceholder="Search Transactions Entry by ID or Member ID"
      >
        <ng-container *appCustomColumn="'statusType'; let item">
          <div class="status-badge flex items-center">
            <span
              [ngClass]="{ 
            'grey': item.statusType.key === 'Unpublished',
            'green': item.statusType.key === 'Published',
          }"
            >
              {{ formatStatusKey(item.statusType.key) }}
            </span>
          </div>
        </ng-container>
      </app-theme-table>
    </app-theme-container>
  </div>
  