<div class="menu px-2 flex flex-col">
    <div class="p-4 pt-6 mb-[14px] flex">
      <img src="assets/logo.svg" alt="Icon" class="logo" />
    </div>
    <ul class="w-full">
      @for (menuItem of menuList; track menuItem) {
      <li class="menu-item mb-2" routerLinkActive="active">
        <a
          class="flex items-center p-4 gap-3 text-[14px] text-[#000000] font-medium"
          [routerLink]="menuItem.route"
          matRipple
        >
          <mat-icon [svgIcon]="menuItem.iconName" class="menu-icon" [ngClass]="menuItem.route" />
          {{ menuItem.label }}
        </a>
      </li>
      }
    </ul>
  </div>
