@if (isLoading) {
<div
  class="fixed flex h-[100vh] items-center justify-center w-[100vw] top-[0] left-[0] z-50"
>
  <div class="absolute">
    <mat-spinner></mat-spinner>
  </div>
</div>
}
<app-header>
  <p title class="flex gap-4 items-center">
    Support Ticket #{{ ticketId }}
    @if (currentTicketStatus) {
    <button
      mat-flat-button
      [matMenuTriggerFor]="menu"
      color="primary"
      class="theme-btn status-btn"
      [ngClass]="{
        blue: currentTicketStatus === 'NEW',
        grey: currentTicketStatus === 'CLOSED',
        green: currentTicketStatus === 'IN-PROGRESS'
      }"
    >
      {{ formatStatusKey(currentTicketStatus) }}
      @if(currentTicketStatus != 'CLOSED'){
      <mat-icon svgIcon="dropdown"></mat-icon>
      }
    </button>
    @if(currentTicketStatus=='IN-PROGRESS' || currentTicketStatus == 'CLOSED'){
    <mat-chip class="assignee-chip"
      >Assignee: {{ ticketForm.get("assignedTo")?.value }}</mat-chip
    >
    }
    <mat-menu #menu="matMenu">
      @if(currentTicketStatus=='NEW'){
      <button mat-menu-item (click)="updateStatus(2)">In Progress</button>
      } @if(currentTicketStatus=='NEW'|| currentTicketStatus=='IN-PROGRESS'){
      <button mat-menu-item (click)="updateStatus(3)">Closed</button>
      }
    </mat-menu>
    }
  </p>
</app-header>

<div class="details main-content">
  <div class="col-span-4 flex flex-col justify-center space-y-5 py-6">
    <form [formGroup]="ticketForm" class="space-y-6 p-6">
      <div class="theme-card">
        <div
          class="flex items-center justify-between flex-wrap gap-2"
          [class.isEditing]="isEditing"
        >
          <h2 class="text-xl font-[500]">Details</h2>
          <div class="flex md:gap-3 items-center gap-2 flex-wrap justify-end">
            @if(currentTicketStatus != 'CLOSED'){ @if(!isEditing ){
            <button mat-flat-button (click)="viewReminders()">
              <span class="text-mat-primary">Reminder</span>
            </button>
            <button
              mat-stroked-button
              color="primary"
              (click)="edit(true)"
              [disabled]="isEditing"
              class="theme-stroke-btn"
            >
              <span
                class="flex text-{{
                  !isEditing ? 'mat-primary' : 'border-light'
                }} uppercase items-center font-medium"
              >
                Edit
              </span>
            </button>
            } @else {
            <button
              mat-button
              (click)="cancelEdit(true)"
              class="ml-auto font-medium"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              (click)="update()"
              color="primary"
              class="ml-2 font-medium theme-btn"
            >
              Update
            </button>
            } }
          </div>
        </div>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 form-grid pt-4"
        >
          <mat-form-field
            appearance="outline"
            class="w-full white-backgrounded-field mb-6"
          >
            <mat-label>Support Type</mat-label>
            <mat-select formControlName="supportTypeId" name="supportTypeId">
              @for (support of supportTypes$ | async; track support) {
              <mat-option [value]="support.value">{{ support.key }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="w-full white-backgrounded-field mb-4"
          >
            <mat-label>Member</mat-label>
            <input
              #input
              type="text"
              placeholder="Member"
              matInput
              formControlName="memberId" 
              [matAutocomplete]="auto"
              (input)="filterMembers()"
              (focus)="filterMembers()"
            />
            <mat-autocomplete
              requireSelection
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event)"
            >
             @for (member of filteredMembers; track $index) {
              <mat-option [value]="member.id">
                {{ member.name }}
              </mat-option>
              }
            </mat-autocomplete>
            @if(ticketForm.controls.memberId.hasError('required')){
              <mat-error 
              >
                Member is required.
              </mat-error>
            }
          </mat-form-field>
          @if (vendorList!== null) {
          <mat-form-field
            appearance="outline"
            class="w-full white-backgrounded-field"
          >
            <mat-label>Vendor</mat-label>
            <mat-select formControlName="vendorId" name="vendor">
              @for (vendor of vendorList; track vendor) {
              <mat-option [value]="vendor.id">{{ vendor.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          } @if(getSelectedVendorName() == stma){
          <mat-form-field
            appearance="outline"
            class="w-full white-backgrounded-field mb-6"
          >
            <mat-label>Department</mat-label>
            <mat-select formControlName="departmentId" name="departmentId">
              @for (department of departmentTypes$ | async; track department) {
              <mat-option [value]="department.value">{{
                department.key
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          }
        </div>
        <div class="flex py-6">
          <div class="flex-1">
            <mat-form-field
              appearance="outline"
              class="mb-4 w-full white-backgrounded-field"
            >
              <mat-label>Issue/Concern</mat-label>
              <textarea
                matInput
                formControlName="issueOrConcern"
                rows="3"
                placeholder=""
              ></textarea>
              @if(isEditing) {
              <mat-hint
                >{{
                  ticketForm.get("issueOrConcern")?.value?.length || 0
                }}/500</mat-hint
              >
              }
            </mat-form-field>
          </div>
        </div>
        @if (ticketForm.value?.statusTypeId === statusType['CLOSED']) {
        <div class="flex-1 pb-6">
          <mat-form-field
            appearance="outline"
            class="w-full white-backgrounded-field"
          >
            <mat-label>Closing Statement</mat-label>
            <textarea
              matInput
              formControlName="closingStatement"
              rows="3"
              placeholder=""
            ></textarea>
          </mat-form-field>
        </div>
        } @if (isEditing) {
        <div class="mb-5 flex ml-auto items-center"></div>
        }
      </div>
      <div class="theme-card" [class.isEditing]="isAttachmentEditing">
        <div class="flex items-center justify-between mb-6">
          <h2 class="text-xl font-[500]">
            Attachments @if(attachments.length){ ({{ attachments.length }}) }
          </h2>
          @if(currentTicketStatus != 'CLOSED'){
          <button
            mat-stroked-button
            color="primary"
            (click)="openAttachmentDialog()"
            class="theme-stroke-btn"
          >
            Upload
          </button>
          }
        </div>
        @if(attachments.length == 0){
        <p class="text-center py-6">
          No attachment found@if(currentTicketStatus!='CLOSED'){<span
            >, you can add by click on
            <strong class="font-medium">Upload</strong> button</span
          >}
        </p>
        } @if(attachments.length){
        <div class="flex gap-6">
          @for(attachment of attachments; track attachment; let i = $index){
          <div class="attachment">
            @if(!attachment.loaded){
            <img src="assets/thumbnail.png" alt="Thumbnail" />
            }
            <button
              (click)="showImage(attachment.id, attachment.loaded || false)"
            >
              <img
                [class.hidden]="!attachment.loaded"
                [src]="attachment.url"
                alt="Thumbnail"
                (load)="onImageLoad(i)"
              />
            </button>
            @if(currentTicketStatus!='CLOSED'){
            <button (click)="deleteAttachment(attachment.id)">
              <mat-icon class="btn-icon" svgIcon="cross"></mat-icon>
            </button>
            }
          </div>
          }
        </div>
        }
      </div>

      <div class="theme-card">
        <h2 class="text-xl font-[500]">Comments ({{ messages.length }})</h2>
        @if (!initiatedComments) {
        <div class="flex flex-col items-center theme-card-body gap-8">
          <img src="assets/chat.svg" alt="Attachment Image" />
          <h4 class="font-bold text-2xl">Start a new conversation</h4>
          <p class="text-light-grey">
            No comments added yet. Be the first to share your feedback
          </p>
        </div>
        } @else {
        <div class="comment-wrapper">
          @for (message of messages; track message; let i = $index) {
          <div class="comment-listing">
            <div class="user-initials">{{ message.name }}</div>
            <div class="font-light">
              <p>
                {{ message.message }}
              </p>
              <p class="text-sm">
                {{ message.time | date : "d MMM, yyyy, h:mm a" }}
              </p>
            </div>
          </div>
          }
        </div>
        } @if(currentTicketStatus!='CLOSED'){
        <div class="flex items-center">
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            class="flex-1 white-backgrounded-field"
            [class.pt-5]="!messages.length"
          >
            <input
              matInput
              [formControl]="message"
              placeholder="Type comment here..."
              (keydown.enter)="handleEnter($event)"
            />
            <div matSuffix class="flex items-center">
              <button
                (click)="sendComment()"
                [disabled]="sendingMessage"
                mat-icon-button
                class="send cursor-pointer flex items-center"
              >
                <mat-icon svgIcon="send-outline"></mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
        }
      </div>
      <!-- <div class="theme-card">
        <h2 class="text-xl font-[500]">Linked Emails</h2>
        <div class="flex flex-col items-center theme-card-body gap-8">
          <img src="assets/chat.svg">
          <h4 class="font-bold text-2xl">Link & View Email Updates</h4>
          <p class="text-light-grey">
            No email is linked, click below to link an email
          </p>
          <button
            mat-flat-button
            color="primary"
            class="theme-btn"
            (click)="linkMail()"
          >
            <mat-icon svgIcon="add"></mat-icon>Link Email
          </button>
        </div>
      </div> -->
    </form>
  </div>
</div>
