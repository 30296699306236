<div mat-dialog-content class="theme-dialog-content">
  <p class="text-[#1D1B20] text-xl">
    {{data.message}}
  </p>
  @if(data.description){
    <p class="text-[#5F5E5E] mt-5">
      {{data.description}}
    </p>
  }
</div>
<div mat-dialog-actions class="theme-dialog-actions flex justify-end gap-2">
  <button mat-button (click)="onCloseClick()">No</button>
  @if(data.type=='warn'){
  <button
    cdkFocusInitial
    mat-flat-button
    color="warn"
    class="theme-warn-btn"
    (click)="onCTAClick()"
  >
    Yes
  </button>
  } @else {
  <button
    cdkFocusInitial
    mat-flat-button
    color="primary"
    class="theme-btn"
    (click)="onCTAClick()"
  >
    Yes
  </button>
  }
</div>
