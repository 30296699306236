import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'; 
import {
  MatNativeDateModule,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DialogService } from '../../services/dialog.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { TicketsService } from '../tickets.service';

@Component({
  selector: 'app-ticket-reminder',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    MatIconModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './ticket-reminder.component.html',
  styleUrl: './ticket-reminder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketReminderComponent {
  reminderForm: FormGroup;
  tickedId: any;
  public isButtonDisabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private readonly dataService: DataSharingService,
    private readonly dialogService: DialogService,
    private ticketsService: TicketsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reminderForm = this.fb.group({
      date: [new Date(), Validators.required],
      time: ['12:00 AM', Validators.required],
      notes: ['', Validators.required],
    });
    this.reminderForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.reminderForm.valid);
    });
    this.dialogService.save$.subscribe(() => {
      if (this.reminderForm.valid) {
        const date = this.reminderForm.get('date')?.value;
        const time = this.reminderForm.get('time')?.value;
        const note = this.reminderForm.get('notes')?.value;

        const isoTimestamp = this.combineDateAndTime(date, time);

        const payload = {
          datetime: isoTimestamp,
          note: note,
        };
        this.ticketsService
          .setReminder(this.tickedId, payload)
          .subscribe((response: any) => { 
            this.dialogService.onData(payload);
          });
      }
    });
  }

  ngOnInit(): void {
    this.tickedId = this.data.data.ticketId;
  }

  combineDateAndTime(date: Date, time: string) {
    const [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    const combinedDate = new Date(date);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);

    return combinedDate.toISOString();
  }
}
