import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomeValidationsService {
  constructor() {}

  numericValidator = (event: any) => {
    let key = event.which ? event.which : event.keyCode;
    if (
      event.keyCode == 8 ||
      event.keyCode == 46 ||
      event.keyCode == 37 ||
      event.keyCode == 39
    ) {
      return true;
    } else if (key < 48 || (key > 57 && key < 96) || key > 105) {
      return false;
    } else return true;
  };

  strictEmailValidator(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    // Custom validation rule: stricter regex for email validation
    const strictEmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email && !strictEmailPattern.test(email)) {
      return { invalidEmail: true };
    }
    return null; // No error
  }
}
