<div class="p-6">
  <div class="flex justify-between gap-2 mb-4">
    <h1 class="text-xl font-medium shrink-0">Documents</h1>
    <div class="flex gap-3 items-center">
      <button class="btn-remarks" mat-button (click)="addRemarks()">
        Add Remarks
      </button>
      <button mat-stroked-button color="primary" class="theme-stroke-btn">
        Deny
      </button>
      <button class="theme-btn" color="primary" mat-flat-button>
        MSR Reviewed
      </button>
    </div>
  </div>
  <div class="grid md:grid-cols-3 gap-4 grid-cols-1">
    @for(document of documents; track document) {
    <app-document-card [documentData]="document"></app-document-card>
    }
  </div>
</div>
