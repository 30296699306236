<div class="flex items-center justify-between ps-4">
  <span class="font-medium">{{ data.message }}</span>
  <span  matSnackBarActions>
    <button
      class="font-medium"
      mat-icon-button 
      matSnackBarAction
      (click)="snackBarRef.dismissWithAction()"
    >
      <mat-icon  svgIcon="cross"></mat-icon>
    </button>
  </span>
</div>
