import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-company-information",
  standalone: true,
  imports: [MatInputModule, MatCheckboxModule, MatButtonModule],
  templateUrl: "./company-information.component.html",
  styleUrl: "./company-information.component.scss",
})
export class CompanyInformationComponent {
  @Output() remarks = new EventEmitter<any>();
  memberShipData: any = {
    companyName: "ABC Corp",
    dba: "Food Mart #1",
    email: "foodmart1@gmail.com",
    phone: "1234567890",
    address: {
      street1: "123 Main St.",
      street2: "STE 123",
      city: "San Antonio",
      state: "Texas",
      county: "Bexar",
      zipcode: "78610",
      country: "United States",
    },
    hasMailingAddress: true,
    mailingAddress: {
      street1: "123 Main St.",
      street2: "STE 123",
      city: "San Antonio",
      state: "Texas",
      county: "Bexar",
      zipcode: "78610",
      country: "United States",
    },
  };

  addRemarks() {
    this.remarks.emit();
  }
}
