import { Component } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ContainerComponent } from '../components/container/container.component';
import { TableComponent } from '../components/table/table.component';
import { CustomColumnDirective } from '../directives/custom-column.directive';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notification.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { AnnouncementDialogComponent } from './announcement-dialog/announcement-dialog.component';
import { Subscription, switchMap } from 'rxjs';
import { AnnouncementService } from './announcement.service';
import { DialogBoxComponent } from '../components/dialog-box/dialog-box.component';
import { ThemeContainerComponent } from '../components/theme-container/theme-container.component';

@Component({
  selector: 'app-announcement',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    HeaderComponent,
    TableComponent,
    ContainerComponent,
    CustomColumnDirective,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    ThemeContainerComponent
  ],
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
})
export class AnnouncementComponent {
  dialogSubscription!: Subscription;
  isLoading = true;
  totalRecords!: number;
  pageNumber!: number;
  pageSize = 10;
  totalPages!: number;

  public announcements: any[] = [];
  public pastAnnouncements: any[] = [];

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataService: DataSharingService,
    private announcementService: AnnouncementService
  ) {
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pencil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/pencil.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'speaker',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/Speaker.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/trash-button.svg'
      )
    );
  }

  ngOnInit() {
    this.pageChange();
  }

  openDialog(id?: number) {
    const dialogRef = this.dialogService.open({
      component: AnnouncementDialogComponent,
      title: 'Create Announcement',
      actionLabel: 'Create',
      themeModal: true,
      hideCloseIcon: true
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$
      .pipe(
        switchMap((data) =>
          this.announcementService.createAnnouncement(data as any)
        )
      )
      .subscribe({
        complete: () => {},
        error: (err: any) => {
          this.notificationService.error(err.message);
        },
        next: (res: any) => {
          dialogRef.close();
          this.dataService.isFormValid.next(true);
          this.pageChange();
          this.notificationService.success('Announcement has been added');
        },
      });
  }

  pageChange() {
    this.pastAnnouncements = []
    this.announcements = []
    this.announcementService.getAnnouncements().subscribe((response: any) => {
      const { announcements } = response;
      this.isLoading = false; 
      this.totalRecords = response.totalRecords;
      announcements.forEach((e: any) => {
        const inComingDate = new Date(e.endDate)
        const currentDate = new Date();
        if(inComingDate < currentDate) {
          this.pastAnnouncements.push(e)
        } else {
          this.announcements.push(e)
        }
      })
    });
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '450px',
      panelClass: 'custom-dialog-box',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this Announcement?', 
        type: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.announcementService.deleteAnnouncement(id).subscribe((res) => {
          this.notificationService.success('Announcement deleted permanently');
          this.pageChange();
        });
      }})
  }
}
